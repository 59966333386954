import React, { useState, useMemo } from 'react';

const DataTable = ({ title = '', notFoundText, columns, data }) => {
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // Search and filter logic
  const filteredData = useMemo(
    () =>
      data.filter((row) =>
        columns.some((column) =>
          row[column.accessor]?.toString().toLowerCase().includes(search.toLowerCase())
        )
      ),
    [data, search, columns]
  );

  // Sorting logic
  const sortedData = useMemo(() => {
    if (sortConfig.key) {
      return [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, sortConfig]);

  // Pagination logic
  const pageCount = Math.ceil(sortedData.length / pageSize);
  const paginatedData = useMemo(() => {
    const start = currentPage * pageSize;
    return sortedData.slice(start, start + pageSize);
  }, [sortedData, currentPage, pageSize]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < pageCount) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="rounded-md shadow p-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <p className="text-2xl font-extrabold mb-4">{title}</p>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..."
          className="mb-4 appearance-none text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full rounded-md overflow-hidden">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.accessor}
                  onClick={() => handleSort(column.accessor)}
                  className="text-nowrap px-2 py-3 font-semibold cursor-pointer text-[#333333] bg-gray-100">
                  {column.Header}
                  {sortConfig.key === column.accessor && (
                    <span>{sortConfig.direction === 'asc' ? ' 🔼' : ' 🔽'}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length ? (
              paginatedData.map((row, index) => (
                <tr key={index} className="text-nowrap border-b border-gray-200">
                  {columns.map((column) => (
                    <td key={column.accessor} className="p-2 text-center text-[#333333]">
                      {row[column.accessor]}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} className="p-2 text-center text-[#333333]">
                  {notFoundText}
                </td>
              </tr>
            )}
            <tr className="border-t border-gray-200">
              <td colSpan={columns.length} className="p-2">
                <div className="flex justify-between items-center">
                  <div className="flex gap-2">
                    <button
                      type="button"
                      onClick={() => handlePageChange(0)}
                      disabled={currentPage === 0}
                      className={`text-xs p-2 w-10 border rounded ${
                        currentPage === 0
                          ? 'border-gray-200 bg-gray-200 text-black text-opacity-50'
                          : 'border-[#5046E4] bg-[#5046E4] text-white'
                      }`}>
                      {'<<'}
                    </button>
                    <button
                      type="button"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0}
                      className={`text-xs p-2 w-10 border rounded ${
                        currentPage === 0
                          ? 'border-gray-200 bg-gray-200 text-black text-opacity-50'
                          : 'border-[#5046E4] bg-[#5046E4] text-white'
                      }`}>
                      {'<'}
                    </button>
                    <button
                      type="button"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage >= pageCount - 1}
                      className={`text-xs p-2 w-10 border rounded ${
                        currentPage >= pageCount - 1
                          ? 'border-gray-200 bg-gray-200 text-black text-opacity-50'
                          : 'border-[#5046E4] bg-[#5046E4] text-white'
                      }`}>
                      {'>'}
                    </button>
                    <button
                      type="button"
                      onClick={() => handlePageChange(pageCount - 1)}
                      disabled={currentPage >= pageCount - 1}
                      className={`text-xs p-2 w-10 border rounded ${
                        currentPage >= pageCount - 1
                          ? 'border-gray-200 bg-gray-200 text-black text-opacity-50'
                          : 'border-[#5046E4] bg-[#5046E4] text-white'
                      }`}>
                      {'>>'}
                    </button>
                  </div>
                  <div className="flex gap-2 items-center">
                    <span className="text-[#333333] ">
                      Page{' '}
                      <strong>
                        {currentPage + 1} of {pageCount}
                      </strong>{' '}
                    </span>
                    <span className="text-[#333333]">
                      | Go to page:{' '}
                      <input
                        type="number"
                        min="1"
                        max={pageCount}
                        value={currentPage + 1}
                        disabled={currentPage >= pageCount - 1}
                        onChange={(e) => handlePageChange(Number(e.target.value) - 1)}
                        className="appearance-none text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </span>
                    <select
                      value={pageSize}
                      disabled={currentPage >= pageCount - 1}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                      className="w-[120px] appearance-none text-gray-700 border border-gray-200 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                      {[10, 20, 30, 40, 50].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
