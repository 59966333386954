import {
  LOADER_SET,
  NOTIFY_SET,
  REFER_MENU_SET,
  RESET_REDUX,
  USER_SET,
  VEHICLE_CONTENT_SET
} from './actions';

export const DEFAULT_USER = {
  id: null,
  name: { first: '', last: '' },
  email: { primary: '' },
  avatar: '',
  role: ['owner'],
  phone: { mobile: '' },
  highlights: [],
  photos: []
};

export const loader = (state = false, action) => {
  switch (action.type) {
    case LOADER_SET:
      return action.data;

    default:
      return state;
  }
};

export const notify = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY_SET:
      return action.state;

    default:
      return state;
  }
};

export const user = (state = DEFAULT_USER, action) => {
  switch (action.type) {
    case USER_SET:
      return action.user;
    case RESET_REDUX: {
      const userData = DEFAULT_USER;
      return userData;
    }

    default:
      return state;
  }
};

export const referMenu = (state = [], action) => {
  switch (action.type) {
    case REFER_MENU_SET:
      return action.state;

    default:
      return state;
  }
};

export const vehicleContent = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_CONTENT_SET:
      return action.state;

    default:
      return state;
  }
};
