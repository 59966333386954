import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Field, Form } from 'react-final-form';
import withNotify from '../redux/Hoc/withNotify';
import withUser from '../redux/Hoc/withUser';
import withLoader from '../redux/Hoc/withLoader';
import { STORAGE_PATH } from '../constants/index';
import { callFBFunction, uploadFile } from '../utils/firebase';
import { renderField, required } from '../helpers/form-validations';

const ReportModal = ({ onClose, open, setLoader, setNotify }) => {
  const [screenshots, setScreenshots] = useState([]);

  const onSelectPhoto = async (event) => {
    setLoader(true);
    if (event.files) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < event.files.length; i++) {
        uploadFile(STORAGE_PATH.REPORT_PROBLEM, event.files[i])
          .then(async (avatar) => {
            setScreenshots((prevData) => [...prevData, avatar]);
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
            setNotify({ type: 'error', message: 'Photo upload failed! Please try again.' });
          });
      }
    }
  };

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      screenshots
    };

    setLoader(true);
    callFBFunction('reportProblem', payload).then(({ data: response }) => {
      const { status, message } = response;
      setLoader(false);
      onClose();
      setNotify({ message, type: status ? 'success' : 'error' });
    });
  };

  return (
    <Transition.Root show={open} as={Fragment} onClose={() => onClose(true)}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="relative inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full md:p-4 sm:p-6">
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <h1 className="text-center font-bold text-xl mb-2">Report a problem</h1>
                    <div className="rounded-lg mb-4">
                      <Field
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        name="description"
                        type="textarea"
                        rows={5}
                        id="inline-email"
                        placeholder="Description"
                        validate={required}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="mb-2 md:mb-4 col-span-2 md:col-span-1">
                      <label
                        htmlFor="inline-lastname"
                        className="text-start block text-sm font-medium text-gray-700">
                        Upload Screenshots
                      </label>
                      <div className="mt-1">
                        <input
                          className="appearance-none block w-full px-3 py-[5px] border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          type="file"
                          multiple
                          id="screenshots"
                          accept="image/*"
                          onChange={(e) => onSelectPhoto(e.target)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <button
                        type="submit"
                        className="px-5 py-1.5 bg-indigo-600 text-white rounded shadow-md">
                        Submit Report
                      </button>
                      <button
                        type="button"
                        onClick={() => onClose(false)}
                        className="px-5 py-1.5 bg-gray-200 text-black rounded shadow-md">
                        Close
                      </button>
                    </div>
                  </form>
                )}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default withNotify(withUser(withLoader(ReportModal)));
