import React, { useEffect } from 'react';
import {
  useParams,
  useNavigate,
  // createSearchParams,
  useSearchParams
} from 'react-router-dom';
import _ from 'lodash';
import { useIndexedDB } from 'react-indexed-db';
import {
  callFBFunction,
  doAuthLogin,
  getAccountById,
  getProfileById,
  getRoofTopById,
  getUserById
} from '../utils/firebase';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import VinsytLoader from '../components/VinsytLoader';

const MagicLinkLogin = ({ setNotify, setUser }) => {
  const { add, update, getByID } = useIndexedDB('links');
  const { code } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const requestCall = async (accountData) => {
    getByID(1).then((data) => {
      if (data) {
        update({ id: 1, magicLink: accountData.magic_link });
      } else {
        add({ magicLink: accountData.magic_link });
      }
    });
  };

  useEffect(() => {
    if (code) {
      // setLoader(true);
      // Check redirection after login
      let redrectTo = '/';
      if (!_.isEmpty(searchParams.get('redirect'))) {
        redrectTo = searchParams.get('redirect');
      }

      // Delete redirect params form URL
      const param = searchParams.get('redirect');
      if (param) {
        searchParams.delete('redirect');
        setSearchParams(searchParams);
      }

      const to = { pathname: redrectTo, search: searchParams.toString() };
      callFBFunction('verifyMagicLink', { code }).then(async ({ data: response }) => {
        const { status, data, message } = response;
        if (status) {
          doAuthLogin(data.e, atob(data.p))
            .then(async ({ user }) => {
              const userData = await getUserById(user.uid);
              const profileData = await getProfileById(user.uid);
              const accountData = await getAccountById(user.uid);
              const rooftopData = await getRoofTopById(userData.rooftop);
              setUser({ ...profileData, ...userData, ...accountData, rooftopData });
              await requestCall(accountData);
              // setLoader(false);
              setNotify({ message: 'Login successfully.', type: 'success' });
              navigate(to, { replace: true });
            })
            .catch(() => {
              // setLoader(false);
              setNotify({ message: 'Failed to login. Please try again.', type: 'error' });
              navigate(to, { replace: true });
            });
        } else {
          // setLoader(false);
          setNotify({ message, type: 'error' });
          navigate(to, { replace: true });
        }
      });
    }
  }, [code]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <VinsytLoader />
    </div>
  );
};
export default withLoader(withNotify(withUser(MagicLinkLogin)));
