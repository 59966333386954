import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ReviewSlider from '../components/DealerReviews/ReviewSlider';
import ReviewList from '../components/DealerReviews/ReviewList';
import { callFBFunction, getRoofTopById } from '../utils/firebase';
import withLoader from '../redux/Hoc/withLoader';

const DealerReviews = ({ setLoader, loader }) => {
  const { type, id } = useParams();
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    async function onInit() {
      setLoader(true);

      // If dealer disabled
      if (isEmpty(id)) {
        window.location = '/';
      }
      const roofTopRes = await getRoofTopById(id);
      if (isEmpty(roofTopRes)) {
        window.location = '/';
      }
      if (Object.prototype.hasOwnProperty.call(roofTopRes, 'disabled') && roofTopRes.disabled) {
        window.location = roofTopRes.url.main || '/';
      }

      callFBFunction('getDealerReview', {
        rooftop: id
      }).then((response) => {
        if (response && response.data) {
          setReviews(response.data.data);
          setLoader(false);
        }
      });
    }
    onInit();
  }, []);
  return (
    <>
      {type === 'slider' && <ReviewSlider loader={loader} review={reviews} />}
      {type === 'scroll' && <ReviewList loader={loader} review={reviews} />}
    </>
  );
};

export default withLoader(DealerReviews);
