/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import WalletTest1 from '../../assets/image/wallet-test-imgs/wallet-test-1.png';
import WalletTest2 from '../../assets/image/wallet-test-imgs/wallet-test-2.png';
import WalletTest3 from '../../assets/image/wallet-test-imgs/wallet-test-3.png';

const GoogleContent = ({ onClick }) => (
  <div>
    <div className="flex flex-row gap-3 md:gap-x-6 md:mx-4 overflow-hidden overflow-x-scroll snap-x snap-mandatory mb-10">
      <img className="w-48" src={WalletTest1} alt="test1" />
      <img className="w-48" src={WalletTest2} alt="test2" />
      <img className="w-48" src={WalletTest3} alt="test3" />
      <img className="w-48" src={WalletTest1} alt="test1" />
      <img className="w-48" src={WalletTest2} alt="test2" />
    </div>
    <div className="flex flex-row">
      <span className="mr-6">1.</span>
      <p className="mb-4">
        To access your Membership Card,{' '}
        <span className="cursor-pointer font-bold text-[#4F45DF]" onClick={onClick}>
          click here
        </span>
        .
      </p>
    </div>
    <div className="flex flex-row">
      <span className="mr-6">2.</span>
      <p className="mb-4">
        You will find an <span className="font-bold">“Add to Google Wallet”</span> button on your
        Google device.
      </p>
    </div>
    <div className="flex flex-row">
      <span className="mr-6">3.</span>
      <p className="mb-4">Now, you can view your membership card in your wallet app.</p>
    </div>

    <div className="flex flex-row">
      <span className="mr-6">4.</span>
      <p className="mb-4">
        For <span className="font-bold">Google Wallet users</span> users, the link to your Vehicle
        Personalized Site will be located on the front of your membership card.
      </p>
    </div>
    <div className="flex flex-row">
      <span className="mr-9" />
      <p className="mb-4">Now, you can view your membership card in your wallet app.</p>
    </div>
  </div>
);

export default GoogleContent;
