/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import formatString from 'format-string-by-pattern';
import { isEmpty } from 'lodash';
import withUser from '../redux/Hoc/withUser';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import {
  callFBFunction,
  checkDuplicateUserEmail,
  getRoofTopById,
  getUnsubscribeByEmail,
  getUnsubscribeByPhone,
  getUserById,
  getVehiclesByUserId,
  updateUser
} from '../utils/firebase';
import {
  composeValidators,
  email,
  renderField,
  required,
  usPhoneNumber
} from '../helpers/form-validations';
import UploadProfile from '../modals/UploadProfile';
import { eventReactGTMModule } from '../utils/analytics';
import { USPhoneNumber } from '../helpers/app';

const Profile = ({ user, setLoader, setNotify, setUser }) => {
  const [isFirstNameSave, setIsFirstNameSave] = useState(false);
  const [isLastNameSave, setIsLastNameSave] = useState(false);
  const [isEmailSave, setIsEmailSave] = useState(false);
  const [isPhoneSave, setIsPhoneSave] = useState(false);
  const [rooftopDetail, setRooftopDetail] = useState({});
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [isSubscribe, setIsSubscribe] = useState({ email: false, phone: false, type: '' });

  useEffect(() => {
    async function onInit() {
      const response = await getRoofTopById(user.rooftop);
      setRooftopDetail(response);
      const vehicleRes = await getVehiclesByUserId(user.id);
      setVehicleDetail(vehicleRes[0]);
      const subscribeEmailData = await getUnsubscribeByEmail(user.email.primary);
      const subscribePhoneData = await getUnsubscribeByPhone(user.phone.mobile);
      setIsSubscribe({
        email: subscribeEmailData,
        phone: subscribePhoneData
      });
    }
    onInit();
  }, []);

  const onUpdate = async (data) => {
    setLoader(true);
    const userRes = await getUserById(user.id);
    if (Object.prototype.hasOwnProperty.call(data, 'first')) {
      userRes.name.first = data.first;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'last')) {
      userRes.name.last = data.last;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'email')) {
      userRes.email.primary = data.email;
      callFBFunction('checkUser', (response) => {
        if (response && response.data) {
          setNotify({ message: 'Email already exists! Please try again.', type: 'error' });
        }
      });
    }
    if (Object.prototype.hasOwnProperty.call(data, 'phone')) {
      userRes.phone.mobile = data.phone.replace(/[- )(]/g, '');
    }
    delete userRes.id;
    const response = await updateUser(userRes, user.id);
    if (response) {
      setUser({ ...user, ...userRes });
      setNotify({ message: 'Profile updated successfully.', type: 'success' });
    } else {
      setNotify({ message: 'Profile update failed! Please try again.', type: 'error' });
    }
    setLoader(false);
  };

  const gaEventCall = (profile_item, avatar_step) => {
    const tagManagerArgs = {
      event: 'e_profile_update',
      area: 'profile',
      avatar_step,
      profile_item
    };
    eventReactGTMModule(tagManagerArgs);
  };

  const onFirstNameSave = async (formData) => {
    gaEventCall('FirstName', '4');
    await onUpdate(formData);
    gaEventCall('FirstName', '5');
    setIsFirstNameSave(false);
  };

  const onEditFirstName = () => {
    gaEventCall('FirstName', '3');
    setIsFirstNameSave(true);
  };

  const onLastNameSave = async (formData) => {
    gaEventCall('LastName', '4');
    await onUpdate(formData);
    gaEventCall('LastName', '5');
    setIsLastNameSave(false);
  };

  const onEditLastName = () => {
    gaEventCall('LastName', '3');
    setIsLastNameSave(true);
  };

  const onEmailSave = async (formData) => {
    const userDetail = await checkDuplicateUserEmail(formData.email, user.id);
    if (userDetail) {
      setNotify({ message: 'Email already exits!', type: 'error' });
      setIsEmailSave(false);
      return;
    }
    gaEventCall('Email', '4');
    await onUpdate(formData);
    gaEventCall('Email', '5');
    setIsEmailSave(false);
  };

  const onEditEmail = () => {
    gaEventCall('Email', '3');
    setIsEmailSave(true);
  };

  const onPhoneSave = async (formData) => {
    gaEventCall('Phone', '4');
    await onUpdate(formData);
    gaEventCall('Phone', '5');
    setIsPhoneSave(false);
  };

  const onEditPhone = () => {
    gaEventCall('Phone', '3');
    setIsPhoneSave(true);
  };

  useEffect(() => {
    if (!isEmpty(isSubscribe.type)) {
      const payload = {
        type: isSubscribe.type
      };
      callFBFunction('unSubscribeEmailPhone', payload).then(({ data: response }) => {
        const { status, message } = response;
        setLoader(false);
        setNotify({ message, type: status ? 'success' : 'error' });
      });
    }
  }, [isSubscribe]);

  return (
    <>
      <main className="lg:col-span-10 xl:col-span-9">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="w-full flex items-center justify-between p-6 space-x-6">
            <div
              className="relative group cursor-pointer"
              onClick={() => setIsUploadModalOpen(true)}>
              <img
                className="w-20 h-20 bg-gray-300 rounded-full flex-shrink-0"
                src={user.avatar}
                alt=""
              />
              <div className="h-10 opacity-0 group-hover:opacity-75 duration-300 absolute inset-x-0 bottom-0 flex items-center justify-center items-end text-xl bg-white text-red-700 font-semibold">
                <div className="w-5 h-5 rounded-full border-2 bg-red-600 flex justify-center items-center">
                  <p className="text-white">+</p>
                </div>
              </div>
            </div>

            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {user.name.first}&nbsp;
                  {user.name.last}
                </h3>
              </div>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {!isEmpty(vehicleDetail) &&
                  `${vehicleDetail.attributes.make} ${vehicleDetail.attributes.model} Owner`}
              </p>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">First name</dt>
                {isFirstNameSave ? (
                  <>
                    <Form
                      onSubmit={onFirstNameSave}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="contents">
                          <dd className="mb-1">
                            <Field
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                              name="first"
                              type="text"
                              id="first"
                              placeholder="First Name"
                              defaultValue={user.name.first}
                              validate={required}>
                              {renderField}
                            </Field>
                          </dd>
                          <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                            <button
                              type="submit"
                              className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                              Save
                            </button>
                          </dd>
                        </form>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                      {user.name.first}
                    </dd>
                    <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                      <span
                        onClick={onEditFirstName}
                        className="cursor-pointer border-indigo-600 text-indigo-600">
                        Edit
                      </span>
                    </dd>
                  </>
                )}
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Last name</dt>
                {isLastNameSave ? (
                  <>
                    <Form
                      onSubmit={onLastNameSave}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="contents">
                          <dd className="mb-1">
                            <Field
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                              name="last"
                              type="text"
                              id="last"
                              placeholder="Last Name"
                              defaultValue={user.name.last}
                              validate={required}>
                              {renderField}
                            </Field>
                          </dd>
                          <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                            <button
                              type="submit"
                              className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                              Save
                            </button>
                          </dd>
                        </form>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                      {user.name.last}
                    </dd>
                    <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                      <span
                        onClick={onEditLastName}
                        className="cursor-pointer border-indigo-600 text-indigo-600">
                        Edit
                      </span>
                    </dd>
                  </>
                )}
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                {isEmailSave ? (
                  <>
                    <Form
                      onSubmit={onEmailSave}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="contents">
                          <dd className="mb-1">
                            <Field
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                              name="email"
                              type="text"
                              id="email"
                              placeholder="Email"
                              defaultValue={user.email.primary}
                              validate={composeValidators(required, email)}>
                              {renderField}
                            </Field>
                          </dd>
                          <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                            <button
                              type="submit"
                              className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                              Save
                            </button>
                          </dd>
                        </form>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                      {user.email.primary}
                    </dd>
                    <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                      <span
                        onClick={onEditEmail}
                        className="cursor-pointer border-indigo-600 text-indigo-600">
                        Edit
                      </span>
                    </dd>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1 invisible">
                      {user.email.primary}
                    </dd>
                    <div className="flex col-span-2 items-center mb-2 mt-2">
                      <input
                        id="email-checkbox"
                        type="checkbox"
                        value="email"
                        checked={isSubscribe.email}
                        onChange={() =>
                          setIsSubscribe({
                            ...isSubscribe,
                            email: !isSubscribe.email,
                            type: 'email'
                          })
                        }
                        className="w-7 h-7 text-indigo-600 border-2 border-gray-300 rounded focus:ring-white dark:ring-offset-gray-800 focus:ring-2 bg-white"
                      />
                      <label
                        htmlFor="email-checkbox"
                        className="cursor-pointer ml-4 text-sm font-medium text-gray-500">
                        Don't send me any email for now
                      </label>
                    </div>
                  </>
                )}
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                {isPhoneSave ? (
                  <>
                    <Form
                      onSubmit={onPhoneSave}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="contents">
                          <dd className="mb-1">
                            <Field
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                              name="phone"
                              type="text"
                              id="phone"
                              placeholder="Phone"
                              defaultValue={USPhoneNumber(user.phone.mobile)}
                              parse={formatString('(999) 999-9999')}
                              validate={composeValidators(required, usPhoneNumber)}>
                              {renderField}
                            </Field>
                          </dd>
                          <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                            <button
                              type="submit"
                              className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                              Save
                            </button>
                          </dd>
                        </form>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                      {USPhoneNumber(user.phone.mobile)}
                    </dd>
                    <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                      <span
                        onClick={onEditPhone}
                        className="cursor-pointer border-indigo-600 text-indigo-600">
                        Edit
                      </span>
                    </dd>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1 invisible">
                      {USPhoneNumber(user.phone.mobile)}
                    </dd>
                    <div className="flex col-span-2 items-center mb-2 mt-2">
                      <label
                        htmlFor="phone-checkbox"
                        className="cursor-pointer text-sm font-medium text-gray-500">
                        To {isSubscribe.phone ? 'subscribe' : 'unsubscribe'} text{' '}
                        {isSubscribe.phone ? 'START' : 'STOP'} to {USPhoneNumber(2077165576)}
                      </label>
                    </div>
                  </>
                )}
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Role</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {user.role[0].capitalize()}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Preferred Dealer</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {rooftopDetail.name}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </main>
      {isUploadModalOpen && <UploadProfile onClose={() => setIsUploadModalOpen(false)} />}
    </>
  );
};

export default withNotify(withLoader(withUser(Profile)));
