import React, { Fragment } from 'react';
import moment from 'moment';
import { Tab } from '@headlessui/react';
import ReactStars from 'react-rating-stars-component';

const CustomerReviews = ({ data }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div className="w-full max-w-2xl mx-auto mt-6 md:mt-16 lg:max-w-none lg:mt-0 lg:col-span-4">
      <Tab.Group as="div">
        <div className="border-b border-gray-200">
          <Tab.List className="-mb-px flex space-x-8">
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-indigo-600'
                    : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300',
                  'whitespace-nowrap py-6 border-b-2 font-medium text-sm'
                )
              }>
              Customer Reviews
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels as={Fragment}>
          <Tab.Panel className="-mb-10">
            {data.length > 0 ? (
              <>
                <h3 className="sr-only">Customer Reviews</h3>
                {data.map((review, reviewIdx) => (
                  <div key={review.id} className="border-b flex text-sm text-gray-500 space-x-4">
                    <div className="flex-none py-10">
                      <img
                        src={review.avatar}
                        alt=""
                        className="w-10 h-10 bg-gray-100 rounded-full"
                      />
                    </div>
                    <div className={classNames(reviewIdx === 0 ? '' : 'border-gray-200', 'py-10')}>
                      <h3 className="font-medium text-gray-900">
                        {review.name.first} {review.name.last}
                      </h3>
                      <p>{moment.unix(review.created_on.seconds).format('MMMM DD, YYYY')}</p>

                      <div className="flex items-center mt-4">
                        <ReactStars
                          size={20}
                          count={5}
                          color="grey"
                          activeColor="#FACC14"
                          value={review.rating}
                          isHalf
                          edit={false}
                        />
                      </div>
                      <p className="sr-only">{review.rating} out of 5 stars</p>

                      <div
                        className="mt-4 prose prose-sm max-w-none text-gray-500"
                        dangerouslySetInnerHTML={{ __html: review.comment }}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="mt-20 text-center">No reviews yet.</p>
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default CustomerReviews;
