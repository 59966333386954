import React from 'react';
import { useLottie } from 'lottie-react';
import groovyWalkAnimation from '../assets/json/latestVinsyt.json';

const VinsytLoader = () => {
  const options = {
    animationData: groovyWalkAnimation
  };
  const { View } = useLottie(options);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white bg-opacity-95 flex flex-col items-center justify-center">
      {View}
    </div>
  );
};

export default VinsytLoader;
