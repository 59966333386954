/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CodeIcon, FlagIcon, StarIcon } from '@heroicons/react/solid';
import { GiftIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { classNames } from '../helpers/app';
import DashboardFeed from '../components/Dashboard/DashboardFeed';
import {
  callFBFunction,
  getAccountById,
  getStaffById,
  getUserFeedsByUserId,
  getVehiclesByUserId
} from '../utils/firebase';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import RooftopDetailCard from '../components/Dashboard/RooftopDetailCard';
import { eventAnalytics, eventReactGTMModule } from '../utils/analytics';
import { ACTION, CATEGORY, FEED_BUTTONS, HeaderCards } from '../constants';
import LeaveReview from '../modals/LeaveReview';
import DashboardContactCard from '../components/Dashboard/DashboardContactCard';
import appImage from '../assets/image';

const Dashboard = ({ setLoader, user }) => {
  const navigate = useNavigate();
  const rooftop = user.rooftopData;
  const [userFeeds, setUserFeed] = useState([]);
  const [accountData, setAccountData] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [staff, setStaff] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [newFeed, setNewFeed] = useState(false);

  const onClickSalesProfile = async (id) => {
    const staffData = await getStaffById(id);
    eventAnalytics({
      category: CATEGORY.POST,
      action: ACTION.AGENT_AVATAR,
      label: `${staffData.name.first} ${staffData.name.last}`,
      nonInteraction: false
    });
    navigate(`/contact/${id}`);
  };

  const onRedirect = async (feed) => {
    if (feed.key === 'welcome') {
      const tagManagerArgs = {
        event: 'e_delivery_photo',
        action: 'open_in_tab'
      };
      eventReactGTMModule(tagManagerArgs);
      window.open(feed.feed_image, '_blank');
    } else if (feed.key === 'share_with_ff') {
      navigate('/friends-and-family');
    } else if (feed.key === 'leave_review') {
      const getAccountRes = await getAccountById(user.id);
      if (!getAccountRes.leave_review.status) {
        setReviewModalOpen(true);
      }
    }
  };

  const onRedirectPage = (link, destination) => {
    const tagManagerArgs = {
      event: 'e_dealer_visit',
      area: 'home',
      external_destination: destination
    };
    eventReactGTMModule(tagManagerArgs);
    window.open(link, '_blank');
  };

  const onRedirectAction = (link, type) => {
    const tagManagerArgs = {
      event: 'e_agent_contact',
      area: 'home_card_sa',
      agent_contact_method: type
    };
    eventReactGTMModule(tagManagerArgs);
    window.open(link);
  };

  useEffect(() => {
    async function myApi() {
      if (isEmpty(user.id)) {
        navigate('/send-magic-link');
        return;
      }
      setLoader(true);
      const getAllUserFeeds = await getUserFeedsByUserId(user.id);
      const staffData = await getStaffById(user.staff);
      const vehicles = await getVehiclesByUserId(user.id);
      const accountsData = await getAccountById(user.id);
      setAccountData(accountsData);
      if (vehicles.length > 0) {
        setVehicle(vehicles[0]);
      }
      setStaff(staffData);
      if (getAllUserFeeds.length > 0) {
        getAllUserFeeds.map(async (feed) => {
          feed.button = FEED_BUTTONS[feed.key] || '';
          if (feed.is_system) {
            feed.avatar = staffData.avatar;
            feed.name = `${staffData.name.first} ${staffData.name.last}`;
          } else {
            // TODO: get user generated feed
          }
        });
        setUserFeed(getAllUserFeeds);
      }
      setLoader(false);
    }
    myApi();
  }, []);

  const filteredHeaderCards = [
    ...HeaderCards.slice(0, 4),
    rooftop?.passkit
      ? {
          navigate: '/wallet',
          title: 'Wallet',
          subtitle: 'Download digital owners documentation',
          icon: appImage.OwnerManuals
        }
      : null,
    ...HeaderCards.slice(4)
  ].filter(Boolean);

  return (
    <>
      <main className="md:col-span-6 xl:col-span-6">
        {/* content column */}
        <aside className=" xl:col-span-4">
          <div className="mb-4 md:mb-0">
            {reviewModalOpen && (
              <LeaveReview
                onContinue={() => setReviewModalOpen(false)}
                open={reviewModalOpen}
                staff={staff}
              />
            )}
          </div>
        </aside>
        <div>
          <h1 className="sr-only">Recent questions</h1>

          {rooftop && !rooftop.dignify && (
            <>
              <a href="/friends-and-family">
                <div className=" w-full bg-gradient-to-r from-[#FDC56B] to-white hover:to-[#FD773C] rounded-lg shadow mb-4 md:lg-9 p-4">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-2 items-center md:mb-4">
                        <GiftIcon className="w-6 h-6" />
                        <h2 className="text-base font-medium text-gray-900">
                          Recommend {!isEmpty(staff) ? staff.name.first : 'your seller'}
                        </h2>
                      </div>
                      {rooftop && rooftop.refer && rooftop.refer.you_get && rooftop.refer.they_get && (
                        <p className="text-[13px] text-black font-light hidden md:block">
                          Give ${rooftop.refer.they_get} to anyone who buys a new car and get paid $
                          {rooftop.refer.you_get}
                        </p>
                      )}
                      {rooftop &&
                        rooftop.refer &&
                        rooftop.refer.you_get &&
                        !rooftop.refer.they_get && (
                          <p className="text-[13px] text-black font-light hidden md:block">
                            Get ${rooftop.refer.you_get} for anyone who buys a new car
                          </p>
                        )}
                      {rooftop &&
                        rooftop.refer &&
                        !rooftop.refer.you_get &&
                        rooftop.refer.they_get && (
                          <p className="text-[13px] text-black font-light hidden md:block">
                            Give ${rooftop.refer.they_get} to anyone who buys a new car
                          </p>
                        )}
                    </div>
                    <button
                      type="button"
                      onClick={() => navigate('/friends-and-family')}
                      className="cursor-pointer md:block relative my-auto mr-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Share Discounts
                    </button>
                  </div>
                </div>
              </a>
            </>
          )}

          {rooftop && rooftop.dignify && (
            <div className="w-full bg-[#FAFAFA] rounded-lg shadow mb-4 p-6 md:py-9 md:px-7 flex flex-col gap-3 md:flex-row md:justify-between items-start md:items-center relative">
              <div className=" max-w-[350px]">
                <h2 className="text-base md:text-xl">
                  Finance service, accessories and more with{' '}
                  <span className="text-[#5046E4]">our in-house financing program</span>
                </h2>
              </div>
              <button
                className="cursor-pointer flex items-center justify-center bg-[#5046E4] text-[14px] text-white px-[16px] py-[9px] rounded-md inline-flex"
                type="button"
                onClick={() => navigate('/financing')}>
                Get Started
              </button>
              <div className="border-[#5046E4] border-[3px] w-60 absolute bottom-0 left-0 rounded-tr-full" />
            </div>
          )}

          {rooftop && rooftop.passkit && !rooftop.dignify && (
            <>
              {accountData && !accountData.passkit.status && (
                <div className="w-full bg-gradient-to-br from-[#3C9CE6] to-[#132DC3] rounded-lg shadow mb-4 md:mb-9 p-6 md:pb-0 md:pt-4 md:px-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="hidden md:inline-flex col-span-12 md:col-span-4 bg-banner-card-wallet">
                      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                      <img
                        className="w-full h-auto"
                        src={appImage.BannerCardWallet}
                        alt="banner mockup image"
                      />
                    </div>
                    <div className="col-span-12 md:col-span-8 mt-5 grid grid-cols-1">
                      <div className="col-span-1">
                        <h1 className="text-base text-white font-semibold mb-2">
                          Your {vehicle && vehicle.attributes.model} - in your digital wallet
                        </h1>
                        <p className="text-[12px] text-white p-0">
                          Its the fastest and most convenient way to get back to your personalized
                          digital companion at any time.
                        </p>
                      </div>
                      <div className="col-span-1 mt-6 md:mt-0">
                        <button
                          className="flex items-center justify-center bg-white text-[11px] px-[14px] py-[9px] rounded-md inline-flex"
                          type="button"
                          onClick={() => {
                            setLoader(true);
                            callFBFunction('getPasskitUrl').then(({ data: response }) => {
                              if (response.status) {
                                setLoader(false);
                                window.location.href = response.data;
                              }
                            });
                          }}>
                          Download Now
                        </button>
                        <a href="./wallet">
                          <button
                            className="flex items-center justify-center text-white text-[11px] ml-5 px-4 py-[9px] rounded-md inline-flex"
                            type="button">
                            Learn More
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div className="grid grid-cols-2 gap-4 md:gap-6 mb-9">
            {filteredHeaderCards.map((item, index) => (
              <div
                key={index}
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg shadow-md transition ease-out duration-0 hover:duration-300"
                onClick={() => navigate(item.navigate)}>
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">{item.title}</h2>
                      <img className="w-10 h-10 h-auto" src={item.icon} alt={item.icon} />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {rooftop && rooftop.passkit && rooftop.dignify && (
            <>
              {accountData && !accountData.passkit.status && (
                <div className="w-full bg-gradient-to-br from-[#3C9CE6] to-[#132DC3] rounded-lg shadow mb-4 md:mb-9 p-6 md:pb-0 md:pt-4 md:px-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="hidden md:inline-flex col-span-12 md:col-span-4 bg-banner-card-wallet">
                      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                      <img
                        className="w-full h-auto"
                        src={appImage.BannerCardWallet}
                        alt="banner mockup image"
                      />
                    </div>
                    <div className="col-span-12 md:col-span-8 mt-5 grid grid-cols-1">
                      <div className="col-span-1">
                        <h1 className="text-base text-white font-semibold mb-2">
                          Your {vehicle && vehicle.attributes.model} - in your digital wallet
                        </h1>
                        <p className="text-[12px] text-white p-0">
                          Its the fastest and most convenient way to get back to your personalized
                          digital companion at any time.
                        </p>
                      </div>
                      <div className="col-span-1 mt-6 md:mt-0">
                        <button
                          className="flex items-center justify-center bg-white text-[11px] px-[14px] py-[9px] rounded-md inline-flex"
                          type="button"
                          onClick={() => {
                            setLoader(true);
                            callFBFunction('getPasskitUrl').then(({ data: response }) => {
                              if (response.status) {
                                setLoader(false);
                                window.location.href = response.data;
                              }
                            });
                          }}>
                          Download Now
                        </button>
                        <a href="./wallet">
                          <button
                            className="flex items-center justify-center text-white text-[11px] ml-5 px-4 py-[9px] rounded-md inline-flex"
                            type="button">
                            Learn More
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {rooftop && rooftop.name && (
            <div className="text-center mb-0">
              <h2 className="mb-2 text-lg font-medium text-[#333333]">{rooftop.name}</h2>
              <p className="text-[13px] text-black font-light">
                Find what matters to you right on your website
              </p>
            </div>
          )}
          <div className="grid grid-cols-2 gap-4 md:gap-6 mb-9 mt-9">
            {rooftop && rooftop.external_links && rooftop.external_links.accessories && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.accessories,
                    'accessories'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Accessories</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Accessories and mods for your ride
                    </p>
                  </div>
                </div>
              </div>
            )}
            {rooftop && rooftop.external_links && rooftop.external_links.finance && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.finance,
                    'coupons'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Parts Coupons</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Discounts on Service & Parts
                    </p>
                  </div>
                </div>
              </div>
            )}
            {rooftop && rooftop.external_links && rooftop.external_links.new_inventory && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.new_inventory,
                    'new_inventory'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Shop New Inventory</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Brand new vehicles from the manufacturer
                    </p>
                  </div>
                </div>
              </div>
            )}
            {rooftop && rooftop.external_links && rooftop.external_links.preowned && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.preowned,
                    'used_inventory'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Certified Pre-Owned</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      The very best of our used inventory
                    </p>
                  </div>
                </div>
              </div>
            )}
            {rooftop && rooftop.external_links && rooftop.external_links.parts && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.parts,
                    'parts_center'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Parts Center</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Browse the parts department
                    </p>
                  </div>
                </div>
              </div>
            )}
            {rooftop && rooftop.external_links && rooftop.external_links.parts_order && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.parts_order,
                    'parts_order'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Order Parts</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Order the exact part you need
                    </p>
                  </div>
                </div>
              </div>
            )}
            {rooftop && rooftop.external_links && rooftop.external_links.trade && (
              <div
                onClick={() =>
                  onRedirectPage(
                    rooftop && rooftop.external_links && rooftop.external_links.trade,
                    'trade'
                  )
                }
                className="cursor-pointer group bg-white hover:bg-gradient-to-br from-white to-[#FDC56B] rounded-lg border-[1.5px] border-500 transition ease-out duration-0 hover:duration-300">
                <div className="w-full h-full group-hover:bg-tire-tread bg-cover p-4">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-base font-medium text-gray-900">Trade</h2>
                      <img className="w-5 h-5 mr-2 h-auto" src={appImage.ExternalLink} alt="" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Find out how much your car is worth
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <aside className=" lg:block xl:block 2xl:block md:col-span-4 xl:col-span-4">
        <div className="sticky top-24 space-y-8">
          <ul role="list" className="space-y-4">
            {userFeeds.map((feed) => (
              <li key={feed.id} className="bg-white shadow rounded-lg">
                <article aria-labelledby={`question-title-${feed.id}`}>
                  <div>
                    {feed.key === 'welcome' && (
                      <img
                        className={classNames(
                          feed.feed_image !== ''
                            ? 'border-10-red mb-4 rounded-md w-full h-auto'
                            : 'hidden'
                        )}
                        src={feed.feed_image}
                        alt=""
                      />
                    )}
                    {newFeed && (
                      <>
                        <div className="flex space-x-3">
                          <div
                            className="flex-shrink-0 cursor-pointer"
                            onClick={() => onClickSalesProfile(user.staff)}>
                            <img className="h-10 w-10 rounded-full" src={feed.avatar} alt="" />
                          </div>
                          <div
                            className="min-w-0 flex-1 cursor-pointer"
                            onClick={() => navigate(`/contact/${user.staff}`)}>
                            <p className="text-sm font-medium text-gray-900">
                              <span className="hover:underline">{feed.name}</span>
                            </p>
                            <p className="text-sm text-gray-500">
                              <span>
                                {feed.created_on === ''
                                  ? ''
                                  : moment
                                      .unix(feed.created_on.seconds)
                                      .format('MMMM DD [at] HH:MM A')}
                              </span>
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center flex">
                            <Menu as="div" className="relative inline-block text-left">
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/"
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'flex px-4 py-2 text-sm'
                                          )}>
                                          <StarIcon
                                            className="mr-3 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span>Add to favorites</span>
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/"
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'flex px-4 py-2 text-sm'
                                          )}>
                                          <CodeIcon
                                            className="mr-3 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span>Embed</span>
                                        </Link>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <Link
                                          to="/"
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'flex px-4 py-2 text-sm'
                                          )}>
                                          <FlagIcon
                                            className="mr-3 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span>Report content</span>
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                        <h2
                          id={`question-title-${feed.id}`}
                          className="mt-4 text-base font-medium text-gray-900">
                          {feed.title}
                        </h2>
                      </>
                    )}
                  </div>
                  {newFeed && (
                    <>
                      <div className="mt-2 text-sm text-gray-700 space-y-4">
                        <span dangerouslySetInnerHTML={{ __html: _.unescape(feed.info) }} />
                      </div>
                      {!isEmpty(feed.button) && (
                        <div className="mt-6 flex justify-between space-x-8">
                          <div className="flex space-x-6">
                            <span className="inline-flex items-center text-sm">
                              <button
                                type="button"
                                className="text-[#000000] bg-[#E3E4FF] focus:ring-4 focus:ring-blue-300 text-sm rounded-lg px-5 py-2.5 mr-2 focus:outline-none"
                                onClick={() => onRedirect(feed)}>
                                {feed.button}
                              </button>
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </article>
              </li>
            ))}
          </ul>

          <DashboardFeed />
          {Object.keys(staff).length > 0 && (
            <DashboardContactCard
              data={staff}
              onRedirectAction={(link, type) => onRedirectAction(link, type)}
            />
          )}
          <RooftopDetailCard staff={staff} />
          {reviewModalOpen && (
            <LeaveReview
              onContinue={() => setReviewModalOpen(false)}
              open={reviewModalOpen}
              staff={staff}
            />
          )}
        </div>
      </aside>
    </>
  );
};

export default withUser(withLoader(Dashboard));
