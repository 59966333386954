import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import Loader from './Loader';

const Global = ({ notify, loader }) => {
  useEffect(() => {
    if (notify) {
      toast(notify.message, {
        type: notify.type,
        icon: true,
        position: 'bottom-left',
        theme: 'colored',
        style: { width: '500px', height: '80px', fontSize: '18px' }
      });
    }
  }, [notify]);

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
    </>
  );
};

export default withLoader(withNotify(Global));
