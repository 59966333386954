import React from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import ReactStars from 'react-rating-stars-component';

const ReviewSlider = ({ review }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: review && review.length > 0 ? 2 : 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <main className="md:col-span-9 xl:col-span-10 p-5 rooftop-review-slider overflow-hidden">
      {review && review.length > 0 ? (
        <Slider {...settings} className="mx-4">
          {review.map((item, index) => (
            <div className="p-2" key={index}>
              <div className="col-span-1 aspect-[6/3] bg-white rounded-lg  divide-gray-200 ease-out duration-100 drop-shadow-[0px_2px_4px_rgba(0,0,0,0.1)] p-4">
                <div className="flex flex-col border-b mb-2">
                  <h3 className="text-gray-900 text-sm font-bold truncate">
                    {item.name.first} {item.name.last}
                  </h3>
                  <p className="text-gray-500 text-[11px] truncate my-1">
                    {moment.unix(item.created_on._seconds).format('MMMM DD, YYYY')}
                  </p>
                  <div className="flex flex-row mb-3 gap-0.5">
                    <ReactStars
                      size={10}
                      count={5}
                      color="grey"
                      activeColor="#FACC14"
                      value={item.rating}
                      isHalf
                      edit={false}
                    />
                  </div>
                </div>
                <div className="">
                  <p className="text-sm text-black/70">
                    {item.comment.length > 200
                      ? `${item.comment.substring(0, 200)}...`
                      : item.comment}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="w-screen h-screen flex">
          <div className="text-center w-96 m-auto mt-[20%] aspect-[5/3] bg-white rounded-lg  divide-gray-200 ease-out duration-100 drop-shadow-[0px_2px_4px_rgba(0,0,0,0.1)] flex flex-row justify-center items-center">
            <h3 className="text-gray-900 text-xl font-semibold truncate">
              No reviews are available yet!!
            </h3>
          </div>
        </div>
      )}
    </main>
  );
};

export default ReviewSlider;
