/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// eslint-disable-next-line no-unused-vars
import { ChevronRightIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import withLoader from '../../redux/Hoc/withLoader';
import withNotify from '../../redux/Hoc/withNotify';
import { callFBFunction } from '../../utils/firebase';
import appImage from '../../assets/image';
import FinancePendingBanner from './finance-pending-banner';
import FinanceApprovedBanner from './finance-approved-banner';
import { eventReactGTMModule } from '../../utils/analytics';
import DignifiLogo from '../../assets/image/dignifi-logo.png';

const Finance = ({
  setLoader,
  setNotify,
  rooftop,
  desktopHide = false,
  shadow = true,
  customClassPending,
  customClassApproved
}) => {
  const [status, setStatus] = useState(null);
  const [amount, setAmount] = useState('');
  const [statusData, setStatusData] = useState(null);
  const [activeStatus, setActiveStatus] = useState(null);
  const [activeStatusData, setActiveStatusData] = useState(null);
  const { pathname } = useLocation();
  const path = pathname.replace('/', '');

  const getFinance = (data) => {
    setLoader(true);
    callFBFunction('finance', data).then(async ({ data: response }) => {
      const { message } = response;
      setLoader(false);
      if (response.status) {
        if (data.type === 'estimate') {
          const tagManagerArgs = {
            event: 'e_dignifi_calculate',
            area:
              path === 'financing' ? 'financing' : path === 'parts-and-accessories' ? 'parts' : ''
          };
          eventReactGTMModule(tagManagerArgs);
          setStatus(true);
        }
        setStatusData(response.data);
      } else {
        setNotify({ message, type: 'error' });
      }
    });
  };

  const onSubmit = async () => {
    await getFinance({
      type: 'estimate',
      financedAmount: amount
    });
  };

  useEffect(() => {
    setLoader(true);
    callFBFunction('finance', {
      type: 'status'
    }).then(async ({ data: response }) => {
      const { data, message } = response;
      setLoader(false);
      if (response.status) {
        if (data && data.application) {
          if (data.application.status === 'DECLINED' || data.application.status === 'EXPIRED') {
            setStatus(false);
            setActiveStatusData(null);
          } else if (
            data.application.status === 'APPROVED' ||
            data.application.status === 'REVOLVING' ||
            data.application.status === 'INSTALLMENT' ||
            data.application.status === 'PENDING'
          ) {
            setActiveStatus(true);
          } else {
            const responseData = {
              status: data.application.status,
              description: data.application.description,
              loanType: data.application.loanType,
              approvedAmount:
                data.application.approvedAmount !== null ? data.application.approvedAmount : 0,
              invoiceAmount:
                data.application.invoiceAmount !== null ? data.application.invoiceAmount : 0,
              loanAmount: data.application.loanAmount !== null ? data.application.loanAmount : 0
            };
            setActiveStatusData(responseData);
            setActiveStatus(false);
          }
        } else {
          setStatus(false);
        }
      } else {
        setNotify({ message, type: 'error' });
      }
    });
  }, []);

  return (
    <>
      {activeStatus !== null && activeStatus ? (
        <div className={customClassPending}>
          <FinancePendingBanner />
        </div>
      ) : (
        <div className={activeStatus !== null && customClassApproved}>
          <FinanceApprovedBanner
            rooftop={rooftop}
            accessories
            shadow={shadow}
            data={activeStatusData}
            desktopHide={desktopHide}
          />
        </div>
      )}
      {status !== null && status ? (
        <div
          className={`bg-white rounded-lg px-4 py-5 md:px-8 mt-3 md:mt-12 mt-5 md:mt-8 ${
            path === 'financing' || path === 'service' ? 'md:px-[32px]' : 'md:px-[64px]'
          }`}>
          <div className="w-full border-b pb-5 flex flex-col md:flex-row items-start md:items-center justify-between gap-5 md:gap-0">
            <h2 className="text-2xl font-extrabold">Payment Estimate</h2>
            <button
              type="button"
              onClick={() => {
                setStatus(false);
                setAmount('');
              }}
              className="cursor-pointer bg-[#F2F3F6] text-[#5046E4] py-4 px-9 rounded-full flex flex-row items-center gap-2">
              <img className="w-auto h-4" src={appImage.ResetIcon} alt="reset icon" />
              Reset
            </button>
          </div>
          {statusData &&
            statusData.financedAmount !== null &&
            statusData.monthlyPayment !== null &&
            statusData.originationCharge !== null &&
            statusData.apr !== null && (
              <>
                <div className="grid grid-cols-2 md:grid-cols-5 pt-8 mb-5 md:mb-12 gap-5 md:gap-0">
                  <div className="col-span-1 p-3 border-r">
                    <p className="text-[15px] text-[#898989]">Financed Amount</p>
                    <span className="text-lg font-bold">
                      ${statusData && statusData.financedAmount}
                    </span>
                  </div>
                  <div className="col-span-1 p-3 md:border-r">
                    <p className="text-[15px] text-[#898989]">Monthly Payment</p>
                    <span className="text-lg font-bold">
                      ${statusData && statusData.monthlyPayment}
                    </span>
                  </div>
                  <div className="col-span-1 p-3 border-r">
                    <p className="text-[15px] text-[#898989]">Term</p>
                    <span className="text-lg font-bold">
                      {statusData && statusData.term} months
                    </span>
                  </div>
                  <div className="col-span-1 p-3 md:border-r">
                    <p className="text-[15px] text-[#898989]">Origination Charge</p>
                    <span className="text-lg font-bold">
                      ${statusData && statusData.originationCharge}
                    </span>
                  </div>
                  <div className="col-span-1 p-3">
                    <p className="text-[15px] text-[#898989]">APR</p>
                    <span className="text-lg font-bold">{statusData && statusData.apr}%</span>
                  </div>
                </div>
              </>
            )}
          {statusData &&
          statusData.financedAmount === null &&
          statusData.monthlyPayment === null &&
          statusData.originationCharge === null &&
          statusData.apr === null ? (
            <p className="max-w-md mb-6 mt-6">
              Estimates are not provided for values higher than $5000 or less than $350. Your
              payment will be calculated during the application process. Get started below.
            </p>
          ) : (
            <p className="max-w-md mb-6">
              Don't wait any longer! Take the next step and kick-start your pre-qualification
              process now.
            </p>
          )}
          <button
            type="button"
            onClick={() => {
              const tagManagerArgs = {
                event: 'e_dignifi_prequal',
                area:
                  path === 'financing'
                    ? 'financing'
                    : path === 'parts-and-accessories'
                    ? 'parts'
                    : ''
              };
              eventReactGTMModule(tagManagerArgs);
              window.open(statusData && statusData.referralLink);
            }}
            className="cursor-pointer flex items-center justify-center bg-[#5046E4] text-[14px] text-white px-[16px] py-[9px] rounded-md inline-flex mb-10">
            Start Prequalification
          </button>
          {statusData && statusData.disclosure !== null && (
            <>
              <h5 className="text-[#9D9D9D] font-bold text-[13px] mb-3">Disclosure</h5>
              <p className="text-[#9D9D9D] text-[12px]">{statusData && statusData.disclosure}</p>
            </>
          )}
        </div>
      ) : (
        status !== null && (
          <div
            className={`bg-white rounded-lg px-4 py-5 md:px-8 mt-3 md:mt-12 mt-5 md:mt-8 ${
              path === 'financing' || path === 'service' ? 'md:px-[32px]' : 'md:px-[64px]'
            }`}>
            <div className="w-full border-b pb-2 flex flex-col md:flex-row justify-between  items-start md:items-center">
              <h2 className="text-2xl font-extrabold mb-2 md:mb-0">
                {rooftop && rooftop.name} Financing Program
              </h2>
              <div className="flex flex-col">
                <span className="text-[11px] font-light md:ml-4">Powered by</span>
                <img
                  className="w-[80px] md:w-[140px] h-auto translate-x-[-8px]	md:translate-x-0"
                  src={DignifiLogo}
                  alt="Dignifi Logo"
                />
              </div>
            </div>
            <div className="grid grid-cols-12 py-8 md:py-10">
              <div className="col-span-12">
                <h3 className="text-lg font-bold mb-4 md:mb-6 flex flex-row items-center">
                  Calculate your payment
                  <img className="w-5" src={appImage.Calculator} alt="" />
                </h3>
              </div>
              <div className="col-span-12 md:col-span-7">
                <p className="max-w-sm mb-10 md:mb-12">
                  We can help you with access to financing: vehicle repairs, tire services,
                  accessories, preventative maintenance and much more.
                </p>
                <h4 className="text-sm font-medium mb-2">Financed Amount</h4>
                <div className="relative mb-6">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
                    <img alt="dollar" src={appImage.DollarIcon} style={{ width: '24px' }} />
                  </div>
                  <input
                    className="pl-6 appearance-none block max-w-lg text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-7"
                    value={amount}
                    type="number"
                    style={{ width: '50%' }}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-span-5 hidden md:flex">
                <div className="">
                  <span className="flex flex-row items-start">
                    <ChevronRightIcon className="h-7 mb-0.5 text-[#5046E4]" />
                    No down payment required.
                  </span>
                  <span className="flex flex-row items-start">
                    <ChevronRightIcon className="h-7 mb-0.5 text-[#5046E4]" />
                    <span>
                      Flexible payments from
                      <span className="font-semibold"> 12 - 36 months **</span>
                    </span>
                  </span>
                  <span className="flex flex-row items-start">
                    <ChevronRightIcon className="h-7 mb-0.5 text-[#5046E4]" />
                    No application or prepayment fees
                  </span>
                  <span className="flex flex-row items-start">
                    <ChevronRightIcon className="h-7 mb-0.5 text-[#5046E4]" />
                    Won't affect your credit score!
                  </span>
                </div>
              </div>
              <div className="col-span-12">
                <button
                  type="button"
                  onClick={onSubmit}
                  disabled={activeStatus || amount === ''}
                  className="cursor-pointer flex items-center justify-center bg-[#5046E4] text-[14px] text-white px-[16px] py-[9px] rounded-md inline-flex">
                  Calculate Payment
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default withLoader(withNotify(Finance));
