import React from 'react';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';
import appImage from '../../assets/image';
import { eventReactGTMModule } from '../../utils/analytics';

const FinanceApprovedBanner = ({ data, rooftop, accessories = false, desktopHide, shadow }) => {
  const { pathname } = useLocation();
  const path = pathname.replace('/', '');

  const onRedirectPage = (link, destination) => {
    const tagManagerArgs = {
      event: 'e_dealer_visit',
      area: path === 'financing' ? 'financing' : path === 'parts-and-accessories' ? 'parts' : '',
      external_destination: destination
    };
    eventReactGTMModule(tagManagerArgs);
    window.open(link, '_blank');
  };

  return (
    <>
      {data && (
        <>
          <div
            className={`flex flex-col gap-6 relative bg-white rounded-lg mt-10 mb-0 mb:mb-14 ${
              shadow
                ? `px-4  sm:px-8 md:px-8 md:py-8 ${
                    path === 'parts-and-accessories'
                      ? 'md:mt-0'
                      : 'shadow ml-4 md:ml-8 mr-4 md:mr-8'
                  }`
                : 'ml-4 md:ml-8 mr-4 md:mr-8 md:mt-[32px]'
            }`}>
            <div className="flex flex-row items-center gap-3">
              <img className="w-[24px] h-[24px]" src={appImage.CheckmarkCircle} alt="" />
              <p className="text-lg md:text-[22px]">
                You have an active
                {data && data.loanType === 'INSTALLMENT'
                  ? ' installment loan '
                  : ' line of credit '}
                with us, start using it today
              </p>
            </div>
            <div className="border-b w-full" />
            <div className="grid grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-4 md:gap-0 ">
              <div className="col-span-1 md:col-span-1 flex flex-col">
                <span className="text-[15px] text-[#898989]">Type</span>
                <p className="text-lg font-bold">
                  {data && data.loanType && capitalize(data.loanType)}
                </p>
              </div>
              <div className="col-span-1 flex flex-col">
                <span className="text-[15px] text-[#898989]">
                  {data && data.loanType === 'INSTALLMENT' ? 'Loan Amount' : 'Credit Line'}
                </span>
                <p className="text-lg font-bold">${data && data.loanAmount}</p>
              </div>
            </div>
            <div className="hidden md:block border-b w-full" />
          </div>
          {accessories && (
            <div
              className={`grid grid-cols-8 md:grid-cols-12 gap-x-4 gap-y-5 mt-8 px-0 mx-4 md:mx-8 mt-5 mb-[50px] md:mt-8 md:mb-[48px] ${
                desktopHide ? 'hidden' : ''
              }`}>
              {rooftop && rooftop.external_links && rooftop.external_links.accessories && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.accessories,
                      'accessories'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">
                        Accessories
                      </h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Accessories and mods for your ride
                    </p>
                  </div>
                </div>
              )}
              {rooftop && rooftop.external_links && rooftop.external_links.finance && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.finance,
                      'coupons'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">Coupons</h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Discounts on Service & Parts
                    </p>
                  </div>
                </div>
              )}
              {/* {rooftop && rooftop.external_links && rooftop.external_links.new_inventory && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.new_inventory,
                      'new_inventory'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">
                        Shop New Inventory
                      </h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Brand new vehicles from the manufacturer
                    </p>
                  </div>
                </div>
              )} */}
              {/* {rooftop && rooftop.external_links && rooftop.external_links.preowned && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.preowned,
                      'used_inventory'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">
                        Certified Pre-Owned
                      </h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      The very best of our used inventory
                    </p>
                  </div>
                </div>
              )} */}
              {rooftop && rooftop.external_links && rooftop.external_links.parts_order && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.parts_order,
                      'parts_order'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">
                        Order Parts
                      </h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Order the exact part you need
                    </p>
                  </div>
                </div>
              )}
              {rooftop && rooftop.external_links && rooftop.external_links.parts && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.parts,
                      'parts_center'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">
                        Parts Center
                      </h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Browse the parts department
                    </p>
                  </div>
                </div>
              )}
              {rooftop && rooftop.external_links && rooftop.external_links.trade && (
                <div
                  className="cursor-pointer col-span-4 bg-[#F8F8F8] rounded-lg shadow p-4"
                  onClick={() =>
                    onRedirectPage(
                      rooftop && rooftop.external_links && rooftop.external_links.parts_order,
                      'trade'
                    )
                  }>
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between items-center">
                      <h2 className="text-sm md:text-base font-medium text-gray-900">Trade</h2>
                      <img className="w-5 h-5 h-auto" src={appImage.ExternalLink} alt="asd" />
                    </div>
                    <p className="text-[13px] text-black font-light hidden md:block">
                      Find out how much your car is worth
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FinanceApprovedBanner;
