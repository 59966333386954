import React, { Fragment, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { Dialog, Transition } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/solid';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import { callFBFunction } from '../utils/firebase';
import { eventAnalytics, eventReactGTMModule } from '../utils/analytics';
import { ACTION, CATEGORY } from '../constants';
import { checkFirebaseSession } from '../helpers/app';
import Google from '../assets/image/google.png';

const GoodOrBadReview = ({
  onGoodOrBadReview,
  goodOrBadReview,
  staff,
  user,
  setUser,
  setNotify,
  onContinue,
  setLoader,
  rooftop,
  reviewType,
  loader
}) => {
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState(null);

  const onBack = () => {
    eventAnalytics({
      category: CATEGORY.REVIEW,
      action: ACTION.REV_BACK,
      nonInteraction: false
    });
    const tagManagerArgs = {
      event: 'e_review_back',
      agentID: staff.id // sales agent being reviewed
    };
    eventReactGTMModule(tagManagerArgs);
    onGoodOrBadReview();
  };

  const onSubmitGoogle = () => {
    eventAnalytics({
      category: CATEGORY.REVIEW,
      action: ACTION.REV_GOOGLE_CLICK,
      nonInteraction: false
    });
    const tagManagerArgs = {
      event: 'e_google_review_click',
      agentID: staff.id, // sales agent being reviewed
      // rating: staff.rating,
      area: 'review_modal'
    };
    eventReactGTMModule(tagManagerArgs);
    window.open(rooftop?.external_links?.google_review_link, '_blank');
    onContinue();
    onBack();
  };

  const onSubmit = async () => {
    const {
      name: { first, last },
      avatar,
      id
    } = user;
    const badOrGoodReview = reviewType === 'good' ? rating : 0;

    // Validation
    if (reviewType === 'bad') {
      if (comment === null) {
        setNotify({ message: 'Please give Review.', type: 'error' });
        return;
      }
    }

    setLoader(true);
    const payload = {
      rating: badOrGoodReview,
      comment: comment === null ? '' : comment,
      user: id,
      name: { first, last },
      avatar
    };
    await checkFirebaseSession();
    callFBFunction('leaveReview', { payload }).then(async ({ data: response }) => {
      const { status, message, data } = response;
      if (status) {
        setUser({ ...user, leave_review: data.leave_review });
        setLoader(false);
        onGoodOrBadReview();
        onContinue();
        eventAnalytics({
          category: CATEGORY.REVIEW,
          action: ACTION.REV_SAVE,
          nonInteraction: false
        });
        const tagManagerArgs = {
          event: 'e_review_save',
          agentID: staff.id, // sales agent being reviewed
          // rating: staff.rating,
          area: 'review_modal'
        };
        eventReactGTMModule(tagManagerArgs);
        setNotify({ message, type: 'success' });
      } else {
        setLoader(false);
        onGoodOrBadReview();
        onContinue();
        setNotify({ message, type: 'error' });
      }
    });
  };

  const ratingChanged = (newRating) => {
    eventAnalytics({
      category: CATEGORY.REVIEW,
      action: ACTION.REV_RATING,
      nonInteraction: false
    });
    setRating(newRating);
  };

  const onChangeComment = (value) => {
    setComment(value);
  };

  return (
    <>
      <Transition.Root show={goodOrBadReview} as={Fragment} onClose={onGoodOrBadReview}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              {rooftop.google_review && reviewType === 'good' ? (
                <div className="relative inline-block align-bottom bg-white rounded-lg sm:w-11/12 md:w-8/12 lg:w-3/5 xl:w-6/12 2xl:w-4/12 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
                  <div className="w-full flex items-center justify-between space-x-6">
                    <div className="flex flex-col-reverse">
                      <div className="">
                        <Dialog.Title
                          as="h1"
                          className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-1xl">
                          {staff.name.first} {staff.name.last}
                        </Dialog.Title>

                        <Dialog.Title as="h2" id="information-heading" className="sr-only">
                          Product information
                        </Dialog.Title>
                        <p className="text-sm text-gray-500 mt-2">{`Sales Agent - ${rooftop.name}`}</p>
                      </div>
                    </div>
                    <img
                      className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"
                      src={staff.avatar}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="mt-2 text-center sm:mt-10">
                      <div className="flex items-center justify-center py-10 pt-0">
                        <img
                          className="h-52 w-52 p-12 rounded-full flex-shrink-0"
                          src={Google}
                          alt=""
                        />
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-400 md:text-lg">
                          Share your experience on Google Reviews helps us out tremendously.
                        </p>
                        <p className="mt-4 text-sm text-gray-400 md:text-lg">
                          Share your review today!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-2xl mx-auto flex items-center justify-center gap-5 text-center py-11 px-4 sm:py-15 sm:px-6 lg:px-8">
                    <button
                      type="button"
                      className="shadow-sm w-full inline-flex items-center justify-center px-5 py-3 border border-transparent border-b-white text-base font-medium rounded-md text-gray-500 bg-white hover:bg-indigo-50 sm:w-auto drop-shadow-2xl"
                      onClick={() => {
                        onBack();
                        onContinue();
                      }}>
                      Skip
                    </button>
                    <button
                      type="button"
                      className="cursor-pointer md:block relative my-auto mr-0 inline-flex items-center px-5 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={onSubmitGoogle}>
                      Leave a Google Review
                    </button>
                  </div>
                </div>
              ) : (
                <div className="relative inline-block align-bottom bg-white rounded-lg sm:w-11/12 md:w-8/12 lg:w-3/5 xl:w-6/12 2xl:w-4/12 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
                  {reviewType === 'good' ? (
                    <>
                      <div className="w-full flex items-center justify-between space-x-6">
                        <div className="flex flex-col-reverse">
                          <div className="mt-4">
                            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-1xl">
                              {staff.name.first} {staff.name.last}
                            </h1>

                            <h2 id="information-heading" className="sr-only">
                              Product information
                            </h2>
                            <p className="text-sm text-gray-500 mt-2">{`Sales Agent - ${rooftop.name}`}</p>
                          </div>
                          <div className="flex items-center">
                            <ReactStars
                              size={20}
                              count={5}
                              color="#F5F5F5"
                              activeColor="#D8D8D8"
                              value={staff.rating}
                              isHalf
                              edit={false}
                            />
                            <p className="text-sm text-gray-500 ml-2">Average rating</p>
                          </div>
                        </div>
                        <img
                          className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"
                          src={staff.avatar}
                          alt=""
                        />
                      </div>
                      <div className="mt-5 flex items-center justify-center">
                        <ReactStars
                          count={5}
                          onChange={ratingChanged}
                          size={50}
                          activeColor="#ffd700"
                          emptyIcon={<StarIcon />}
                          fullIcon={<StarIcon />}
                          value={rating}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <img
                        className="mx-auto h-48 w-48 text-gray-400"
                        src="https://firebasestorage.googleapis.com/v0/b/vinsyt-prod.appspot.com/o/app%2FIdentity.png?alt=media&token=cba662dc-698c-410e-bc53-305e9c1e2f73"
                        alt=""
                      />
                      <p className="mt-6 text-sm text-gray-500 mb-4">
                        We regret to hear you had a negative experience.
                      </p>
                      <p className="text-sm text-gray-500 mb-4">
                        We strive for 100% customer satisfaction and anything less is not OK.
                      </p>
                      <p className="text-sm text-gray-500 mb-6">
                        Using this feature provides feedback directly to our management team so that
                        we can immediately address your concerns and do everything possible to make
                        it right.
                      </p>
                    </div>
                  )}

                  <div className="mt-6">
                    <div className="rounded-lg">
                      <label htmlFor="comment" className="sr-only">
                        Comment
                      </label>
                      <div className="mx-11">
                        <textarea
                          rows="5"
                          name="comment"
                          id="comment"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full h-52 sm:text-sm border-gray-300 rounded-md"
                          placeholder={`${
                            reviewType === 'good'
                              ? 'Tell us about your experience'
                              : "Please let us know what aspect(s) of your experience didn't meet your expectations."
                          }`}
                          onChange={(event) => onChangeComment(event.target.value)}
                          onFocus={() => {
                            eventAnalytics({
                              category: CATEGORY.REVIEW,
                              action: ACTION.REV_COMMENT,
                              nonInteraction: false
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className={`rounded-lg ${reviewType === 'good' ? 'mb-10' : ''}`}>
                      {reviewType === 'bad' ? (
                        <div className="mx-11 mx-px mt-px ml-14 pt-2 pb-12 text-sm leading-5 text-gray-500">
                          The management team at {rooftop.shortname} will receive your feedback
                        </div>
                      ) : (
                        ''
                      )}
                    </div> */}
                  </div>
                  <div className="justify-center text-center items-center justify-end p-4 rounded-b-md">
                    <button
                      type="button"
                      className="px-6 py-2.5 text-black rounded shadow-md"
                      onClick={onBack}
                      disabled={loader}>
                      Back
                    </button>
                    <button
                      type="button"
                      className="px-6 py-2.5 bg-indigo-600 text-white rounded shadow-md ml-2"
                      onClick={onSubmit}
                      disabled={loader}>
                      {reviewType === 'good' ? 'Save Your Review' : 'Send Feedback '}
                    </button>
                  </div>
                  {rooftop.google_review && (
                    <div className="justify-center text-center items-center justify-end p-4 rounded-b-md">
                      <p className="text-sm text-gray-500">
                        Or, if you prefer, you can use this link to leave a review on our Google My
                        Business page.{' '}
                        <button
                          type="button"
                          className="text-indigo-600"
                          onClick={() => {
                            window.open(rooftop.external_links.google_review_link, '_blank');
                            onGoodOrBadReview();
                            onContinue();
                          }}>
                          Leave a Google review
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default withNotify(withLoader(withUser(GoodOrBadReview)));
