import React, { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import withUser from '../redux/Hoc/withUser';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import {
  deleteFile,
  getProfileById,
  getRoofTopById,
  updateProfile,
  uploadFile
} from '../utils/firebase';
import { STORAGE_PATH } from '../constants/index';
import DeleteModal from '../modals/Delete';
import { eventReactGTMModuleSales } from '../utils/analytics';

const PhotoGallery = ({ user, setLoader, setNotify, setUser }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [rooftopDetail, setRooftopDetail] = useState({});

  useEffect(() => {
    async function onInit() {
      const response = await getRoofTopById(user.rooftop);
      setRooftopDetail(response);
    }
    onInit();
  }, []);

  const onSelectImage = async (event) => {
    if (event.files && event.files[0]) {
      try {
        setLoader(true);
        const imageData = event.files[0];
        if (_.isEmpty(imageData.name)) {
          setNotify({ type: 'error', message: 'Please select avatar.' });
        } else {
          await uploadFile(STORAGE_PATH.SALES_GALLERY, imageData)
            .then(async (avatar) => {
              const profileRes = await getProfileById(user.id);
              if (_.isEmpty(profileRes.photos)) {
                profileRes.photos = [];
              }
              profileRes.photos.push(avatar);
              delete profileRes.id;
              const response = await updateProfile(user.id, profileRes);
              setLoader(false);
              if (response) {
                user.photos = profileRes.photos;
                setUser({ ...user });
                setLoader(false);
                const tagManagerArgs = {
                  event: 'e_file_upload',
                  agentID: user.staff,
                  rooftop: user.rooftop,
                  dealerGroupID: user.rooftopData.group,
                  experience: 'sa_private'
                };
                eventReactGTMModuleSales(tagManagerArgs);
                setNotify({ type: 'success', message: 'Image uploaded successfully.' });
              } else {
                setLoader(false);
                setNotify({ type: 'error', message: 'Image upload failed! Please try again.' });
              }
              setLoader(false);
            })
            .catch(() => {
              setLoader(false);
              setNotify({ type: 'error', message: 'Image upload failed! Please try again.' });
            });
          setLoader(false);
        }
      } catch (e) {
        setLoader(false);
        setNotify({ type: 'error', message: 'Image upload failed! Please try again.' });
      }
    }
  };
  const onChoosePhoto = () => {
    document.getElementById('galleryImage').click();
  };

  const onDeleteImage = (photo) => {
    setSelectedPhoto(photo);
    setIsDeleteModalOpen(true);
  };

  const onClose = () => {
    setIsDeleteModalOpen(false);
    setSelectedPhoto(null);
  };

  const onContinue = async () => {
    try {
      setLoader(true);
      await deleteFile(selectedPhoto).then(async (response) => {
        if (response) {
          const profileRes = await getProfileById(user.id);
          profileRes.photos = _.remove(profileRes.photos, function removeImage(item) {
            return item !== selectedPhoto;
          });
          delete profileRes.id;
          const updatedRes = await updateProfile(user.id, profileRes);
          if (updatedRes) {
            user.photos = profileRes.photos;
            setUser({ ...user });
            setLoader(false);
            const tagManagerArgs = {
              event: 'e_file_delete',
              agentID: user.staff,
              rooftop: user.rooftop,
              dealerGroupID: user.rooftopData.group,
              experience: 'sa_private'
            };
            eventReactGTMModuleSales(tagManagerArgs);
            setNotify({ type: 'success', message: 'Image deleted successfully.' });
          } else {
            setLoader(false);
            setNotify({ type: 'error', message: 'Image deleted failed! Please try again.' });
          }
        } else {
          setLoader(false);
          setNotify({
            type: 'error',
            message:
              'Image deleted failed! You can delete after image few minutes. Please try again.'
          });
        }
      });
    } catch (e) {
      setLoader(false);
      setNotify({
        type: 'error',
        message: 'Image deleted failed! You can delete image after few minutes. Please try again.'
      });
    }
    setIsDeleteModalOpen(false);
    setSelectedPhoto(null);
  };

  return (
    <>
      <main className="lg:col-span-12 xl:col-span-9">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg text-center">
          <div className="w-full flex items-center justify-between p-6 space-x-6 border-b border-gray">
            <div className="relative group">
              <img
                className="w-20 h-20 bg-gray-300 rounded-full flex-shrink-0"
                src={user.avatar}
                alt=""
              />
            </div>
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {user.name.first}&nbsp;
                  {user.name.last}
                </h3>
              </div>
              <div className="flex items-center space-x-3">
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {user.title} - {rooftopDetail.name}
                </p>
              </div>
            </div>
          </div>
          <section className="overflow-hidden text-gray-700 ">
            <div className="container px-5 py-2 mx-auto lg:py-8">
              <div className="flex flex-wrap -m-1 md:-m-2 sm:-m-2">
                {user.photos?.length !== 0 &&
                  user.photos?.map((photo, key) => (
                    <div key={key} className="flex flex-wrap md:w-1/4 sm:w-1/3">
                      <div className="w-full relative group p-1 sm:p-4 md:p-6">
                        <p
                          role="presentation"
                          onClick={() => onDeleteImage(photo)}
                          className="opacity-0 group-hover:opacity-75 duration-300 absolute rounded-full h-5 w-5 bg-amber-50 sm:top-4 sm:right-4 md:top-6 md:right-6 lg:top-7 lg:right-7">
                          <XCircleIcon />
                        </p>
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full rounded-lg sm:h-20 md:h-24 lg:h-28"
                          src={photo}
                        />
                      </div>
                    </div>
                  ))}
                <div className="sm:w-1/4 md:w-1/5 lg:mt-6 lg:ml-6 md:mt-5 md:ml-5 sm:mt-3.5 sm:ml-3.5">
                  <div className="flex flex-wrap items-center justify-center relative sm:h-20 md:h-24 lg:h-28 p-1 sm:p-4 sm:p-4 md:p-6 border border-dashed border-2 border-gray-600 block object-fill object-center rounded-lg">
                    <input
                      type="file"
                      accept="image/*"
                      id="galleryImage"
                      className="hidden"
                      onChange={(e) => onSelectImage(e.target)}
                    />
                    <button
                      onClick={onChoosePhoto}
                      className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm sm:px-1.5 sm:py-1.5 md:px-2.5 md:py-2.5 focus:outline-none"
                      type="button">
                      Choose photo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="mb-52" />
        </div>
      </main>
      {isDeleteModalOpen && <DeleteModal onClose={onClose} onContinue={onContinue} />}
    </>
  );
};

export default withNotify(withLoader(withUser(PhotoGallery)));
