import React from 'react';

const OfferCard = ({ rooftop }) => (
  <div className="absolute md:inset-x-8 -translate-y-64 md:translate-y-0 md:-bottom-11">
    <div className="w-full">
      <div className="flex flex-col md:flex-row text-white mx-auto">
        <div className="flex-1 p-8 lg:p-4 xl:p-8 bg-[#221D5E] rounded-t-[10px] md:rounded-tr-none md:mt-5 md:mb-11 lg:mt-16">
          <h2 className="text-xl font-bold mb-4">They Win</h2>
          {rooftop && rooftop.refer && rooftop.refer.they_get && rooftop.refer.you_get ? (
            <p>
              They get <span className="text-[#FF9AD0] font-bold"> ${rooftop.refer.they_get}</span>{' '}
              off the purchase of any new vehicle at {rooftop && rooftop.name}
            </p>
          ) : (
            <p className="xl:text-lg">They qualify for exclusive discounts</p>
          )}
        </div>
        <div className="md:max-w-[286px] md:aspect-square w-full p-8 bg-[#3D8DEB] md:rounded-[10px] shadow-xl">
          <h2 className="text-xl font-bold mb-4">You Win</h2>
          {rooftop && rooftop.refer && rooftop.refer.you_get ? (
            <p className="mb-4">
              You get ${rooftop.refer.you_get} any time one of them buys a new car from{' '}
              {rooftop && rooftop.name}.
            </p>
          ) : (
            <p className="mb-4">You qualify for exclusive discounts.</p>
          )}
          <p>Simply enter their contact info below and I take care of the rest!</p>
        </div>
        <div className="flex-1 p-8 bg-[#221D5E] rounded-b-[10px] md:rounded-b-none md:rounded-tr-[10px] md:mt-5 md:mb-11 lg:mt-16">
          <h2 className="text-xl font-bold mb-4">I Win</h2>
          <p>Recommendations from satisfied customers are one of my greatest rewards.</p>
        </div>
      </div>
    </div>
  </div>
);

export default OfferCard;
