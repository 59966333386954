import React, { useEffect, useState } from 'react';
import _, { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import withUser from '../redux/Hoc/withUser';
import withLoader from '../redux/Hoc/withLoader';
import withVehicleContent from '../redux/Hoc/withVehicleContent';
import {
  callFBFunction,
  getRoofTopById,
  getUserById,
  getVehiclesByUserId
} from '../utils/firebase';
import { eventAnalytics } from '../utils/analytics';
import { ACTION, CATEGORY } from '../constants';

const Photos = ({ user, setLoader, vehicleContent }) => {
  const { userId } = useParams();
  const [vehicleContentList, setVehicleContentList] = useState(null);

  const onClickVehiclePhoto = (image) => {
    eventAnalytics({
      category: CATEGORY.ASSET,
      action: ACTION.PHOTO_VIEW,
      label: image.title,
      nonInteraction: false
    });
    window.open(image.source, '_blank');
  };

  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const vehicles = [];
      let vehicleContentData = {};
      if (_.isEmpty(user.id)) {
        const userRes = await getUserById(userId);
        if (isEmpty(userRes)) {
          window.location = '/';
        }
        const roofTopRes = await getRoofTopById(userRes.rooftop);
        if (Object.prototype.hasOwnProperty.call(userRes, 'disabled') && userRes.disabled) {
          window.location = roofTopRes.url.main || '/';
        }

        const vehicleData = await getVehiclesByUserId(userId);
        vehicleData.map((value) => {
          vehicles.push({
            make: value.attributes.make,
            model: value.attributes.model,
            year: value.attributes.year
          });
        });
        if (vehicles.length > 0) {
          const payload = {
            make: vehicles[0].make,
            model: vehicles[0].model,
            year: vehicles[0].year
          };
          callFBFunction('getVehicleContent', payload).then(({ data: response }) => {
            const { status } = response;
            if (status) {
              const { data } = response;
              vehicleContentData = data;
              if (vehicleContentData.images.length > 0) {
                const vehicleContentPhotos = _.uniqWith(
                  vehicleContentData.images,
                  (vehicle1, vehicle2) => vehicle1.source === vehicle2.source
                );
                setVehicleContentList(vehicleContentPhotos);
              }
            }
            setLoader(false);
          });
        }
      } else {
        vehicleContentData = vehicleContent;
      }
      if (vehicleContentData.images.length > 0) {
        const vehicleContentPhotos = _.uniqWith(
          vehicleContentData.images,
          (vehicle1, vehicle2) => vehicle1.source === vehicle2.source
        );
        setVehicleContentList(vehicleContentPhotos);
      }
      setLoader(false);
    }
    onInit();
  }, [user]);

  return !vehicleContentList ? (
    <>
      <main className="lg:col-span-12 xl:col-span-6">
        <p className="mt-20 text-center">No Vehicle Photos Found.</p>
      </main>
    </>
  ) : (
    <>
      <main className="lg:col-span-10 xl:col-span-10">
        <div className="mb-7">
          <h2 className="text-2xl leading-6 font-medium text-[#333333]">Photos</h2>
        </div>
        {vehicleContentList.length > 0 ? (
          <ul
            role="list"
            className="grid grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-4 md:gap-6 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-8">
            {vehicleContentList.map((image, pIndex) => (
              <li key={`photos-${pIndex}`} className="relative cursor-pointer">
                <span
                  onClick={() => onClickVehiclePhoto(image)}
                  className="group block w-full aspect-w-16 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden hover:scale-[1.1] ease-out duration-300 drop-shadow-[0px_2px_4px_rgba(0,0,0,0.3)] hover:drop-shadow-[0px_10px_12px_rgba(0,0,0,0.5)]">
                  <img
                    src={image.source}
                    alt=""
                    className="object-fill object-cover pointer-events-none group-hover:opacity-90 bg-contain"
                  />
                </span>
                <p className="mt-2 block text-sm font-medium text-gray-900 pointer-events-none">
                  {image.title}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="mt-20 text-center">No Vehicle Images Found.</p>
        )}
      </main>
    </>
  );
};

export default withVehicleContent(withUser(withLoader(Photos)));
