import React from 'react';
import Slider from 'react-slick';

const sliderSettings = {
  lazyLoad: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const CustomerTestimonials = ({ data }) => (
  <div className="w-full max-w-2xl mx-auto mt-6 md:mt-16 lg:max-w-none lg:mt-0 lg:col-span-4">
    <p className="whitespace-nowrap py-6 font-medium text-sm border-b border-gray-200 mb-4 md:mb-10">
      Customer testimonials
    </p>
    <Slider {...sliderSettings}>
      {data.map((item, index) => (
        <div
          key={`video-${index}`}
          className="group items-center block w-full aspect-[15/10] rounded-lg bg-gray-100 overflow-hidden">
          <video
            controls
            autoPlay={false}
            playsInline
            className="w-full h-full object-fit"
            src={item.link}
            alt="video_testimonial"
          />
        </div>
      ))}
    </Slider>
  </div>
);

export default CustomerTestimonials;
