/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable camelcase */
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Popover, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';
import {
  BellIcon,
  HomeIcon,
  MenuIcon,
  FilmIcon,
  PhotographIcon,
  UsersIcon,
  XIcon,
  TruckIcon,
  GiftIcon,
  UserCircleIcon,
  CogIcon,
  ShoppingCartIcon
} from '@heroicons/react/outline';
import _, { isEmpty } from 'lodash';
import { checkFirebaseSession, classNames, userLogout } from './helpers/app';
import withUser from './redux/Hoc/withUser';
import withLoader from './redux/Hoc/withLoader';
import withVehicleContent from './redux/Hoc/withVehicleContent';
import {
  getRoofTopById,
  getStaffById,
  getVehiclesByUserId,
  getGroupById,
  callFBFunction
} from './utils/firebase';
import { eventReactGTMModule, initAnalytics, initReactGTMModule } from './utils/analytics';
import ReportModal from './modals/ReportModal';
import appImage from './assets/image';

let closeDrawerRef = () => {};

const Layout = ({ user, setLoader, setVehicleContent }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const rooftop = user.rooftopData;
  const [currentRoute, setCurrentRoute] = useState('/');
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    setCurrentRoute(location.pathname);
    closeDrawerRef();
  }, [location]);

  const gaEventCall = (profile_item, avatar_step) => {
    const tagManagerArgs = {
      event: 'e_profile_update',
      area: 'profile',
      avatar_step,
      profile_item
    };
    eventReactGTMModule(tagManagerArgs);
  };

  const insertAtPosition = (prev, item, position) => [
    ...prev.slice(0, position),
    item,
    ...prev.slice(position)
  ];

  useEffect(() => {
    async function myApi() {
      if (!isEmpty(user.id)) {
        initAnalytics(user.id);
        const roofTopData = await getRoofTopById(user.rooftop);
        const groupData = await getGroupById(roofTopData.group);
        const staffData = await getStaffById(user.staff);
        const vehicleData = await getVehiclesByUserId(user.id);
        const vehicle = [];
        vehicleData.map((value) => {
          vehicle.push({
            make: value.attributes.make,
            model: value.attributes.model,
            year: value.attributes.year
          });
        });
        const tagManagerArgs = {
          ownerID: user.id,
          user_type: user.role.includes('sales') ? 'sales agent' : 'owner',
          preferred_dealer_groupID: roofTopData.group,
          preferred_dealer_group: _.isEmpty(groupData) ? '' : groupData.name,
          rooftop: roofTopData.name,
          rooftopID: roofTopData.id,
          sales_agentID: user.staff,
          agent_rating: staffData.rating,
          vehicles: vehicle,
          environment: process.env.REACT_APP_ENV,
          version: '2.O'
        };
        initReactGTMModule(tagManagerArgs);
        // if (Object.keys(roofTopData).length > 0) {
        //   setRoofTop(roofTopData);
        // }

        // Get vehicle content
        if (vehicle.length) {
          setLoader(true);
          const payload = {
            make: vehicle[0].make,
            model: vehicle[0].model,
            year: vehicle[0].year
          };
          callFBFunction('getVehicleContent', payload).then(({ data: response }) => {
            const { status } = response;
            if (status) {
              const { data } = response;
              setVehicleContent(data);
            }
            setLoader(false);
          });
        }

        // GTM
        if (user.role.includes('owner')) {
          const gtmData = {
            event: 'e_vinsyt_visit'
          };
          eventReactGTMModule(gtmData);
        }

        if (user.role.includes('sales')) {
          navigate('/public-profile');
        }
        setTimeout(() => {
          checkFirebaseSession();
        }, 2000);
      } else {
        navigate('/send-magic-link');
      }

      if (user.role.includes('sales')) {
        setNavigation([
          {
            name: 'Public Profile',
            href: '/public-profile',
            icon: HomeIcon,
            current: currentRoute === '/public-profile'
          },
          {
            name: 'Edit Profile',
            href: '/manage-profile',
            icon: UserCircleIcon,
            current: currentRoute === '/manage-profile'
          },
          {
            name: 'Photo Gallery',
            href: '/photo-gallery',
            icon: PhotographIcon,
            current: currentRoute === '/photo-gallery'
          }
          /* {
          name: 'Training',
          href: '/training',
          icon: AcademicCapIcon,
          current: currentRoute === '/training'
        } */
        ]);
      } else {
        setNavigation([
          { name: 'Home', href: '/', icon: HomeIcon, current: currentRoute === '/' },
          {
            name: 'Vehicle',
            href: '/vehicle',
            icon: TruckIcon,
            current: currentRoute === '/vehicle'
          },
          {
            name: 'Service',
            href: '/service',
            icon: CogIcon,
            current: currentRoute.indexOf('/service') > -1
          },
          {
            name: 'Friends & Family',
            href: '/friends-and-family',
            icon: GiftIcon,
            current: currentRoute === '/friends-and-family'
          },
          {
            name: 'Parts and Accessories',
            href: '/parts-and-accessories',
            icon: ShoppingCartIcon,
            current: currentRoute === '/parts-and-accessories'
          },

          { name: 'Videos', href: '/videos', icon: FilmIcon, current: currentRoute === '/videos' },
          // {
          //   name: 'Photos',
          //   href: '/photos',
          //   icon: PhotographIcon,
          //   current: currentRoute === '/photos'
          // },
          {
            name: 'Contacts',
            href: '/contacts',
            icon: UsersIcon,
            current: currentRoute.indexOf('/contact') > -1
          }
        ]);
        if (rooftop?.passkit) {
          setNavigation((prev) =>
            insertAtPosition(
              prev,
              {
                name: 'Wallet',
                href: '/wallet',
                customIcon: appImage.Wallet,
                current: currentRoute.indexOf('/wallet') > -1
              },
              6
            )
          );
        }

        if (rooftop?.dignify) {
          setNavigation((prev) =>
            insertAtPosition(
              prev,
              {
                name: 'Financing',
                href: '/financing',
                customIcon: appImage.FinancingIcon,
                current: currentRoute.indexOf('/financing') > -1
              },
              3
            )
          );
        }
      }
    }
    myApi();
  }, [user]);

  const userProfile = () => {
    if (user.role.includes('sales')) {
      navigate('/manage-profile');
    } else {
      gaEventCall('Avatar', '2');
      navigate('/profile');
    }
  };

  const userNavigation = [
    {
      name: 'Your Profile',
      onClick: () => userProfile()
    },
    { name: 'Sign out', onClick: () => userLogout() }
  ];

  return (
    <>
      <div className="min-h-full sticky top-0 z-10">
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-white shadow-md lg:static lg:overflow-y-visible'
            )
          }>
          {({ open }) => (
            <>
              <div className="bg-white max-w-12xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                  {/* brand logo */}
                  <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                    {user.role.includes('sales') ? (
                      <div className="flex-shrink-0 flex items-center">
                        <Link to="/">
                          <img
                            className="block h-8 w-auto"
                            src="https://firebasestorage.googleapis.com/v0/b/vinsyt-prod.appspot.com/o/app%2Fvinsyt-logo.svg?alt=media&token=5b3982bf-7b72-495b-bf8a-9df44fcb193e"
                            alt={rooftop?.name}
                          />
                        </Link>
                      </div>
                    ) : (
                      <div className="flex-shrink-0 flex flex-row items-center">
                        <Link to="/">
                          <img
                            className="block h-10 w-auto"
                            src={rooftop?.logo}
                            alt={rooftop?.name}
                          />
                        </Link>
                        <span className="hidden md:flex ml-3 border-r-2 h-7 border-gray-500 font-light" />
                        <span className="hidden md:inline-block ml-2 text-gray-500">
                          {rooftop?.name}
                        </span>
                      </div>
                    )}
                  </div>
                  {/* search bar - currently invisible */}
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 invisible">
                    <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                      <div className="w-full">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <input
                            id="search"
                            name="search"
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                            placeholder="Search"
                            type="search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* mobile hamburger menu button */}
                  <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                    <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Popover.Button>
                  </div>
                  {/* secondary menu */}
                  <div
                    className={`hidden lg:flex lg:items-center lg:justify-end ${
                      user.role.includes('sales') ? 'xl:col-span-3' : 'xl:col-span-4'
                    }`}>
                    {/* notification bell button */}
                    {/* {user.id && (
                      <Link
                        to="/"
                        className="ml-5 flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </Link>
                    )} */}
                    {/* user menu menu button */}
                    {user.id && (
                      <Menu as="div" className="flex-shrink-0 relative">
                        <div>
                          <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.avatar} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={`${item.name}${Math.random()}`}>
                                {({ active }) => (
                                  <p
                                    {...item}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'cursor-pointer block py-2 px-4 text-sm text-gray-700'
                                    )}>
                                    {item.name}
                                  </p>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                    {/* major action button */}
                    {/* <Link
                      to="/"
                      className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                      Share
                    </Link> */}
                  </div>
                </div>
              </div>
              {/* nav menu mobile - global */}
              <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                {({ close }) => {
                  closeDrawerRef = close;
                  return (
                    <>
                      <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                        {navigation.map((item) => (
                          <Link
                            key={`${item.name}${Math.random()}`}
                            to={item.href}
                            aria-current={item.current ? 'page' : undefined}
                            className={classNames(
                              item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                              'block rounded-md py-2 px-3 text-base font-medium'
                            )}>
                            {item.name}
                          </Link>
                        ))}
                      </div>
                      {/* nav menu - secondary */}
                      {user.id && (
                        <div className="border-t border-gray-200 pt-4">
                          <div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                            <div className="flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={user.avatar} alt="" />
                            </div>
                            <div className="ml-3">
                              <div className="text-base font-medium text-gray-800">
                                {user.name.first} {user.name.last}
                              </div>
                              <div className="text-sm font-medium text-gray-500">
                                {user.email.primary}
                              </div>
                            </div>
                            <button
                              type="button"
                              className="ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                              <span className="sr-only">View notifications</span>
                              <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                          <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                            {userNavigation.map((item) => (
                              <p
                                key={`${item.name}${Math.random()}`}
                                {...item}
                                className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900">
                                {item.name}
                              </p>
                            ))}
                          </div>
                        </div>
                      )}
                      {/* nav menu mobile - secondary actions */}
                      <div className="mt-6 max-w-3xl mx-auto px-4 sm:px-6">
                        <Link
                          to="/"
                          className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-rose-600 hover:bg-rose-700">
                          Share with Friends and Family
                        </Link>
                      </div>
                    </>
                  );
                }}
              </Popover.Panel>
            </>
          )}
        </Popover>
      </div>
      {/* nav menu desktop - global */}
      <div className="w-full h-[100vh] flex flex-col justify-between">
        <div className="py-4 md:py-6 lg:py-8 xl:py-10">
          <div className="max-w-[1298px] mx-auto px-3 md:px-6 lg:px-8 lg:grid lg:grid-cols-12 md:gap-6 lg:gap-6 xl:gap-8">
            <div className="hidden lg:block lg:col-span-2 xl:col-span-2">
              <nav aria-label="Sidebar" className="sticky top-24 divide-y divide-gray-300">
                {/* nav menu - global - first section */}
                <div className="pb-8 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      key={`${item.name}${Math.random()}`}
                      to={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-200 text-gray-900' : 'text-black hover:bg-gray-50',
                        'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                      )}
                      aria-current={item.current ? 'page' : undefined}>
                      {item.customIcon ? (
                        <img
                          className={classNames(
                            item.current ? 'text-black' : 'text-black group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          alt={item.customIcon}
                          src={item.customIcon}
                        />
                      ) : (
                        <item.icon
                          className={classNames(
                            item.current ? 'text-black' : 'text-black group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                      )}
                      <span className="truncate">{item.name}</span>
                    </Link>
                  ))}
                </div>
              </nav>
            </div>
            <Outlet />
          </div>
        </div>
        <div className="mt-10 border-t-2 border-grey-900 bg-gray-100 pl-5 pt-2 pb-2">
          <p
            className="cursor-pointer inline-block rounded-md py-2 px-3 text-base font-normal text-gray-500 hover:text-gray-900"
            onClick={() => setReportModalOpen(true)}>
            Report a problem
          </p>
          <a
            className="float-right cursor-pointer inline-block rounded-md py-2 px-7 text-base font-normal text-gray-500 hover:text-gray-900"
            target="_blank"
            href="https://www.vinsyt.com/privacy-policy"
            rel="noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
      <ReportModal open={reportModalOpen} onClose={(value) => setReportModalOpen(value)} />
    </>
  );
};
export default withVehicleContent(withLoader(withUser(Layout)));
