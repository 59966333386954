import React from 'react';

const FinancePendingBanner = () => (
  <>
    <div className="rounded-lg bg-[#FAFAFA] px-4 py-5 mx-4 md:mx-0 md:px-14 md:py-10 mt-5 md:mt-8 shadow flex flex-row justify-center items-start gap-2 mb-8">
      <p className="text-lg md:text-[22px] max-w-2xl text-center">
        Your application is being evaluated by DigniFi and a status will be returned here as soon as
        that process completes.
      </p>
    </div>
  </>
);

export default FinancePendingBanner;
