import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WalletTabs from '../components/WalletTabs';
import appImage from '../assets/image';
import { callFBFunction } from '../utils/firebase';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import { eventReactGTMModule } from '../utils/analytics';

const Wallet = ({ setLoader, user }) => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoader(true);
    callFBFunction('getPasskitUrl').then(({ data: response }) => {
      if (response.status) {
        setLoader(false);
        const tagManagerArgs = {
          event: 'e_wallet_download',
          area: 'wallet',
          passType: 'loyalty'
        };
        eventReactGTMModule(tagManagerArgs);
        window.location.href = response.data;
      }
    });
  };

  useEffect(() => {
    async function onInit() {
      if (!user?.rooftopData?.passkit) {
        navigate('/');
      }
    }
    onInit();
  }, []);

  return (
    <>
      <main className="lg:col-span-9 xl:col-span-9">
        <div className="rounded-lg bg-white px-4 py-8 md:px-14 md:py-10 shadow ">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 md:col-span-6 flex flex-col justify-between my-2">
              <div>
                <h2 className="text-2xl md:text-4xl font-bold mb-3">Your Personalized Website</h2>
                <h3 className="text-lg font-light mb-12">RIGHT IN YOUR WALLET</h3>
                <button
                  className="flex items-center justify-center bg-[#5046E4] text-[14px] text-white px-[16px] py-[9px] rounded-md inline-flex"
                  type="button"
                  onClick={handleSubmit}>
                  Download to your Digital Wallet
                </button>
                <p className="text-sm mt-6 mb-10">
                  {user.rooftopData.name} provides this digital membership card as a fast and
                  convenient way to get back to your personalized vehicle website while you’re on
                  the go.
                </p>
              </div>
              <div className="flex flex-row justify-center md:justify-start gap-8 mb-4">
                {window.navigator.userAgent.match(/iPhone/i) && (
                  <img className="w-auto h-10" src={appImage.AppleWallet} alt="apple wallet" />
                )}
                {window.navigator.userAgent.match(/Android/i) && (
                  <img className="w-auto h-10" src={appImage.GoogleWallet} alt="apple wallet" />
                )}
                {!window.navigator.userAgent.match(/android|iphone|kindle|ipad/i) && (
                  <>
                    <img className="w-auto h-10" src={appImage.AppleWallet} alt="apple wallet" />
                    <img className="w-auto h-10" src={appImage.GoogleWallet} alt="apple wallet" />
                  </>
                )}
              </div>
              <div />
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col justify-end">
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                className="w-full h-auto px-12"
                src={appImage.BannerCardWallet}
                alt="banner mockup image"
              />
            </div>
          </div>
          <div className="border-b border-black/10 w-full mb-10" />
          <p className="text-base font-bold mb-4">How to get your Membership Card?</p>
          <WalletTabs onClick={handleSubmit} />
        </div>
      </main>
    </>
  );
};

export default withLoader(withUser(Wallet));
