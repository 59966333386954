import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Cropper from 'react-easy-crop';
import _ from 'lodash';
import withUser from '../redux/Hoc/withUser';
import { getCroppedImg } from './CanvasUtils';
import { uploadFile, updateProfile, getProfileById } from '../utils/firebase';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import { STORAGE_PATH } from '../constants/index';

const ProfileCropper = ({
  user,
  setUser,
  setLoader,
  setNotify,
  image,
  crop,
  imageData,
  onMakeAvatar,
  onCropperBack
}) => {
  const [zoom, setZoom] = useState(1);
  const [aspect] = useState(1);
  const [cropSize, setCropSize] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (cropSizeData) => {
    setCropSize(cropSizeData);
  };

  const onCropComplete = (croppedArea, croppedAreaPixelsData) => {
    setCroppedAreaPixels(croppedAreaPixelsData);
  };

  const onZoomChange = (zoomData) => {
    setZoom(zoomData);
  };

  const showCroppedImage = async () => {
    try {
      setLoader(true);
      let croppedImageData = '';
      if (crop) {
        croppedImageData = await getCroppedImg(image, croppedAreaPixels);
      }
      const avatarImageData = crop ? croppedImageData : imageData;
      if (_.isEmpty(avatarImageData.name)) {
        setNotify({ type: 'error', message: 'Please select avatar.' });
      } else {
        await uploadFile(STORAGE_PATH.PROFILE, avatarImageData)
          .then(async (avatar) => {
            const profileRes = await getProfileById(user.id);
            profileRes.avatar = avatar;
            delete profileRes.id;
            const response = await updateProfile(user.id, profileRes);

            if (response) {
              setUser({ ...user, avatar });
              setLoader(false);
              setNotify({ type: 'success', message: 'Avatar uploaded successfully.' });
            } else {
              setLoader(false);
              setNotify({ type: 'error', message: 'Avatar upload failed! Please try again.' });
            }
          })
          .catch(() => {
            setLoader(false);
            setNotify({ type: 'error', message: 'Avatar upload failed! Please try again.' });
          });
        onMakeAvatar();
      }
    } catch (e) {
      setLoader(false);
      setNotify({ type: 'error', message: 'Avatar upload failed! Please try again.' });
    }
  };
  return (
    <>
      <Transition.Root show as={Fragment} onClose={() => false}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-96 sm:w-full sm:p-6">
                <div className="mt-20 h-64">
                  <Cropper
                    image={image}
                    crop={cropSize}
                    zoom={zoom}
                    aspect={aspect}
                    cropShape="round"
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                  />
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium border-indigo-600 text-indigo-600">
                      Align your mug
                    </Dialog.Title>
                    <div className="mx-px mt-px px-3 pt-2 text-sm leading-5 text-gray-500">
                      Then share your avatar with the world
                    </div>
                  </div>
                  <div className="justify-center text-center items-center justify-end p-4 rounded-b-md">
                    <button
                      onClick={onCropperBack}
                      type="button"
                      className="px-6 py-2.5 text-gray-500 rounded shadow-md">
                      Back
                    </button>
                    <button
                      onClick={showCroppedImage}
                      type="button"
                      className="px-6 py-2.5 bg-indigo-600 text-white rounded shadow-md ml-2">
                      Make Avatar
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default withUser(withNotify(withLoader(ProfileCropper)));
