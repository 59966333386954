import React from 'react';
import withNotify from '../../redux/Hoc/withNotify';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import CreateForm from '../../components/User/CreateForm';

const CreateUser = () => (
  <main className="lg:col-span-9 xl:col-span-6">
    <div className="py-7 px-3 bg-gray-100 h-screen">
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Create a Vinsyt</h3>
      </div>
      <div className="mt-3 md:mt-8 sm:mx-auto sm:w-full md:max-w-2xl">
        <div className="bg-white pt-1 pb-1 px-2 shadow sm:rounded-lg md:px-6 max-w-6xl">
          <CreateForm />
        </div>
      </div>
    </div>
  </main>
);

export default withNotify(withUser(withLoader(CreateUser)));
