import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initDB } from 'react-indexed-db';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import AppRoutes from './AppRoutes';
import { initAnalytics } from './utils/analytics';
import { initFirebase } from './utils/firebase';
import { DBConfig } from './helpers/app';

import 'react-toastify/dist/ReactToastify.css';
import 'react-activity/dist/library.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/style.css';

let ErrorBoundary = Fragment;

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: 'f3f1ee2c2733150cc5f25c8460c5df63',
    plugins: [new BugsnagPluginReact()]
  });
  BugsnagPerformance.start({ apiKey: 'f3f1ee2c2733150cc5f25c8460c5df63' });
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}
initDB(DBConfig);
initAnalytics();
initFirebase();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </ErrorBoundary>
);
