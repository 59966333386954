import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import VehicleIdentity from '../components/Vehicle/VehicleIdentity';
import Specifications from '../components/Vehicle/Specifications';
import DownloadDocs from '../components/Vehicle/DownloadDocs';
import { getVehiclesByUserId } from '../utils/firebase';
import withUser from '../redux/Hoc/withUser';
import withLoader from '../redux/Hoc/withLoader';
import withVehicleContent from '../redux/Hoc/withVehicleContent';
import SliderImages from '../components/Vehicle/SliderImages';
import { makeVehicleFeatures } from '../helpers/app';

const Vehicle = ({ user, setLoader, vehicleContent }) => {
  const [vehicle, setVehicle] = useState(null);
  const [docs, setDocs] = useState(null);

  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const vehicles = await getVehiclesByUserId(user.id);
      if (vehicles.length > 0) {
        const updatedVehicle = await makeVehicleFeatures(vehicles[0]);
        setVehicle(updatedVehicle);
      }
      setLoader(false);
    }
    onInit();
  }, [user]);

  useEffect(() => {
    const vehicleContentDocs = _.uniqWith(
      vehicleContent.docs,
      (vehicle1, vehicle2) =>
        vehicle1.source === vehicle2.source && vehicle1.title === vehicle2.title
    );
    setDocs(vehicleContentDocs);
  }, []);

  return !vehicle ? (
    <>
      <main className="lg:col-span-9 xl:col-span-6">
        <p className="mt-20 text-center">No Vehicle Details Found.</p>
      </main>
    </>
  ) : (
    <>
      <main className="md:col-span-6 xl:col-span-6">
        <div className="lg:hidden mb-4">{!_.isEmpty(docs) && <DownloadDocs data={docs} />}</div>
        <div className="p-10 bg-white shadow overflow-hidden rounded-lg mb-4 md:0">
          <VehicleIdentity data={vehicle} />
          <Specifications data={vehicle} />
        </div>
      </main>
      {vehicleContent && (
        <aside className="col-span-4">
          <div className="sticky top-24 space-y-5 vehicle-slider">
            <SliderImages data={vehicleContent.images} />
            <div className="hidden lg:block">
              {!_.isEmpty(docs) && <DownloadDocs data={docs} />}
            </div>
          </div>
        </aside>
      )}
    </>
  );
};

export default withVehicleContent(withUser(withLoader(Vehicle)));
