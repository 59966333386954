import React from 'react';
import Profile from '../components/Sales/Profile';
import TellCustomersAboutYou from '../components/Sales/TellCustomersAboutYou';

const SalesProfile = () => (
  <>
    <main className="lg:col-span-12 xl:col-span-9">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <Profile />
        <TellCustomersAboutYou />
        <div className="mb-36" />
      </div>
    </main>
  </>
);

export default SalesProfile;
