import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import _ from 'lodash';
import { getConfig, getLocalHost } from '../config';
import { syntaxUppercase, wordUppercase } from '../helpers/app';

import { FIREBASE_DOCS } from '../constants';

let app = null;
let functions = null;
let db = null;
let auth = null;
let storage = null;

export const initFirebase = () => {
  const config = getConfig();
  app = initializeApp(config.firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
  functions = getFunctions(app);
  storage = getStorage(app);
  if (!getLocalHost()) getPerformance(app);
  // When want to connect with local function.
  // connectFunctionsEmulator(functions, 'localhost', 5001);
};

export const callFBFunction = async (functionName, data) => {
  const functionInstance = httpsCallable(functions, functionName);
  return functionInstance(data);
};

export const getAllRoofTops = async () => {
  const response = [];
  const querySnapshot = await getDocs(collection(db, FIREBASE_DOCS.ROOFTOPS));
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      if (!item.data().disabled) {
        response.push({ id: item.id, ...item.data() });
      }
    });
  }
  return response;
};

export const getAllSalesByRooftop = async (rooftopId, role) => {
  const response = [];
  const myQuery = query(
    collection(db, FIREBASE_DOCS.STAFF),
    where('role', 'array-contains-any', role),
    where('rooftop', '==', rooftopId)
  );
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }
  return response;
};

export const doAuthLogin = (email, pass) => signInWithEmailAndPassword(auth, email, pass);
export const doAuthLogout = () => signOut(auth);
export const getCurrentUser = () => getAuth(app).currentUser;
export const getUserById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.USERS, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getProfileById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.PROFILES, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getAccountById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.ACCOUNTS, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getVehiclesByUserId = async (userId) => {
  const response = [];
  const myQuery = query(
    collection(db, FIREBASE_DOCS.VEHICLES),
    where('user', '==', userId),
    orderBy('created_on', 'desc')
  );
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }
  return response;
};

export const getVehicleContentById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.VEHICLE_CONTENT, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getStaffById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.STAFF, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getTestimonialByUserId = async (id, type = 'owner') => {
  const response = [];
  const myQuery = await query(collection(db, FIREBASE_DOCS.TESTIMONIAL), where(type, '==', id));
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }

  if (type === 'owner') {
    return response[0];
  }

  return response;
};

export const getRoofTopById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.ROOFTOPS, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getGroupById = async (id) => {
  const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.GROUPS, id));
  return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
};

export const getAllReviewsByStaff = async (staffId) => {
  const response = [];
  const myQuery = query(
    collection(db, `${FIREBASE_DOCS.STAFF}/${staffId}/${FIREBASE_DOCS.REVIEWS}`),
    orderBy('created_on', 'desc')
  );
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }
  return response;
};

export const getUserFeedsByUserId = async (userId) => {
  const response = [];
  const myQuery = query(collection(db, FIREBASE_DOCS.USER_FEED), where('user', '==', userId));
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }
  return response;
};

export const updateUser = async (data, id) => {
  try {
    await setDoc(doc(db, FIREBASE_DOCS.USERS, id), data);
    return true;
  } catch (e) {
    return false;
  }
};

// export const uploadFile = (path, fileName, fileData) =>
//   new Promise((resolve, reject) => {
//     const storageRef = ref(storage, `${path}${fileName}`);
//     const uploadTask = uploadBytesResumable(storageRef, fileData);

//     uploadTask.on(
//       'state_changed',
//       () => {},
//       (error) => {
//         reject(error);
//       },
//       () => {
//         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//           resolve(downloadURL);
//         });
//       }
//     );
//   });

export const uploadFile = (path, fileData) =>
  new Promise((resolve, reject) => {
    const contentType = fileData.type;
    const fileExtention = fileData.name.split('.').pop();

    try {
      callFBFunction('generateSignedURL', { path, contentType, fileExtention }).then(
        async (res) => {
          const { url, destination } = res.data.data;
          const upload = await fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': contentType // Set the content type of the file
            },
            body: fileData
          });
          if (upload.ok) {
            callFBFunction('generateDownloadURL', { path: destination }).then((downloadURL) => {
              resolve(downloadURL.data.data);
            });
          }
        }
      );
    } catch (error) {
      reject(error);
    }
  });

export const updateProfile = async (id, data) => {
  try {
    await setDoc(doc(db, FIREBASE_DOCS.PROFILES, id), data);
    return true;
  } catch (e) {
    return false;
  }
};

export const getStaffByUserId = async (userId) => {
  const response = [];
  const myQuery = query(collection(db, FIREBASE_DOCS.STAFF), where('user', '==', userId));
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }
  return response.length ? response[0] : {};
};

export const updateStaff = async (data, id) => {
  try {
    await setDoc(doc(db, FIREBASE_DOCS.STAFF, id), data);
    return true;
  } catch (e) {
    return false;
  }
};

export const updateAccount = async (id, data) => {
  try {
    await setDoc(doc(db, FIREBASE_DOCS.ACCOUNTS, id), data);
    return true;
  } catch (e) {
    return false;
  }
};

export const deleteFile = (path) =>
  new Promise((resolve, reject) => {
    const desertRef = ref(storage, path);
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUserFeedByUserIdAndFeedType = async (data) => {
  try {
    const response = [];
    const myQuery = query(
      collection(db, FIREBASE_DOCS.USER_FEED),
      where('user', '==', data.userId),
      where('key', '==', data.feedType)
    );
    const querySnapshot = await getDocs(myQuery);
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((item) => {
        response.push({ id: item.id, ...item.data() });
      });
    }
    return response.length ? response[0] : {};
  } catch (e) {
    return false;
  }
};

export const getVehicleContentByMakeModelYear = async (make, model, year) => {
  try {
    const uppercaseRes = [];
    const lowercaseRes = [];
    const wordUppercaseRes = [];
    const syntaxRes = [];

    // Uppercase
    const uppercaseQuery = query(
      collection(db, FIREBASE_DOCS.VEHICLE_CONTENT),
      where('make', '==', make),
      where(`models.${model.toUpperCase()}`, '==', true),
      where(`years.${year}`, '==', true)
    );
    const uppercaseQuerySnapshot = await getDocs(uppercaseQuery);
    if (uppercaseQuerySnapshot.size > 0) {
      uppercaseQuerySnapshot.forEach((item) => {
        uppercaseRes.push({ id: item.id, ...item.data() });
      });
    }

    // Lowercase
    const lowercaseQuery = query(
      collection(db, FIREBASE_DOCS.VEHICLE_CONTENT),
      where('make', '==', make),
      where(`models.${model.toLowerCase()}`, '==', true),
      where(`years.${year}`, '==', true)
    );
    const lowercaseQuerySnapshot = await getDocs(lowercaseQuery);
    if (lowercaseQuerySnapshot.size > 0) {
      lowercaseQuerySnapshot.forEach((item) => {
        lowercaseRes.push({ id: item.id, ...item.data() });
      });
    }

    // Word uppercase
    const wordUppercaseQuery = query(
      collection(db, FIREBASE_DOCS.VEHICLE_CONTENT),
      where('make', '==', make),
      where(`models.${wordUppercase(model)}`, '==', true),
      where(`years.${year}`, '==', true)
    );
    const wordUppercaseQuerySnapshot = await getDocs(wordUppercaseQuery);

    if (wordUppercaseQuerySnapshot.size > 0) {
      wordUppercaseQuerySnapshot.forEach((item) => {
        wordUppercaseRes.push({ id: item.id, ...item.data() });
      });
    }

    // syntax uppercase
    const syntaxUppercaseQuery = query(
      collection(db, FIREBASE_DOCS.VEHICLE_CONTENT),
      where('make', '==', make),
      where(`models.${syntaxUppercase(model)}`, '==', true),
      where(`years.${year}`, '==', true)
    );
    const syntaxUppercaseQuerySnapshot = await getDocs(syntaxUppercaseQuery);

    if (syntaxUppercaseQuerySnapshot.size > 0) {
      syntaxUppercaseQuerySnapshot.forEach((item) => {
        syntaxRes.push({ id: item.id, ...item.data() });
      });
    }

    const finalVehicle = uppercaseRes.concat(lowercaseRes, wordUppercaseRes, syntaxRes);
    return _.uniqBy(finalVehicle, 'id');
  } catch (e) {
    return false;
  }
};

export const getReferById = async (id) => {
  try {
    const querySnapshot = await getDoc(doc(db, FIREBASE_DOCS.REFERRALS, id));
    return querySnapshot.exists() ? { id: querySnapshot.id, ...querySnapshot.data() } : {};
  } catch (e) {
    return false;
  }
};

export const checkDuplicateUserEmail = async (email, userId) => {
  try {
    const user = {};
    const userQuery = query(
      collection(db, FIREBASE_DOCS.USERS),
      where('email.primary', '==', email)
    );
    const userQuerySnapshot = await getDocs(userQuery);
    if (userQuerySnapshot.size > 0) {
      userQuerySnapshot.forEach((item) => {
        if (item.id !== userId) {
          Object.assign(user, { id: item.id, ...item.data() });
        }
      });
    }
    return !_.isEmpty(user);
  } catch (e) {
    return false;
  }
};

export const getUnsubscribeByEmail = async (email) => {
  try {
    const data = {};
    const userQuery = query(collection(db, FIREBASE_DOCS.UNSUBSCRIBE), where('email', '==', email));
    const querySnapshot = await getDocs(userQuery);
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((item) => {
        Object.assign(data, { id: item.id, ...item.data() });
      });
    }
    return !_.isEmpty(data);
  } catch (e) {
    return false;
  }
};

export const getUnsubscribeByPhone = async (phone) => {
  try {
    const data = {};
    const userQuery = query(collection(db, FIREBASE_DOCS.UNSUBSCRIBE), where('phone', '==', phone));
    const querySnapshot = await getDocs(userQuery);
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((item) => {
        Object.assign(data, { id: item.id, ...item.data() });
      });
    }
    return !_.isEmpty(data);
  } catch (e) {
    return false;
  }
};

export const getReferralByUserId = async (userId) => {
  const response = [];
  const myQuery = query(
    collection(db, FIREBASE_DOCS.REFERRALS),
    where('user', '==', userId),
    orderBy('created_on', 'desc')
  );
  const querySnapshot = await getDocs(myQuery);
  if (querySnapshot.size > 0) {
    querySnapshot.forEach((item) => {
      response.push({ id: item.id, ...item.data() });
    });
  }
  return response;
};
