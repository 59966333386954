import React, { useEffect, useState } from 'react';
import _, { isEmpty } from 'lodash';
import withUser from '../redux/Hoc/withUser';
import withLoader from '../redux/Hoc/withLoader';
import withVehicleContent from '../redux/Hoc/withVehicleContent';
import VideoPlayerModal from '../modals/VideoPlayer';
import {
  eventAnalytics,
  eventReactGTMModule,
  eventReactGTMModuleReferral
} from '../utils/analytics';
import { ACTION, CATEGORY } from '../constants';
import appImage from '../assets/image';
import withReferMenu from '../redux/Hoc/withReferMenu';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Videos = ({ user, setLoader, vehicleContent, referMenu = false }) => {
  const [vehicleContentData, setVehicleContent] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [videRef, setVideoRef] = useState({});
  const [open, setOpen] = useState(false);
  const referral = !isEmpty(referMenu) ? referMenu.find((o) => o.referral) : false;

  useEffect(() => {
    async function onInit() {
      setLoader(true);
      if (vehicleContent.videos.length > 0) {
        const vehicleContentVideos = _.uniqWith(
          vehicleContent.videos,
          (vehicle1, vehicle2) => vehicle1.source === vehicle2.source
        );

        const vehiclesData = {};
        vehicleContentVideos.map((video) => {
          if (!(video.categoryTitle in vehiclesData)) {
            vehiclesData[video.categoryTitle] = [video];
          } else {
            vehiclesData[video.categoryTitle].push(video);
          }
        });
        setVehicleContent(vehiclesData);
        setSelectedTab(Object.keys(vehiclesData)[0]);
      }
      setLoader(false);
    }
    onInit();
  }, [user]);

  const onChangeTab = (tab) => {
    setSelectedTab(tab);
  };
  const onOpenVideoPopupModal = (video) => {
    if (referral) {
      const tagManagerArgs = {
        event: 'e_video_start',
        video_title: video.title,
        video_category: video.category,
        video_url: video.source,
        area: 'video'
      };
      eventReactGTMModuleReferral(tagManagerArgs);
    } else {
      eventAnalytics({
        category: CATEGORY.ASSET,
        action: ACTION.VIDEO_PLAY,
        label: video.title,
        nonInteraction: false
      });
      const tagManagerArgs = {
        event: 'e_video_start',
        video_title: video.title,
        video_category: video.category,
        video_url: video.source,
        area: 'video'
      };
      eventReactGTMModule(tagManagerArgs);
    }
    setOpen(true);
    setVideoRef(video);
  };

  return !vehicleContent ? (
    <>
      <main className="lg:col-span-12 xl:col-span-6">
        <p className="mt-20 text-center">No Vehicle Videos Found.</p>
      </main>
    </>
  ) : (
    <>
      <main className="lg:col-span-10 xl:col-span-10">
        <h2 className="ml-8 text-2xl leading-6 font-medium text-[#333333]">Videos</h2>
        {vehicleContentData !== null && Object.keys(vehicleContentData).length > 0 ? (
          <div className="flex-1 flex items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              <div className="pt-2 max-w-12xl px-4 sm:px-6 lg:px-8">
                <div className="mt-3 sm:mt-2">
                  <div className="hidden sm:block">
                    <div className="flex items-center border-b border-gray-200">
                      <nav className="flex-1 -mb-px flex space-x-6 xl:space-x-8" aria-label="Tabs">
                        {Object.keys(vehicleContentData).map((tab) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            onClick={() => onChangeTab(tab)}
                            key={tab}
                            aria-current={tab === selectedTab ? 'page' : undefined}
                            className={classNames(
                              tab === selectedTab
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                              'cursor-pointer whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}>
                            {tab}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <p className="mt-4 text-[13px] font-medium text-gray-500">
                  * ATTENTION: Other years and/or models may be used in video to demonstrate
                  features, All features shown are not available on all models and may not be
                  equipped in your vehicle.
                </p>
                <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                  <h2 id="gallery-heading" className="sr-only">
                    Recently viewed
                  </h2>
                  <ul
                    role="list"
                    className="grid grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-4 md:gap-6 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-8">
                    {vehicleContentData[selectedTab] !== undefined &&
                      vehicleContentData[selectedTab].map((video, vIndex) => (
                        <li key={`video-${vIndex}`} className="relative">
                          <div
                            className="group bg-neutral-700 group block w-full aspect-w-19 aspect-h-9 rounded-lg focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden hover:scale-[1.1] ease-out duration-300 shadow-[0px_2px_4px_rgba(0,0,0,0.4)] hover:shadow-[0px_10px_12px_rgba(0,0,0,0.5)]"
                            onClick={() => onOpenVideoPopupModal(video)}>
                            <div className="mx-auto flex w-full h-24 md:h-28 xl:h-32 items-center justify-center cursor-pointer">
                              {!_.isEmpty(video.thumb) && video.is_verified_by_script !== 2 && (
                                <img
                                  src={video.thumb}
                                  alt=""
                                  className="h-full w-auto aspect-w-10 aspect-h-7"
                                  id="videoThumbnail"
                                  onError={({ currentTarget }) => {
                                    window.document.getElementById(
                                      `thumb-${vIndex}`
                                    ).style.display = 'none';
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = `${appImage.noVideoUrl}`;
                                  }}
                                />
                              )}
                              {!_.isEmpty(video.source) && (
                                <img
                                  id={`thumb-${vIndex}`}
                                  className="absolute top-auto left-auto w-20 h-auto hover:shadow-outline opacity-90 group-hover:opacity-100"
                                  src={appImage.thumbIcon}
                                  alt=""
                                />
                              )}
                            </div>
                          </div>
                          <p className="mt-2 block text-sm font-medium text-gray-900 pointer-events-none break-all">
                            {video.title}
                          </p>
                        </li>
                      ))}
                  </ul>
                </section>
              </div>
            </main>
            {open && (
              <VideoPlayerModal
                onContinue={() => setOpen(false)}
                open={open}
                video={videRef}
                referral={referral}
              />
            )}
          </div>
        ) : (
          <p className="mt-20 text-center">No Vehicle Videos Found.</p>
        )}
      </main>
    </>
  );
};

export default withReferMenu(withVehicleContent(withUser(withLoader(Videos))));
