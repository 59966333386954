import React from 'react';
import { Field, Form } from 'react-final-form';
import { maxLength, renderField } from '../../helpers/form-validations';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import withNotify from '../../redux/Hoc/withNotify';
import { getProfileById, updateProfile } from '../../utils/firebase';
import { eventReactGTMModuleSales } from '../../utils/analytics';

const TellCustomersAboutYou = ({ user, setLoader, setUser, setNotify }) => {
  const onSave = async (formData) => {
    setLoader(true);
    const highlightsData = [];
    const profileRes = await getProfileById(user.id);
    if (Object.prototype.hasOwnProperty.call(formData, 'bio')) {
      profileRes.bio = formData.bio;
    }

    highlightsData.push(
      Object.prototype.hasOwnProperty.call(formData, 'line1') ? formData.line1 : ''
    );

    highlightsData.push(
      Object.prototype.hasOwnProperty.call(formData, 'line2') ? formData.line2 : ''
    );

    highlightsData.push(
      Object.prototype.hasOwnProperty.call(formData, 'line3') ? formData.line3 : ''
    );

    highlightsData.push(
      Object.prototype.hasOwnProperty.call(formData, 'line4') ? formData.line4 : ''
    );

    highlightsData.push(
      Object.prototype.hasOwnProperty.call(formData, 'line5') ? formData.line5 : ''
    );

    profileRes.highlights = highlightsData;
    delete profileRes.id;
    const response = await updateProfile(user.id, profileRes);
    if (response) {
      const updatedData = { ...user, ...profileRes };
      setUser(updatedData);
      const tagManagerArgs = {
        event: 'e_agent_profile',
        agentID: user.staff,
        rooftop: user.rooftop,
        ownerID: user.id,
        dealerGroupID: user.rooftopData.group,
        experience: 'sa_private',
        profile_item: 'About'
      };
      eventReactGTMModuleSales(tagManagerArgs);
      setNotify({ message: 'Profile updated successfully.', type: 'success' });
    } else {
      setNotify({ message: 'Profile update failed! Please try again.', type: 'error' });
    }
    setLoader(false);
  };
  return (
    <>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="mt-14 mb-5 sm:px-6 font-medium text-[#333333] text-md">
            Tell customers about yourself
          </div>
          <>
            <Form
              onSubmit={onSave}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="contents">
                  <div className="h-64 border-t border-gray-200 flex py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="w-52 text-sm font-medium text-gray-500">
                      Biography
                      <p className="mt-5 text-xs font-medium text-gray-500">
                        Use 355 characters to describe yourself however you like
                      </p>
                    </dt>
                    <label
                      htmlFor="inline-bio"
                      className="col-span-2 text-sm font-medium text-gray-700">
                      Bio
                      <Field
                        className="h-48 w-2/3 resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md rows-[34]"
                        name="bio"
                        type="textarea"
                        defaultValue={user.bio}
                        validate={maxLength(355)}
                        id="bio">
                        {renderField}
                      </Field>
                    </label>
                  </div>
                  <div className="border-t border-gray-200 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="row-span-5 text-sm font-medium text-gray-500">
                      Highlights
                      <p className="mt-5 text-xs font-medium text-gray-500">
                        Use 78 characters to describe something about yourself. Suggestions are just
                        that.
                      </p>
                    </dt>
                    <label
                      htmlFor="inline-bio"
                      className="col-span-2 text-sm font-medium text-gray-700">
                      Line 1
                      <Field
                        className="w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        name="line1"
                        type="text"
                        defaultValue={user.highlights[0]}
                        validate={maxLength(78)}
                        id="line1">
                        {renderField}
                      </Field>
                    </label>
                    <label
                      htmlFor="inline-bio"
                      className="col-span-2 text-sm font-medium text-gray-700">
                      Line 2
                      <Field
                        className="w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        name="line2"
                        type="text"
                        defaultValue={user.highlights[1]}
                        validate={maxLength(78)}
                        id="line2">
                        {renderField}
                      </Field>
                    </label>
                    <label
                      htmlFor="inline-bio"
                      className="col-span-2 text-sm font-medium text-gray-700">
                      Line 3
                      <Field
                        className="w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        name="line3"
                        type="text"
                        defaultValue={user.highlights[2]}
                        validate={maxLength(78)}
                        id="line3">
                        {renderField}
                      </Field>
                    </label>
                    <label
                      htmlFor="inline-bio"
                      className="col-span-2 text-sm font-medium text-gray-700">
                      Line 4
                      <Field
                        className="w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        name="line4"
                        type="text"
                        defaultValue={user.highlights[3]}
                        validate={maxLength(78)}
                        id="line4">
                        {renderField}
                      </Field>
                    </label>
                    <label
                      htmlFor="inline-bio"
                      className="col-span-2 text-sm font-medium text-gray-700">
                      Line 5
                      <Field
                        className="w-2/3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        name="line5"
                        type="text"
                        defaultValue={user.highlights[4]}
                        validate={maxLength(78)}
                        id="line5">
                        {renderField}
                      </Field>
                    </label>
                  </div>
                  <div className="border-gray-200 py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="row-span-5 text-sm font-medium text-gray-500">
                      <p className="mt-5 text-xs font-medium text-gray-500" />
                    </dt>
                    <div className="col-span-2">
                      <button
                        type="submit"
                        className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </>
        </dl>
      </div>
    </>
  );
};

export default withNotify(withLoader(withUser(TellCustomersAboutYou)));
