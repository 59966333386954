import { connect } from 'react-redux';
import { referMenuSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setReferMenu: (data) => {
    dispatch(referMenuSet(data));
  }
});

const mapStateToProps = (state) => ({
  referMenu: state.referMenu ? state.referMenu : []
});

export default connect(mapStateToProps, mapDispatchToProps);
