import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ReactPlayer from 'react-player';
import { isEmpty } from 'lodash';
import { getYoutubeVideoId } from '../helpers/app';
import { eventReactGTMModule, eventReactGTMModuleReferral } from '../utils/analytics';

const VideoPlayer = ({ video, open, onContinue, referral }) => {
  const onVideoOpenNewTab = () => {
    if (referral) {
      const tagManagerArgs = {
        event: 'e_video_tab',
        video_title: video.title,
        video_category: video.category,
        video_url: video.source,
        area: 'video_modal'
      };
      eventReactGTMModuleReferral(tagManagerArgs);
    } else {
      const tagManagerArgs = {
        event: 'e_video_tab',
        video_title: video.title,
        video_category: video.category,
        video_url: video.source,
        area: 'video_modal'
      };
      eventReactGTMModule(tagManagerArgs);
    }
    window.open(video.source);
  };

  return (
    <Transition.Root show={open} as={Fragment} onClose={onContinue}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="relative sm:h-[530px] md:h-[750px] lg:h-[750px] xl:h-[750px] 2xl:h-[750px] inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-5xl sm:w-full sm:p-6">
              <p className="mb-5 text-lg font-medium text-[#333333] break-all">{video.title}</p>
              {!isEmpty(video) ? (
                video.source.indexOf('.html') > -1 ? (
                  <iframe
                    src={video.source.replace('http://', 'https://')}
                    title="video"
                    width="100%"
                    height="82%"
                  />
                ) : video.source.indexOf('youtube.com') > -1 ||
                  video.source.indexOf('youtu.be') > -1 ? (
                  <iframe
                    src={`https://youtube.com/embed/${getYoutubeVideoId(
                      video.source
                    )}?autoplay=1&mute=0&controls=1&origin=${encodeURI(
                      window.location.origin
                    )}&playsinline=1&showinfo=0&rel=0`}
                    title="video"
                    width="100%"
                    height="82%"
                  />
                ) : (
                  <ReactPlayer playing width="auto" height="82%" url={video.source} controls />
                )
              ) : (
                'Video URL not found'
              )}
              <div className="mt-5 text-center">
                <button
                  type="button"
                  className="text-white bg-[#5046E4] hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
                  onClick={onVideoOpenNewTab}>
                  Open Video In New Tab
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default VideoPlayer;
