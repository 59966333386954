import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import ContactCards from '../components/Contacts/ContactCards';
import { getAllSalesByRooftop, getStaffById } from '../utils/firebase';
import { eventAnalytics } from '../utils/analytics';
import { ACTION, CATEGORY, ROLE } from '../constants';

const Contacts = ({ setLoader, user }) => {
  const navigate = useNavigate();
  const [people, setPeople] = useState([]);
  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const roles = [ROLE.SALES, ROLE.SERVICE_ADVISOR, ROLE.SERVICE_MANAGER];
      const salesLists = await getAllSalesByRooftop(user.rooftop, roles);
      if (salesLists.length > 0) {
        const salesAgentData = salesLists.splice(
          salesLists.findIndex((sales) => sales.id === user.staff),
          1
        );
        salesLists.unshift(salesAgentData[0]);
        setPeople(salesLists);
      }
      setLoader(false);
    }
    onInit();
  }, []);

  const onSalesReview = async (index) => {
    const staffData = await getStaffById(user.staff);
    eventAnalytics({
      category: CATEGORY.CONTACT,
      action: ACTION.AGENT_PROFILE,
      label: `${staffData.name.first} ${staffData.name.last}`,
      nonInteraction: false
    });
    navigate(`/contact/${index}`, { replace: true });
  };

  return (
    <>
      <main className="md:col-span-9 xl:col-span-10">
        {people.length > 0 ? (
          <>
            <div className="mb-7">
              <h2 className="text-2xl leading-6 font-medium text-[#333333]">Contacts</h2>
            </div>
            <ul
              role="list"
              className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-6 lg:grid-cols-3">
              <ContactCards
                data={people}
                onSalesReview={onSalesReview}
                user={user}
                area="contacts"
                event="e_agent_contact"
              />
            </ul>
          </>
        ) : (
          <p className="mt-20 text-center">No Contacts Found.</p>
        )}
      </main>
    </>
  );
};

export default withUser(withLoader(Contacts));
