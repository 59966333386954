import thumbIcon from './video-thumb-icon.png';
import noVideoUrl from './no-video-url.png';
import scheduleService from './schedule-service.svg';
import latestIncentives from './latest-incentives.svg';
import getPreApproved from './get-pre-approved.svg';
import shopInventory from './shop-inventory.svg';
import VehicleInfo from './vehicle-info.svg';
import Service from './service.svg';
import HowToVideos from './how-to-videos.svg';
import PhotoGallery from './photo-gallery.svg';
import OwnerManuals from './owner-manuals.svg';
import Contacts from './contacts.svg';
import ExternalLink from './external-link.svg';
import ExternalLinkLight from './external-link-light.svg';
import Wallet from './wallet.svg';
import WalletWhite from './wallet-white.svg';
import BannerCardWallet from './banner-card-wallet.png';
import AppleWallet from './apple-wallet.svg';
import GoogleWallet from './google-wallet.svg';
import ResetIcon from './reset-icon.svg';
import Hourglass from './hourglass.svg';
import CheckmarkCircle from './checkmark-circle.svg';
import ExternalLinkWhite from './external-link-white.svg';
import CarRims from './car-rims.svg';
import CartIcon from './cart-icon.svg';
import CouponIcon from './coupon-icon.svg';
import TireIcon from './tire-icon.svg';
import RepairServiceWork from './repairs-and-service-work.svg';
import PreventiveMaintenance from './preventive-maintenance.svg';
import TireServices from './tire-services.svg';
import InsuranceDeductables from './insurance-deductibles.svg';
import BodyWork from './body-work.svg';
import RepairsMaintenancePackages from './maintenance-packages.svg';
import VehicleServiceContracts from './service-contracts.svg';
import FinanceAccessories from './finance-accessories.svg';
import DownPaymentAssistance from './down-payment-assiatance.svg';
import BlueCheckOutline from './blue-check-outline.svg';
import BlueCrossOutline from './blue-cross-outline.svg';
import FinancingIcon from './financing-icon.svg';
import DollarIcon from './dollar.svg';
import Calculator from './calculator.svg';

export default {
  thumbIcon,
  noVideoUrl,
  latestIncentives,
  getPreApproved,
  shopInventory,
  scheduleService,
  VehicleInfo,
  Service,
  HowToVideos,
  PhotoGallery,
  OwnerManuals,
  ExternalLink,
  Contacts,
  ExternalLinkLight,
  ExternalLinkWhite,
  Wallet,
  WalletWhite,
  BannerCardWallet,
  AppleWallet,
  GoogleWallet,
  ResetIcon,
  Hourglass,
  CheckmarkCircle,
  CarRims,
  CartIcon,
  CouponIcon,
  TireIcon,
  RepairServiceWork,
  PreventiveMaintenance,
  TireServices,
  InsuranceDeductables,
  BodyWork,
  RepairsMaintenancePackages,
  VehicleServiceContracts,
  FinanceAccessories,
  DownPaymentAssistance,
  BlueCheckOutline,
  BlueCrossOutline,
  FinancingIcon,
  DollarIcon,
  Calculator
};
