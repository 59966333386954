import stagingConfig from './config.staging';
import productionConfig from './config.production';

export const getConfig = () =>
  process.env.REACT_APP_ENV !== 'production' ? stagingConfig : productionConfig;

export const getEnviroment = () => process.env.REACT_APP_ENV;

export const getLocalHost = () =>
  location.hostname === 'localhost' || location.hostname === '127.0.0.1';
