import React, { useRef, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import formatString from 'format-string-by-pattern';
import _ from 'lodash';
import { OnFocus } from 'react-final-form-listeners';

import { renderField } from '../../helpers/form-validations';
import { callFBFunction } from '../../utils/firebase';
import withUser from '../../redux/Hoc/withUser';
import withNotify from '../../redux/Hoc/withNotify';
import withLoader from '../../redux/Hoc/withLoader';
import { eventAnalytics, eventReactGTMModule } from '../../utils/analytics';
import { ACTION, CATEGORY } from '../../constants';

const SendDiscountForm = ({ setOpen, setLoader, setNotify, user, setFormSubmitted }) => {
  const addRowRef = useRef(null);
  const [addRow, setAddRow] = useState(false);

  useEffect(() => {
    if (!addRow) {
      [...Array(10)].map(() => {
        addRowRef.current.click();
      });
      setAddRow(true);
    }
  }, [addRow]);

  const onSubmit = (formData, form) => {
    // Filter out empty rows
    const filteredUsers = formData.user.filter(
      (item) => (item.email || item.phone) && (item.first || item.last)
    );

    if (filteredUsers.length === 0) {
      setNotify({ message: 'Please fill out at least one user.', type: 'error' });
      return;
    }
    setLoader(true);
    callFBFunction('sendDiscounts', { data: filteredUsers }).then(async ({ data: response }) => {
      const { status, message } = response;
      setLoader(false);
      if (status) {
        setOpen();
        form.reset();
        setAddRow(false);
        setFormSubmitted(true);
        eventAnalytics({
          category: CATEGORY.REFERRAL,
          action: ACTION.REF_SEND,
          label: 'Send Discounts',
          nonInteraction: false
        });
        const tagManagerArgs = {
          event: 'e_share_discount',
          number_shares: filteredUsers.length
        };
        eventReactGTMModule(tagManagerArgs);
      } else {
        eventAnalytics({
          category: CATEGORY.REFERRAL,
          action: ACTION.REF_ERROR,
          label: message,
          nonInteraction: false
        });
        setNotify({ message, type: 'error' });
      }
    });
  };

  const validateRequired = (value, allValues, index) => {
    if (allValues && allValues.user) {
      const { first, email, phone } = allValues.user[index];
      if (!value && !first && (email || phone)) {
        return 'Required';
      }
    }
    return undefined;
  };

  const validateEmail = (value, allValues, index) => {
    if (allValues && allValues.user) {
      const { phone, first, last } = allValues.user[index];
      if (!value && (first || last) && !phone) {
        return 'Required';
      }
      if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        return 'Invalid email address';
      }
    }
    return undefined;
  };

  const validatePhone = (value, allValues, index) => {
    if (allValues && allValues.user) {
      const { email, first, last } = allValues.user[index];
      if (!value && (first || last) && !email) {
        return 'Required';
      }
      if (value && !/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
        return 'Invalid phone number';
      }
    }
    return undefined;
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      validate={(values) => {
        const errors = { user: [] };
        if (values.user) {
          values.user.map((item, iIndex) => {
            errors.user.push({ email: undefined, phone: undefined });
            if (item.email?.toLowerCase() === user.email?.primary?.toLowerCase()) {
              errors.user[iIndex].email = 'You can not invite your self.';
            }
            if (
              !_.isEmpty(user.phone.mobile) &&
              item.phone.replace(/([^\w]+|\s+)/g, '') ===
                user.phone.mobile.replace(/([^\w]+|\s+)/g, '')
            ) {
              errors.user[iIndex].phone = 'You can not invite your self.';
            }
          });
        }
        return errors;
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name="user">
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <div
                    key={`referral-keys-${index}`}
                    className="bg-white py-2 px-4 space-y-6 sm:px-6">
                    <div className="grid grid-cols-10 gap-4 md:gap-y-3">
                      <div className="col-span-12 md:col-span-4 lg:col-span-2">
                        <label
                          className={`${
                            index === 0 ? 'block' : 'lg:hidden'
                          } uppercase tracking-wide text-[#333333] text-xs font-bold mb-2`}
                          htmlFor="grid-city">
                          First <label className="text-pink-500">*</label>
                        </label>
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name={`${name}.first`}
                          id="grid-first"
                          type="text"
                          placeholder="Johnny"
                          validate={(value, allValues) =>
                            validateRequired(value, allValues, index)
                          }>
                          {renderField}
                        </Field>
                        <OnFocus name={`${name}.first`}>
                          {() => {
                            eventAnalytics({
                              category: CATEGORY.REFERRAL,
                              action: ACTION.REF_FORM_ACTIVITY,
                              label: 'First',
                              nonInteraction: false
                            });
                          }}
                        </OnFocus>
                      </div>

                      <div className="col-span-12 md:col-span-4 lg:col-span-2">
                        <label
                          className={`${
                            index === 0 ? 'block' : 'lg:hidden'
                          } uppercase tracking-wide text-[#333333] text-xs font-bold mb-2`}
                          htmlFor="grid-city">
                          Last <label className="text-pink-500">*</label>
                        </label>
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          name={`${name}.last`}
                          id="grid-last"
                          type="text"
                          placeholder="Appleseed"
                          validate={(value, allValues) =>
                            validateRequired(value, allValues, index)
                          }>
                          {renderField}
                        </Field>
                        <OnFocus name={`${name}.last`}>
                          {() => {
                            eventAnalytics({
                              category: CATEGORY.REFERRAL,
                              action: ACTION.REF_FORM_ACTIVITY,
                              label: 'Last',
                              nonInteraction: false
                            });
                          }}
                        </OnFocus>
                      </div>

                      <div className="col-span-12 md:col-span-4 lg:col-span-2">
                        <label
                          className={`${
                            index === 0 ? 'block' : 'lg:hidden'
                          } uppercase tracking-wide text-[#333333] text-xs font-bold mb-2`}
                          htmlFor="grid-email">
                          Email <label className="text-pink-500">*</label>
                        </label>
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-email"
                          name={`${name}.email`}
                          type="text"
                          placeholder="johnny@gmail.com"
                          validate={(value, allValues) => validateEmail(value, allValues, index)}>
                          {renderField}
                        </Field>
                        <OnFocus name={`${name}.email`}>
                          {() => {
                            eventAnalytics({
                              category: CATEGORY.REFERRAL,
                              action: ACTION.REF_FORM_ACTIVITY,
                              label: 'Email',
                              nonInteraction: false
                            });
                          }}
                        </OnFocus>
                      </div>

                      <div className="col-span-12 md:col-span-4 lg:col-span-2">
                        <label
                          className={`${
                            index === 0 ? 'block' : 'lg:hidden'
                          } uppercase tracking-wide text-[#333333] text-xs font-bold mb-2`}
                          htmlFor="grid-email">
                          Phone <label className="text-pink-500">*</label>
                        </label>
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-phone"
                          name={`${name}.phone`}
                          type="text"
                          parse={formatString('(555) 867-5309')}
                          placeholder="(555) 867-5309"
                          validate={(value, allValues) => validatePhone(value, allValues, index)}>
                          {renderField}
                        </Field>
                        <OnFocus name={`${name}.phone`}>
                          {() => {
                            eventAnalytics({
                              category: CATEGORY.REFERRAL,
                              action: ACTION.REF_FORM_ACTIVITY,
                              label: 'Phone',
                              nonInteraction: false
                            });
                          }}
                        </OnFocus>
                      </div>

                      <div className="col-span-12 md:col-span-4 lg:col-span-2">
                        <label
                          className={`${
                            index === 0 ? 'block' : 'lg:hidden'
                          } uppercase tracking-wide text-[#333333] text-xs font-bold mb-2`}
                          htmlFor="grid-email">
                          Relationship
                        </label>
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-relationship"
                          name={`${name}.relationship`}
                          type="text"
                          placeholder="Brother">
                          {renderField}
                        </Field>
                      </div>

                      <div className="col-span-12 md:col-span-10">
                        <hr />
                      </div>

                      {/* {fields.length > 1 && (
                        <div className="col-span-12 md:col-span-4 lg:col-span-2">
                          <button
                            type="button"
                            className="flex gap-2 cursor-pointer items-center uppercase tracking-wide text-[#FF0000] text-xs font-bold"
                            onClick={() => fields.remove(index)}>
                            Remove invite
                          </button>
                        </div>
                      )} */}
                    </div>
                  </div>
                ))}
                <div className="bg-white py-2 px-4 sm:px-6 flex flex-col md:flex-row gap-4 md:gap-8 justify-center md:justify-end md:items-center">
                  <span
                    ref={addRowRef}
                    className="cursor-pointer border-indigo-600 text-indigo-600"
                    onClick={() => {
                      eventAnalytics({
                        category: CATEGORY.REFERRAL,
                        action: ACTION.REF_ADD_ROWS,
                        nonInteraction: false
                      });
                      fields.push({ first: '', last: '', email: '', phone: '', relationship: '' });
                    }}>
                    Invite More
                  </span>
                  <button
                    type="submit"
                    className="text-white bg-[#5046E4] border border-[#5046E4] hover:bg-indigo-800 hover:border-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none transition-all">
                    Send Discounts
                  </button>
                </div>
              </>
            )}
          </FieldArray>
        </form>
      )}
    />
  );
};

export default withLoader(withNotify(withUser(SendDiscountForm)));
