import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
// import secureLocalStorage from 'react-secure-storage';
// import { isEmpty } from 'lodash';
import { useIndexedDB } from 'react-indexed-db';
import { composeValidators, renderField, required } from '../helpers/form-validations';
import { callFBFunction } from '../utils/firebase';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';

const SendMagicLink = ({ setLoader, setNotify }) => {
  const { getByID } = useIndexedDB('links');
  useEffect(() => {
    setLoader(false);
  }, []);

  const onSubmit = (formData, form) => {
    setLoader(true);
    callFBFunction('resendMagicLink', formData).then(({ data: response }) => {
      const { status, message } = response;
      setLoader(false);
      form.reset();
      setNotify({ message, type: status ? 'success' : 'error' });
    });
  };

  useEffect(() => {
    getByID(1).then((data) => {
      if (data && data.magicLink) {
        // window.location.href = data.magicLink;
      }
    });
  }, []);

  /* const fbPost = () => {
    callFBFunction('url').then(({ data: response }) => {
      console.log(response);
    });
  }; */
  return (
    <>
      <main className="lg:col-span-12 xl:col-span-10">
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-10 w-auto"
              src="https://firebasestorage.googleapis.com/v0/b/vinsyt-stage.appspot.com/o/app%2Fvinsyt-logo.svg?alt=media&token=e851e35e-f5e6-4ffc-ae47-30823d411288"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Send Magic Link
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form className="space-y-6" onSubmit={handleSubmit} action="#" method="POST">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <div className="mt-1">
                        <Field
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          name="email"
                          type="text"
                          id="inline-email"
                          placeholder="email@example.com"
                          validate={composeValidators(required)}>
                          {renderField}
                        </Field>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Send
                      </button>
                      {/* <button
                        onClick={() => fbPost()}
                        type="button"
                        className="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        FB
                      </button> */}
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withNotify(withLoader(SendMagicLink));
