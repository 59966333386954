import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';
import { FilmIcon, HomeIcon, MenuIcon, PhotographIcon, XIcon } from '@heroicons/react/outline';
import { classNames } from './helpers/app';
import { callFBFunction, getRoofTopById, getUserById, getVehiclesByUserId } from './utils/firebase';
import withReferMenu from './redux/Hoc/withReferMenu';
import withVehicleContent from './redux/Hoc/withVehicleContent';
import withLoader from './redux/Hoc/withLoader';
import { initReactGTMModule } from './utils/analytics';

let closeDrawerRef = () => {};

const menuIcons = {
  HomeIcon,
  PhotographIcon,
  FilmIcon
};

const ReferralLayout = ({ referMenu, setReferMenu, setVehicleContent, setLoader }) => {
  const location = useLocation();
  const { userId, referCode } = useParams();
  const initialState =
    referCode === undefined ? `/user/${userId}` : `/user/${userId}/refer/${referCode}`;
  const [currentRoute, setCurrentRoute] = useState(initialState);
  const [roofTop, setRooftop] = useState({});

  useEffect(() => {
    async function onInit() {
      const userRes = await getUserById(userId);
      const roofTopRes = await getRoofTopById(userRes.rooftop);
      if (userId) {
        const vehicleData = await getVehiclesByUserId(userId);
        const vehicle = [];
        vehicleData.map((value) => {
          vehicle.push({
            make: value.attributes.make,
            model: value.attributes.model,
            year: value.attributes.year
          });
        });
        if (vehicle.length > 0) {
          setLoader(true);
          const payload = {
            make: vehicle[0].make,
            model: vehicle[0].model,
            year: vehicle[0].year
          };
          callFBFunction('getVehicleContent', payload).then(({ data: response }) => {
            const { status } = response;
            if (status) {
              const { data } = response;
              setVehicleContent(data);
            }
            setLoader(false);
          });
        }
      }
      setRooftop(roofTopRes);
      const tagManagerArgs = {
        environment: process.env.REACT_APP_ENV,
        version: '2.O'
      };
      initReactGTMModule(tagManagerArgs);
    }
    onInit();
  }, []);

  useEffect(() => {
    setCurrentRoute(location.pathname);
    closeDrawerRef();
    if (!window.location.href.includes('photos') && !window.location.href.includes('videos')) {
      const currentRout =
        referCode === undefined ? `/user/${userId}` : `/user/${userId}/refer/${referCode}`;
      const referValue = [
        {
          href: currentRout,
          name: 'Home',
          icon: 'HomeIcon'
        },
        // {
        //   href: `/user/${userId}/photos`,
        //   name: 'Photos',
        //   icon: 'PhotographIcon'
        // },
        {
          href: `/user/${userId}/videos`,
          name: 'Videos',
          icon: 'FilmIcon',
          referral: true
        }
      ];
      setReferMenu(referValue);
    }
  }, [location]);

  const renderMenuIcon = (iconName, route) => {
    const Icon = menuIcons[iconName];
    return (
      <Icon
        className={classNames(
          route === currentRoute ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
        )}
        aria-hidden="true"
      />
    );
  };

  const navigation = referMenu;

  return (
    <>
      <div className="min-h-full">
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-white shadow-sm lg:static lg:overflow-y-visible'
            )
          }>
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                  {/* brand logo */}
                  <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to="/">
                        <img className="block h-10 w-auto" src={roofTop.logo} alt={roofTop.name} />
                      </Link>
                      <span className="ml-3 text-gray-500 text-3xl font-light">|</span>
                      <span className="ml-2 text-gray-500">{roofTop.name}</span>
                    </div>
                  </div>
                  {/* search bar - currently invisible */}
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 invisible">
                    <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                      <div className="w-full">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <input
                            id="search"
                            name="search"
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                            placeholder="Search"
                            type="search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* mobile hamburger menu button */}
                  <div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
                    <Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Popover.Button>
                  </div>
                </div>
              </div>
              {/* nav menu mobile - global */}
              <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                {({ close }) => {
                  closeDrawerRef = close;
                  return (
                    <>
                      <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                        {navigation.map((item) => (
                          <Link
                            key={`${item.name}${Math.random()}`}
                            to={item.href}
                            aria-current={item.href === currentRoute ? 'page' : undefined}
                            className={classNames(
                              item.href === currentRoute
                                ? 'bg-gray-100 text-gray-900'
                                : 'hover:bg-gray-50',
                              'block rounded-md py-2 px-3 text-base font-medium'
                            )}>
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </>
                  );
                }}
              </Popover.Panel>
            </>
          )}
        </Popover>
      </div>
      {/* nav menu desktop - global */}
      <div className="py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
            <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
              {/* nav menu - global - first section */}
              <div className="pb-8 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={`${item.name}${Math.random()}`}
                    to={item.href}
                    className={classNames(
                      item.href === currentRoute
                        ? 'bg-gray-200 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50',
                      'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                    )}
                    aria-current={item.href === currentRoute ? 'page' : undefined}>
                    {renderMenuIcon(item.icon, item.href)}
                    <span className="truncate">{item.name}</span>
                  </Link>
                ))}
              </div>
            </nav>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default withReferMenu(withLoader(withVehicleContent(ReferralLayout)));
