import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import NotFound from './screens/NotFound';
import CreateUser from './screens/admin/CreateUser';
import Dashboard from './screens/Dashboard';
import Layout from './Layout';
import MagicLinkLogin from './screens/MagicLinkLogin';
import Vehicle from './screens/Vehicle';
import Contacts from './screens/Contacts';
import Service from './screens/Service';
import Global from './components/Global';
import Profile from './screens/Profile';
import Videos from './screens/Videos';
import Photos from './screens/Photos';
import SalesReview from './screens/SalesReview';
import SendMagicLink from './screens/SendMagicLink';
import FriendsAndFamily from './screens/FriendsAndFamily';
import SalesPublicProfile from './screens/SalesPublicProfile';
import SalesProfile from './screens/SalesProfile';
import PhotoGallery from './screens/PhotoGallery';
/* import Training from './screens/Training'; */
import ReferralHome from './screens/ReferralHome';
import ReferralLayout from './ReferralLayout';
import SalesPublicLayout from './SalesPublicLayout';
import CreateStaff from './screens/admin/CreateStaff';
import NoLayout from './NoLayout';
import PartsAndAccessories from './screens/PartsAndAccessories';
import Wallet from './screens/Wallet';
import DealerReviews from './screens/DealerReviews';
import Financing from './screens/Financing';

const UN_AUTH_PATHS = ['/send-magic-link', '/dealer/'];

const ValidateRoute = ({ children }) => {
  const { user } = store.getState();
  const location = useLocation();

  const unAuthPath = UN_AUTH_PATHS.filter((path) => location.pathname.indexOf(path) > -1);

  if (!isEmpty(user.id) && unAuthPath.length > 0) {
    return <Navigate to={`${user.role.indexOf('sales') > -1 ? '/public-profile' : '/'}`} replace />;
  }
  return children;
};

const AppRoutes = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Global />
      <Routes>
        <Route
          path="/send-magic-link"
          element={
            <ValidateRoute>
              <SendMagicLink />
            </ValidateRoute>
          }
        />
        <Route
          path="/dealer/:id/reviews/:type"
          element={
            <ValidateRoute>
              <DealerReviews />
            </ValidateRoute>
          }
        />
        <Route
          path="/login-via-magic-link/:code"
          element={
            <ValidateRoute>
              <MagicLinkLogin />
            </ValidateRoute>
          }
        />
        <Route
          exact
          path="/user"
          element={
            <ValidateRoute>
              <ReferralLayout />
            </ValidateRoute>
          }>
          <Route
            path="/user/:userId"
            element={
              <ValidateRoute>
                <ReferralHome />
              </ValidateRoute>
            }
          />
          <Route
            path="/user/:userId/refer/:referCode"
            element={
              <ValidateRoute>
                <ReferralHome />
              </ValidateRoute>
            }
          />
          <Route
            path="/user/:userId/photos"
            element={
              <ValidateRoute>
                <Photos />
              </ValidateRoute>
            }
          />
          <Route
            path="/user/:userId/videos"
            element={
              <ValidateRoute>
                <Videos />
              </ValidateRoute>
            }
          />
        </Route>
        <Route exact path="/sales" element={<SalesPublicLayout />}>
          <Route
            path="/sales/:id"
            element={
              <ValidateRoute>
                <SalesReview />
              </ValidateRoute>
            }
          />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route
            path="/"
            element={
              <ValidateRoute>
                <Dashboard />
              </ValidateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ValidateRoute>
                <Profile />
              </ValidateRoute>
            }
          />
          <Route
            path="/vehicle"
            element={
              <ValidateRoute>
                <Vehicle />
              </ValidateRoute>
            }
          />
          <Route
            path="/videos"
            element={
              <ValidateRoute>
                <Videos />
              </ValidateRoute>
            }
          />
          <Route
            path="/photos"
            element={
              <ValidateRoute>
                <Photos />
              </ValidateRoute>
            }
          />
          <Route
            path="/service"
            element={
              <ValidateRoute>
                <Service />
              </ValidateRoute>
            }
          />
          <Route
            path="/contacts"
            element={
              <ValidateRoute>
                <Contacts />
              </ValidateRoute>
            }
          />
          <Route
            path="/wallet"
            element={
              <ValidateRoute>
                <Wallet />
              </ValidateRoute>
            }
          />
          <Route
            path="/contact/:id"
            element={
              <ValidateRoute>
                <SalesReview />
              </ValidateRoute>
            }
          />
          <Route
            path="/financing"
            element={
              <ValidateRoute>
                <Financing />
              </ValidateRoute>
            }
          />
          <Route
            path="/friends-and-family"
            element={
              <ValidateRoute>
                <FriendsAndFamily />
              </ValidateRoute>
            }
          />
          <Route
            path="/parts-and-accessories"
            element={
              <ValidateRoute>
                <PartsAndAccessories />
              </ValidateRoute>
            }
          />
          <Route
            path="/public-profile"
            element={
              <ValidateRoute>
                <SalesPublicProfile />
              </ValidateRoute>
            }
          />
          <Route
            path="/manage-profile"
            element={
              <ValidateRoute>
                <SalesProfile />
              </ValidateRoute>
            }
          />
          <Route
            path="/photo-gallery"
            element={
              <ValidateRoute>
                <PhotoGallery />
              </ValidateRoute>
            }
          />
          {/* <Route
            path="/training"
            element={
              <ValidateRoute>
                <Training />
              </ValidateRoute>
            }
          /> */}
        </Route>
        <Route exact path="/admin" element={<NoLayout />}>
          <Route
            path="/admin/user/create"
            element={
              <ValidateRoute>
                <CreateUser />
              </ValidateRoute>
            }
          />
          <Route
            path="/admin/staff/create"
            element={
              <ValidateRoute>
                <CreateStaff />
              </ValidateRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </PersistGate>
  </Provider>
);

export default AppRoutes;
