import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import formatString from 'format-string-by-pattern';
import withUser from '../../redux/Hoc/withUser';
import withNotify from '../../redux/Hoc/withNotify';
import withLoader from '../../redux/Hoc/withLoader';
import {
  checkDuplicateUserEmail,
  getProfileById,
  getRoofTopById,
  updateProfile
} from '../../utils/firebase';
import {
  composeValidators,
  email,
  renderField,
  required,
  usPhoneNumber
} from '../../helpers/form-validations';
import UploadProfile from '../../modals/UploadProfile';
import { eventReactGTMModuleSales } from '../../utils/analytics';

const Profile = ({ user, setLoader, setUser, setNotify }) => {
  const [isFirstNameSave, setIsFirstNameSave] = useState(false);
  const [isLastNameSave, setIsLastNameSave] = useState(false);
  const [isEmailSave, setIsEmailSave] = useState(false);
  const [isPhoneSave, setIsPhoneSave] = useState(false);
  const [isTitleSave, setIsTitleSave] = useState(false);
  const [rooftopDetail, setRooftopDetail] = useState({});
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  useEffect(() => {
    async function onInit() {
      const response = await getRoofTopById(user.rooftop);
      setRooftopDetail(response);
    }
    onInit();
  }, []);

  const onUpdate = async (data) => {
    setLoader(true);
    const profileRes = await getProfileById(user.id);
    if (Object.prototype.hasOwnProperty.call(data, 'first')) {
      profileRes.name.first = data.first;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'last')) {
      profileRes.name.last = data.last;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'email')) {
      profileRes.email.primary = data.email;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'mobile')) {
      profileRes.phone.mobile = data.mobile;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'title')) {
      profileRes.title = data.title;
    }
    delete profileRes.id;
    const response = await updateProfile(user.id, profileRes);
    if (response) {
      setUser({ ...user, ...profileRes });
      setNotify({ message: 'Profile updated successfully.', type: 'success' });
    } else {
      setNotify({ message: 'Profile update failed! Please try again.', type: 'error' });
    }
    setLoader(false);
  };

  const gaEventCall = (profileItem) => {
    const tagManagerArgs = {
      event: 'e_agent_profile',
      agentID: user.staff,
      rooftop: user.rooftop,
      dealerGroupID: user.rooftopData.group,
      experience: 'sa_private',
      profile_item: profileItem
    };
    eventReactGTMModuleSales(tagManagerArgs);
  };

  const onFirstNameSave = async (formData) => {
    gaEventCall('FirstName');
    await onUpdate(formData);
    setIsFirstNameSave(false);
  };

  const onEditFirstName = () => {
    setIsFirstNameSave(true);
  };

  const onLastNameSave = async (formData) => {
    gaEventCall('LastName');
    await onUpdate(formData);
    setIsLastNameSave(false);
  };

  const onEditLastName = () => {
    setIsLastNameSave(true);
  };

  const onEmailSave = async (formData) => {
    const userDetail = await checkDuplicateUserEmail(formData.email, user.id);
    if (userDetail) {
      setNotify({ message: 'Email already exits!', type: 'error' });
      setIsEmailSave(false);
      return;
    }
    gaEventCall('Email');
    await onUpdate(formData);
    setIsEmailSave(false);
  };

  const onEditEmail = () => {
    setIsEmailSave(true);
  };

  const onPhoneSave = async (formData) => {
    gaEventCall('Phone');
    await onUpdate(formData);
    setIsPhoneSave(false);
  };

  const onEditPhone = () => {
    setIsPhoneSave(true);
  };

  const onTitleSave = async (formData) => {
    gaEventCall('Title');
    await onUpdate(formData);
    setIsTitleSave(false);
  };

  const onEditTitle = () => {
    setIsTitleSave(true);
  };

  return (
    <>
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="relative group cursor-pointer" onClick={() => setIsUploadModalOpen(true)}>
          <img
            className="w-20 h-20 bg-gray-300 rounded-full flex-shrink-0"
            src={user.avatar}
            alt=""
          />
          <div className="h-10 opacity-0 group-hover:opacity-75 duration-300 absolute inset-x-0 bottom-0 flex items-center justify-center items-end text-xl bg-white text-red-700 font-semibold">
            <div className="w-5 h-5 rounded-full border-2 bg-red-600 flex justify-center items-center">
              <p className="text-white">+</p>
            </div>
          </div>
        </div>

        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {user.name.first}&nbsp;
              {user.name.last}
            </h3>
          </div>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {user.title} - {rooftopDetail.name}
          </p>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">First name</dt>
            {isFirstNameSave ? (
              <>
                <Form
                  onSubmit={onFirstNameSave}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="contents">
                      <dd className="mb-1">
                        <Field
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                          name="first"
                          type="text"
                          id="first"
                          placeholder="First Name"
                          defaultValue={user.name.first}
                          validate={required}>
                          {renderField}
                        </Field>
                      </dd>
                      <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                        <button
                          type="submit"
                          className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                          Save
                        </button>
                      </dd>
                    </form>
                  )}
                />
              </>
            ) : (
              <>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                  {user.name.first}
                </dd>
                <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                  <span
                    onClick={onEditFirstName}
                    className="cursor-pointer border-indigo-600 text-indigo-600">
                    Edit
                  </span>
                </dd>
              </>
            )}
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Last name</dt>
            {isLastNameSave ? (
              <>
                <Form
                  onSubmit={onLastNameSave}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="contents">
                      <dd className="mb-1">
                        <Field
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                          name="last"
                          type="text"
                          id="last"
                          placeholder="Last Name"
                          defaultValue={user.name.last}
                          validate={required}>
                          {renderField}
                        </Field>
                      </dd>
                      <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                        <button
                          type="submit"
                          className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                          Save
                        </button>
                      </dd>
                    </form>
                  )}
                />
              </>
            ) : (
              <>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                  {user.name.last}
                </dd>
                <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                  <span
                    onClick={onEditLastName}
                    className="cursor-pointer border-indigo-600 text-indigo-600">
                    Edit
                  </span>
                </dd>
              </>
            )}
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            {isEmailSave ? (
              <>
                <Form
                  onSubmit={onEmailSave}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="contents">
                      <dd className="mb-1">
                        <Field
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                          name="email"
                          type="text"
                          id="email"
                          placeholder="Email"
                          defaultValue={user.email.primary}
                          validate={composeValidators(required, email)}>
                          {renderField}
                        </Field>
                      </dd>

                      <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                        <button
                          type="submit"
                          className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                          Save
                        </button>
                      </dd>
                    </form>
                  )}
                />
              </>
            ) : (
              <>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                  {user.email.primary}
                </dd>
                <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                  <span
                    onClick={onEditEmail}
                    className="cursor-pointer border-indigo-600 text-indigo-600">
                    Edit
                  </span>
                </dd>
              </>
            )}
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Phone</dt>
            {isPhoneSave ? (
              <>
                <Form
                  onSubmit={onPhoneSave}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="contents">
                      <dd className="mb-1">
                        <Field
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                          name="mobile"
                          type="text"
                          id="mobile"
                          placeholder="Phone"
                          defaultValue={user.phone.mobile}
                          parse={formatString('(999) 999-9999')}
                          validate={composeValidators(required, usPhoneNumber)}>
                          {renderField}
                        </Field>
                      </dd>

                      <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                        <button
                          type="submit"
                          className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                          Save
                        </button>
                      </dd>
                    </form>
                  )}
                />
              </>
            ) : (
              <>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                  {user.phone.mobile}
                </dd>
                <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                  <span
                    onClick={onEditPhone}
                    className="cursor-pointer border-indigo-600 text-indigo-600">
                    Edit
                  </span>
                </dd>
              </>
            )}
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Title</dt>
            {isTitleSave ? (
              <>
                <Form
                  onSubmit={onTitleSave}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="contents">
                      <dd className="mb-1">
                        <Field
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm h-12 border-gray-300 rounded-md"
                          name="title"
                          type="text"
                          id="title"
                          placeholder="Title"
                          defaultValue={user.title}
                          validate={required}>
                          {renderField}
                        </Field>
                      </dd>

                      <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                        <button
                          type="submit"
                          className="text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">
                          Save
                        </button>
                      </dd>
                    </form>
                  )}
                />
              </>
            ) : (
              <>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">{user.title}</dd>
                <dd className="text-sm text-gray-900 sm:col-span-1 text-right">
                  <span
                    onClick={onEditTitle}
                    className="cursor-pointer border-indigo-600 text-indigo-600">
                    Edit
                  </span>
                </dd>
              </>
            )}
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.role[0].capitalize()}
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Preferred Dealer</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {rooftopDetail.name}
            </dd>
          </div>
        </dl>
      </div>
      {isUploadModalOpen && <UploadProfile onClose={() => setIsUploadModalOpen(false)} />}
    </>
  );
};

export default withNotify(withLoader(withUser(Profile)));
