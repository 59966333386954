/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import ReactStars from 'react-rating-stars-component';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { USPhoneNumber } from '../../helpers/app';

const SalesContactCard = ({ staff, rooftop, reviews, gaEventData }) => {
  const onClickEventMethod = (link, method) => {
    gaEventData({
      event: 'e_agent_contact',
      area: 'home_card_sa',
      agent_contact_method: method
    });
    window.open(link);
  };

  const onClickEventDestination = (link, destination) => {
    gaEventData({
      event: 'e_dealer_visit',
      area: 'home_card_sa',
      external_destination: destination
    });
    window.open(link);
  };

  const onClickEvent = (link) => {
    gaEventData({
      event: 'e_agent_visit',
      area: 'home_card_sa'
    });
    window.open(link);
  };

  return (
    <div className="flow-root p-6">
      <div className="w-full flex items-center justify-between space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-lg font-medium truncate">
              {staff.name.first} {staff.name.last}
            </h3>
          </div>
          <div className="flex items-center space-x-3">
            <div className="flex items-center mt-4">
              <ReactStars
                size={20}
                count={5}
                color="grey"
                activeColor="#FACC14"
                value={staff.rating}
                isHalf
                edit={false}
              />
            </div>
            <span className="text-sm font-normal text-gray-500 pl-4 mt-4">
              {reviews > 0 ? (reviews > 1 ? `${reviews} Reviews` : `${reviews} Review`) : ''}
            </span>
          </div>
        </div>
        <img
          className="w-20 h-20 bg-gray-300 rounded-full flex-shrink-0"
          src={staff.avatar}
          alt=""
        />
      </div>
      <div className="mt-4">
        <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3">
          <dt
            className="cursor-pointer text-sm text-blue-500 hover:text-[#5046E4] font-normal"
            onClick={() => onClickEventMethod(`tel:${staff.phone.mobile}`, 'call')}>
            {USPhoneNumber(staff.phone.mobile)}
          </dt>
        </div>
        <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3">
          <dt
            className="cursor-pointer text-sm text-blue-500 hover:text-[#5046E4] font-normal"
            onClick={() => onClickEventMethod(`mailto:${staff.email.primary}`, 'email')}>
            {staff.email.primary}
          </dt>
        </div>
        <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3">
          <dt
            className="cursor-pointer text-sm text-blue-500 hover:text-[#5046E4] font-normal"
            onClick={() => onClickEventDestination(rooftop.url.main, 'home')}>
            {rooftop.url.main}
          </dt>
        </div>
      </div>
      <div className="self-end mt-12">
        <p
          className="cursor-pointer flex flex-row inline-flex shadow text-white bg-indigo-600 hover:bg-indigo-800 focus:shadow-outline focus:outline-none py-2 px-4 rounded"
          onClick={() => onClickEvent(`${window.location.origin}/sales/${staff.id}`)}>
          <span>See {staff.name.first}’s Profile</span>
          <ArrowRightIcon className=" ml-2 h-6 w-6 text-white" aria-hidden="true" />
        </p>
      </div>
    </div>
  );
};

export default SalesContactCard;
