import React from 'react';
import Slider from 'react-slick';

const sliderSettings = {
  lazyLoad: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const SalesSliderImages = ({ data }) => (
  <>
    {data.length ? (
      <Slider {...sliderSettings}>
        {data.map((image, index) => (
          <div
            key={`image-${index}`}
            className="group block w-full aspect-w-15 aspect-h-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-gray-500 overflow-hidden">
            <img
              src={image}
              alt={image}
              className="object-cover h-120 w-120 object-cover pointer-events-none"
            />
          </div>
        ))}
      </Slider>
    ) : null}
  </>
);

export default SalesSliderImages;
