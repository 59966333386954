import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/solid';
import ReactStars from 'react-rating-stars-component';
import { isEmpty } from 'lodash';
import GoodOrBadReview from './GoodOrBadReview';
import { getRoofTopById } from '../utils/firebase';
import { eventAnalytics, eventReactGTMModule } from '../utils/analytics';
import { ACTION, CATEGORY } from '../constants';

const LeaveReview = ({ onContinue, open, staff }) => {
  const [goodOrBadReview, setGoodOrBadReview] = useState(false);
  const [reviewType, seReviewType] = useState(null);
  const [rooftop, setRooftop] = useState({});

  const onBadReview = (value) => {
    eventAnalytics({
      category: CATEGORY.REVIEW,
      action: ACTION.REV_NEGATIVE,
      nonInteraction: false
    });
    seReviewType(value);
    setGoodOrBadReview(true);
  };
  const onGoodReview = (value) => {
    eventAnalytics({
      category: CATEGORY.REVIEW,
      action: ACTION.REV_POSITIVE,
      nonInteraction: false
    });
    seReviewType(value);
    setGoodOrBadReview(true);
  };

  const onSkip = () => {
    onContinue();
    eventAnalytics({
      category: CATEGORY.REVIEW,
      action: ACTION.REV_CANCEL,
      nonInteraction: false
    });
    const tagManagerArgs = {
      event: 'e_review_skip',
      agentID: staff.id, // sales agent being reviewed
      area: 'review_modal'
    };
    if (!isEmpty(staff)) {
      eventReactGTMModule(tagManagerArgs);
    }
  };

  useEffect(() => {
    async function onInit() {
      if (!isEmpty(staff)) {
        setRooftop(await getRoofTopById(staff.rooftop));
      }
      if (open) {
        eventAnalytics({
          category: CATEGORY.REVIEW,
          action: ACTION.REV_SHOWN,
          label: `${staff.name.first} ${staff.name.last}`,
          nonInteraction: false
        });
        const tagManagerArgs = {
          event: 'e_review_open',
          agentID: staff.id // sales agent being reviewed
        };
        eventReactGTMModule(tagManagerArgs);
      }
    }
    onInit();
  }, [staff]);

  return (
    <>
      <Transition.Root show={open} as={Fragment} onClose={onContinue}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="relative inline-block align-bottom bg-white rounded-lg sm:w-11/12 md:w-8/12 lg:w-3/5 xl:w-6/12 2xl:w-4/12 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
                <div className="w-full flex items-center justify-between space-x-6">
                  <div className="flex flex-col-reverse">
                    <div className="mt-4">
                      <Dialog.Title
                        as="h1"
                        className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-1xl">
                        {staff.name.first} {staff.name.last}
                      </Dialog.Title>

                      <Dialog.Title as="h2" id="information-heading" className="sr-only">
                        Product information
                      </Dialog.Title>
                      <p className="text-sm text-gray-500 mt-2">{`Sales Agent - ${rooftop.name}`}</p>
                    </div>
                    {rooftop.google_review ? null : (
                      <div className="flex items-center">
                        <ReactStars
                          size={20}
                          count={5}
                          color="#F5F5F5"
                          activeColor="#D8D8D8"
                          value={staff.rating}
                          isHalf
                          edit={false}
                        />
                        <p className="text-sm text-gray-500 ml-2">Average rating</p>
                      </div>
                    )}
                  </div>
                  <img
                    className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"
                    src={staff.avatar}
                    alt=""
                  />
                </div>
                <div>
                  <div className="mt-2 text-center sm:mt-10">
                    <div className="mt-2">
                      <p className="text-sm text-gray-800 text-lg">
                        {`How was your purchase experience with ${staff.name.first} ${staff.name.last}?`}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full mx-auto mt-9">
                  <div className="inline-flex sm:grid grid-cols-4 gap-5 mx-auto sm:-ml-[8%] md:ml-4 lg:ml-9 md:ml-16">
                    <div className="col-start-1 col-end-3 my-2">
                      <button
                        type="button"
                        className="focus:outline-none sm:ml-2 md:ml-10"
                        onClick={() => onGoodReview('good')}>
                        <div className="h-full p-6 bg-white hover:shadow-xl rounded text-right">
                          <ThumbUpIcon className="w-20 h-32 fill-indigo-600" />
                          <p className="text-sm text-gray-500 text-sm text-center">Good</p>
                        </div>
                      </button>
                    </div>
                    <div className="col-end-5 col-span-2 my-2">
                      <button
                        type="button"
                        className="focus:outline-none ml-10"
                        onClick={() => onBadReview('bad')}>
                        <div className="h-full p-6 bg-white hover:shadow-xl rounded">
                          <ThumbDownIcon className="w-20 h-32 fill-indigo-600" />
                          <p className="text-sm text-gray-500 text-sm text-center">Not so good</p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="max-w-2xl mx-auto text-center py-11 px-4 sm:py-15 sm:px-6 lg:px-8">
                  <button
                    type="button"
                    className="shadow-md mt-2 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent border-b-white text-base font-medium rounded-md text-gray-500 bg-white hover:bg-indigo-50 sm:w-auto drop-shadow-2xl"
                    onClick={() => onSkip()}>
                    Skip
                  </button>
                </div>
              </div>
            </Transition.Child>
            <GoodOrBadReview
              onGoodOrBadReview={() => setGoodOrBadReview(false)}
              goodOrBadReview={goodOrBadReview}
              staff={staff}
              rooftop={rooftop}
              onContinue={onContinue}
              reviewType={reviewType}
            />
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LeaveReview;
