/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';
import { eventAnalytics, eventReactGTMModule } from '../../utils/analytics';
import { ACTION, CATEGORY } from '../../constants';

const ContactCards = ({ data, onSalesReview, user, event, area }) => {
  const onClickCallOrEmail = (value, item, link) => {
    eventAnalytics({
      category: CATEGORY.CONTACT,
      action: value === 'email' ? ACTION.AGENT_EMAIL : ACTION.AGENT_CALL,
      label: value === 'email' ? item.email.primary : item.phone.mobile,
      nonInteraction: false
    });
    const tagManagerArgs = {
      event,
      agentID: item.id,
      area,
      agent_contact_method: value === 'email' ? 'email' : 'call' // 'email' or 'call'
    };
    eventReactGTMModule(tagManagerArgs);
    window.open(link);
  };

  return (
    <>
      {data.map(
        (person) =>
          person.name.last.toLowerCase() !== 'test' && (
            <div
              key={person.id}
              className={`col-span-12 md:col-span-1 ${
                user.staff === person.id ? 'bg-[#4F45DF]' : 'bg-white'
              } rounded-lg divide-y divide-gray-200 hover:scale-[1.05] ease-out duration-100 drop-shadow-[0px_2px_4px_rgba(0,0,0,0.1)] hover:drop-shadow-[0px_10px_12px_rgba(0,0,0,0.2)]`}>
              <div
                className={`w-full flex items-center justify-between p-6 space-x-6 ${
                  user.staff === person.id ? 'cursor-pointer' : ''
                }`}
                onClick={
                  person.role.includes('sales')
                    ? () => {
                        onSalesReview(person.id);
                      }
                    : () => {}
                }>
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3
                      className={`${
                        user.staff === person.id ? 'text-white' : 'text-gray-900'
                      } text-sm font-medium truncate`}>
                      {person.name.first} {person.name.last}
                    </h3>
                  </div>
                  <p
                    className={`mt-1 ${
                      user.staff === person.id ? 'text-slate-400' : 'text-gray-500'
                    } text-sm truncate`}>
                    {user.staff === person.id ? 'Your Sales Agent' : person.title}
                  </p>
                </div>
                <img
                  className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                  src={person.avatar}
                  alt=""
                />
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="w-0 flex-1 flex">
                    <p
                      className="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                      onClick={() =>
                        onClickCallOrEmail('email', person, `mailto:${person.email.primary}`)
                      }>
                      <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                      <span className={`ml-3 ${user.staff === person.id ? 'text-white' : ''}`}>
                        Email
                      </span>
                    </p>
                  </div>
                  <div className="-ml-px w-0 flex-1 flex">
                    <p
                      className="cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                      onClick={() =>
                        onClickCallOrEmail('phone', person, `tel:${person.phone.mobile}`)
                      }>
                      <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                      <span className={`ml-3 ${user.staff === person.id ? 'text-white' : ''}`}>
                        Call
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
};

export default ContactCards;
