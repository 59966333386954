import appImage from '../assets/image';

export const ROBO_USER = {
  name: 'Vinsyt robo',
  avatar:
    'https://firebasestorage.googleapis.com/v0/b/vinsyt-prod.appspot.com/o/app%2Fvinsyt-robo.webp?alt=media&token=9d2a8bc5-9877-41d3-8cb3-cb22d9e4a6aa'
};

export const STORAGE_PATH = {
  PROFILE: 'profile',
  APP: 'app',
  DATA_MODEL: 'data_model',
  ROOFTOPS: 'rooftops',
  VEHICLES: 'vehicles',
  SALES_GALLERY: 'sales_gallery',
  FEED: 'feed',
  REPORT_PROBLEM: 'report_problems'
};

export const FIREBASE_DOCS = {
  ACCOUNTS: 'accounts',
  GROUPS: 'groups',
  INVENTORY: 'inventory',
  INVENTORY_SNAPSHOTS: 'inventory_snapshots',
  PAYABLES: 'payables',
  PROFILES: 'profiles',
  ROOFTOPS: 'rooftops',
  STAFF: 'staff',
  SUBSCRIPTIONS: 'subscriptions',
  USER_FEED: 'user_feed',
  USERS: 'users',
  VEHICLE_CONTENT: 'vehicle_content',
  VEHICLE_TEMPLATES: 'vehicle_templates',
  VEHICLES: 'vehicles',
  REVIEWS: 'reviews',
  REFERRALS: 'referrals',
  UNSUBSCRIBE: 'unsubscribe',
  TESTIMONIAL: 'owner_testimonials'
};

export const CATEGORY = {
  CHECKLIST: 'checklist',
  POST: 'post',
  ASSET: 'asset',
  REFERRAL: 'referral',
  CONTACT: 'contact',
  SALES_AGENT: 'salesagent',
  SHARE: 'share',
  REVIEW: 'review'
};

export const ACTION = {
  CHK_PASSKIT: 'chk_passkit',
  CHK_LEAVE_REVIEW: 'chk_leave_review',
  CHK_FRIENDS_FAMILY: 'chk_friends_family',
  CHK_SHARE_FACEBOOK: 'chk_share_facebook',
  AGENT_AVATAR: 'agent_avatar',
  DOC_DOWNLOAD: 'doc_download',
  VIDEO_PLAY: 'video_play',
  PHOTO_VIEW: 'photo_view',
  GALLERY_VIEW: 'gallery_view',
  REF_GET_STARTED: 'ref_get_started',
  REF_ADD_ROWS: 'ref_add_rows',
  REF_SEND: 'ref_send',
  REF_FORM_ACTIVITY: 'ref_form_activity',
  REF_ERROR: 'ref_error',
  AGENT_EMAIL: 'agent_email',
  AGENT_CALL: 'agent_call',
  AGENT_PROFILE: 'agent_profile',
  AGENT_SHARE_FACEBOOK: 'agent_share_facebook',
  AGENT_SHARE_INSTAGRAM: 'agent_share_instagram',
  AGENT_SHARE_TWITTER: 'agent_share_twitter',
  SHARE_FACEBOOK: 'share_facebook',
  REV_SHOWN: 'rev_shown',
  REV_POSITIVE: 'rev_positive',
  REV_NEGATIVE: 'rev_negative',
  REV_RATING: 'rev_rating',
  REV_COMMENT: 'rev_comment',
  REV_BACK: 'rev_back',
  REV_CANCEL: 'rev_cancel',
  REV_SAVE: 'rev_save',
  REV_CONTINUE: 'rev_continue',
  REV_GOOGLE_CLICK: 'rev_google_click'
};

export const FEED_BUTTONS = {
  welcome: 'Download your Photo',
  leave_review: 'Leave a Review',
  share_with_ff: 'Send to your Friends & Family'
};

export const ROLE = {
  SALES: 'sales',
  SERVICE_ADVISOR: 'service_advisor',
  SERVICE_MANAGER: 'service_manager',
  SERVICE: 'service',
  SERVICE_SPECIALIST: 'service_specialist',
  SERVICE_WRITER: 'service_writer',
  SERVICE_DIRECTOR: 'service_director',
  SERVICE_CONSULTANT: 'service_consultant',
  CLIENT_ADVISOR: 'client_advisor',
  MANAGEMENT: 'management'
};

export const HeaderCards = [
  {
    navigate: '/vehicle',
    title: 'Vehicle Info',
    subtitle: 'Your vehicle specs and overview',
    icon: appImage.VehicleInfo
  },
  {
    navigate: '/service',
    title: 'Service',
    subtitle: 'Schedule an appointment',
    icon: appImage.Service
  },
  {
    navigate: '/videos',
    title: 'How-To Videos',
    subtitle: 'Learn how your vehicle works',
    icon: appImage.HowToVideos
  },
  // {
  //   navigate: '/photos',
  //   title: 'Photo Gallery',
  //   subtitle: 'Get photos to share',
  //   icon: appImage.PhotoGallery
  // },
  {
    navigate: '/vehicle',
    title: 'Owners Manuals',
    subtitle: 'Download digital owners documentation',
    icon: appImage.OwnerManuals
  },
  {
    navigate: '/contacts',
    title: 'Contacts',
    subtitle: 'Get in touch with the team',
    icon: appImage.Contacts
  }
];
