import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CameraIcon } from '@heroicons/react/outline';

import ProfileCropper from './ProfileCropper';

const UploadProfile = ({ onClose }) => {
  const [image, setImage] = useState('');
  const [imageData, setImageData] = useState({});
  const [isCropper, setIsCropper] = useState(false);
  const onImgUpload = () => {
    document.getElementById('imgupload').click();
  };

  const onSelectImage = (event) => {
    if (event.files && event.files[0]) {
      setImage(URL.createObjectURL(event.files[0]));
      setImageData(event.files[0]);
      setIsCropper(true);
    }
  };

  const onMakeAvatar = () => {
    onClose();
    setIsCropper(false);
  };

  const onCropperBack = () => {
    setIsCropper(false);
  };

  return (
    <>
      <Transition.Root show as={Fragment} onClose={onClose}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center">
                    <CameraIcon className="h-40 w-full text-indigo-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium border-indigo-600 text-indigo-600">
                      Upload a Photo
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Choose your best look from your library
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex m-5 space-x-1.5">
                  <button
                    onClick={onClose}
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white-600 text-base font-medium text-gray-500 sm:text-sm">
                    Close
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    id="imgupload"
                    className="hidden"
                    onChange={(e) => onSelectImage(e.target)}
                  />
                  <button
                    onClick={onImgUpload}
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    type="button"
                    id="OpenImgUpload">
                    Choose Photo
                  </button>
                </div>
              </div>
            </Transition.Child>
            {isCropper && (
              <ProfileCropper
                image={image}
                imageData={imageData}
                onMakeAvatar={onMakeAvatar}
                onCropperBack={onCropperBack}
                crop
              />
            )}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default UploadProfile;
