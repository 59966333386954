import React from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/solid';

const DashboardContactCard = ({ data, onRedirectAction }) => (
  <section aria-labelledby="who-to-follow-heading" className="mb-4 md:mb-6 xl:mb-9">
    <div
      key={data.email}
      className="col-span-1 bg-white rounded-lg shadow-md divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {data.name.first} {data.name.last}
            </h3>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">{data.title}</p>
        </div>
        <img
          className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
          src={data.avatar}
          alt=""
        />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div
            className="w-0 flex-1 flex cursor-pointer"
            onClick={() => onRedirectAction(`mailto:${data.email.primary}`, 'email')}>
            <p className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
              <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Email</span>
            </p>
          </div>
          <div
            className="-ml-px w-0 flex-1 flex cursor-pointer"
            onClick={() => onRedirectAction(`tel:${data.phone.mobile}`, 'call')}>
            <p className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
              <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Call</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DashboardContactCard;
