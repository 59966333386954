import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import moment from 'moment';
import withLoader from '../redux/Hoc/withLoader';
import withUser from '../redux/Hoc/withUser';
import SendDiscount from '../modals/SendDiscount';
import SendDiscountForm from '../components/FriendsAndFamily/SendDiscountForm';
import OfferCard from '../components/FriendsAndFamily/OfferCard';
import { eventAnalytics } from '../utils/analytics';
import { ACTION, CATEGORY } from '../constants';
import {
  getReferralByUserId,
  getRoofTopById,
  getStaffById,
  getVehiclesByUserId
} from '../utils/firebase';
import DataTable from '../elements/DataTable';

const FriendsAndFamily = ({ setLoader, user }) => {
  const [open, setOpen] = useState(false);
  const [rooftop, setRooftop] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [salesAgent, setSalesAgent] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [referral, setReferral] = useState([]);

  const columns = [
    { Header: 'Referred Name', accessor: 'name' },
    { Header: 'Referred Email', accessor: 'email' },
    { Header: 'Referred Phone', accessor: 'phone' },
    { Header: 'Referred Relationship', accessor: 'relationship' },
    { Header: 'Referral Date', accessor: 'date' }
  ];

  useEffect(() => {
    async function onInit() {
      setLoader(true);
      const roofTopDetail = await getRoofTopById(user.rooftop);
      const vehicles = await getVehiclesByUserId(user.id);
      const salesData = await getStaffById(user.staff);
      const referralData = await getReferralByUserId(user.id);
      setReferral(
        referralData.map((item) => ({
          name: `${item.name.first} ${item.name.last}`,
          email: item.email,
          phone: item.phone,
          date: moment(item.created_on.seconds * 1000).format('YYYY-MM-DD'),
          relationship: item.relationship
        }))
      );
      setRooftop(roofTopDetail);
      setSalesAgent(salesData);
      if (vehicles.length > 0) {
        setVehicle(vehicles[0]);
      }
      setLoader(false);
    }
    onInit();
  }, [formSubmitted]);

  return (
    <>
      <main className="md:col-span-10 xl:col-span-9">
        <>
          <div className="bg-gray-50 rounded-lg">
            <div className="rounded-lg bg-white pb-12 shadow">
              <div className="relative z-0 ">
                <div className=" bg-cover bg-center bg-share-cover rounded-t-lg h-[460px] w-full ">
                  <div className="relative bg-black/70 w-full h-full rounded-t-lg md:pt-8">
                    <div className="flex flex-row mb-3.5">
                      <div className="bg-[#4F45DF] w-[396px] h-[60px] flex items-center rounded-tl-lg md:rounded-none xl:w-[496px]">
                        <h1 className="text-2xl md:text-[30px] text-white font-bold ml-8 md:ml-14">
                          Recommend {salesAgent && salesAgent.name && salesAgent.name.first}!
                        </h1>
                      </div>
                      <div className="fnf-ribbon w-14 h-[60px] bg-[#4F45DF] -translate-x-[0.5px]" />
                    </div>
                    <p className="text-white max-w-xs ml-8 md:ml-14">
                      Would you be willing to recommend me to your friends and family with the hope
                      that the next time they are in the market for a new car, they might think of
                      me?
                    </p>
                  </div>

                  <OfferCard rooftop={rooftop} vehicle={vehicle} />
                </div>
              </div>
              <section aria-labelledby="comparison-heading">
                <div className="mt-5 cursor-pointer text-center">
                  <div className="rounded-lg">
                    <div className="col-start-1 col-end-3 my-2">
                      <div
                        className="rounded hidden font-pacifico text-center"
                        onClick={() => {
                          eventAnalytics({
                            category: CATEGORY.REFERRAL,
                            action: ACTION.REF_GET_STARTED,
                            nonInteraction: false
                          });
                          window.scrollTo(0, 500);
                        }}>
                        <p className="text-[#5046E4] text-center text-[35px]">Get Started!</p>
                        <ChevronDownIcon className="inline-block w-8 h-8 text-center fill-indigo-600" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative max-w-2xl mx-auto pt-[300px] md:pt-14 px-2 lg:max-w-5xl lg:px-4">
                  <p className="text-center mt-3 mb-9 text-[28px] md:text-2xl font-normal text-[#333333]  font-['Inter, Medium']">
                    Who do you want to share these discounts with?
                  </p>
                  <SendDiscountForm
                    setOpen={() => setOpen(true)}
                    setFormSubmitted={(value) => setFormSubmitted(value)}
                  />
                </div>
              </section>
              {formSubmitted && (
                <p className="text-[#5046E4] rounded font-pacifico text-center text-[45px]">
                  Thank You!
                </p>
              )}
              <div className="py-2 px-4 space-y-6 sm:px-6 mt-10">
                <DataTable
                  title="Your Referrals"
                  columns={columns}
                  data={referral}
                  notFoundText="No referrals available"
                />
              </div>
            </div>
          </div>
        </>
      </main>
      {open && <SendDiscount onContinue={() => setOpen(false)} open={open} />}
    </>
  );
};

export default withUser(withLoader(FriendsAndFamily));
