import React from 'react';
import { DownloadIcon } from '@heroicons/react/outline';
import { eventAnalytics, eventReactGTMModule } from '../../utils/analytics';
import { ACTION, CATEGORY } from '../../constants';

const downloadVehicleDetail = async (doc) => {
  eventAnalytics({
    category: CATEGORY.ASSET,
    action: ACTION.DOC_DOWNLOAD,
    label: doc.title,
    nonInteraction: false
  });
  const fileExtension = doc.source.substring(doc.source.lastIndexOf('.') + 1);
  const tagManagerArgs = {
    event: 'e_file_download',
    file_name: doc.title,
    file_path: doc.source,
    file_extension: fileExtension
  };
  eventReactGTMModule(tagManagerArgs);
  window.open(doc.source, '_blank');
};

const DownloadDocs = ({ data }) => (
  <>
    {data.length
      ? data.map((doc, index) => (
          <div
            key={`docs-${index}`}
            className="mt-5 col-span-1 flex shadow-sm rounded-md cursor-pointer">
            <div
              className="bg-blue-800 flex-shrink-0 flex items-center justify-center w-16 text-white rounded-l-md"
              onClick={() => {
                downloadVehicleDetail(doc);
              }}>
              <DownloadIcon className="w-8 h-8" />
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 text-sm truncate">
                <a
                  target="_blank"
                  href={doc.source}
                  className="text-gray-900 font-medium hover:text-gray-600"
                  rel="noreferrer">
                  {doc.title}
                </a>
                <p className="text-gray-500">-</p>
              </div>
            </div>
          </div>
        ))
      : null}
  </>
);

export default DownloadDocs;
