import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';
import { classNames } from './helpers/app';
import { getRoofTopById, getStaffById } from './utils/firebase';
import { initReactGTMModule } from './utils/analytics';

const closeDrawerRef = () => {};

const SalesPublicLayout = () => {
  const location = useLocation();
  const { id } = useParams();
  const [roofTop, setRooftop] = useState({});

  useEffect(() => {
    async function onInit() {
      const salesAgentData = await getStaffById(id);
      const roofTopRes = await getRoofTopById(salesAgentData.rooftop);
      setRooftop(roofTopRes);
      const tagManagerArgs = {
        environment: process.env.REACT_APP_ENV,
        version: '2.O'
      };
      initReactGTMModule(tagManagerArgs);
    }
    onInit();
  }, []);

  useEffect(() => {
    closeDrawerRef();
  }, [location]);

  return (
    <>
      <div className="min-h-full">
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-white shadow-sm lg:static lg:overflow-y-visible'
            )
          }>
          {() => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                  {/* brand logo */}
                  <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                    <div className="flex-shrink-0 flex items-center">
                      <Link to="/">
                        <img className="block h-10 w-auto" src={roofTop.logo} alt={roofTop.name} />
                      </Link>
                      <span className="ml-3 text-gray-500 text-3xl font-light">|</span>
                      <span className="ml-2 text-gray-500">{roofTop.name}</span>
                    </div>
                  </div>
                  {/* search bar - currently invisible */}
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6 invisible">
                    <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                      <div className="w-full">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <input
                            id="search"
                            name="search"
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                            placeholder="Search"
                            type="search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Popover>
      </div>
      {/* nav menu desktop - global */}
      <div className="py-10">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default SalesPublicLayout;
