import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import FinancingBanner from '../assets/image/financing-banner.png';
import Finance from '../components/Finance';
import appImage from '../assets/image';
import FinancingFaqImg from '../assets/image/financing-faq.png';
import NoHiddenFeesImg from '../assets/image/no-hidden-fees-img.png';
import { getRoofTopById } from '../utils/firebase';
import withUser from '../redux/Hoc/withUser';
import { eventReactGTMModule } from '../utils/analytics';

const Financing = ({ user }) => {
  const navigate = useNavigate();
  const [rooftopDetail, setRooftopDetail] = useState({});

  useEffect(() => {
    async function onInit() {
      const response = await getRoofTopById(user.rooftop);
      setRooftopDetail(response);
      if (response && !response.dignify) {
        navigate('/');
      }
    }
    onInit();
  }, []);
  return (
    <>
      <main className="lg:col-span-9 xl:col-span-9">
        <div className="w-full rounded-lg bg-white shadow flex flex-col mb-8 ">
          <div className="rounded-t-lg bg-[#F2F3F6] p-5 md:px-[68px] pt-[48px] pb-[50px] md:py-[90px] flex flex-col gap-6 relative">
            <img
              className="hidden md:block   bg-financing-banner financing-banner-height aspect-[6/4] absolute object-cover object-center top-0 right-0 rounded-tr-lg rounded-bl-[90px] md:rounded-bl-[120px]"
              src={FinancingBanner}
              alt="financing"
            />
            <div className="">
              <h2 className="text-2xl md:text-4xl leading-6 font-extrabold text-[#333333] mt-0 md:mt-0 mb-4 md:mb-8">
                Financing
              </h2>
              <p className="text-base md:text-lg font-normal max-w-[380px] ">
                {rooftopDetail && rooftopDetail.name} now offers{' '}
                <span className="text-[#5046E4]"> easy and flexible</span> access to financing for
                all your vehicle needs!
              </p>
              <div className="w-full font-light block md:hidden mt-3">
                <span className="flex flex-row items-center ">
                  <ChevronRightIcon className="h-7 text-[#5046E4]" />
                  No down payment required.
                </span>
                <span className="flex flex-row items-center">
                  <ChevronRightIcon className="h-7 text-[#5046E4]" />
                  <span>
                    Flexible payments from <span className="font-semibold">12 - 36 months</span>
                  </span>
                </span>
                <span className="flex flex-row items-center">
                  <ChevronRightIcon className="h-7 text-[#5046E4]" />
                  No application or prepayment fees
                </span>
                <span className="flex flex-row items-center">
                  <ChevronRightIcon className="h-7 text-[#5046E4]" />
                  Won't affect your credit score!
                </span>
              </div>
            </div>
            <div className="border-[#5046E4] border-[3px] w-[180px] md:w-96 absolute bottom-[-6px] left-0 rounded-br-full" />
          </div>
          <div className="md:px-[32px]  md:pt-[16px] ">
            <Finance rooftop={rooftopDetail} shadow={false} />
          </div>
          <div className="bg-[#FAFAFA] py-12 px-5 md:py-16 md:px-20  ">
            <h2 className="text-lg md:text-xl font-extrabold text-center mb-10 md:mb-16">
              What kind of work can be financed?
            </h2>
            <div className="flex flex-row flex-wrap gap-x-6 gap-y-8 md:gap-y-12 md:gap-x-6 justify-center md:justify-center">
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.RepairServiceWork} alt="" />
                <p className="text-[12px] font-bold text-center">Repairs & service work</p>
              </div>
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.PreventiveMaintenance} alt="" />
                <p className="text-[12px] font-bold text-center">Preventive Maintenance</p>
              </div>
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.TireServices} alt="" />
                <p className="text-[12px] font-bold text-center">Tire and Wheel Services</p>
              </div>
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.InsuranceDeductables} alt="" />
                <p className="text-[12px] font-bold text-center">Insurance deductibles</p>
              </div>
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.BodyWork} alt="" />
                <p className="text-[12px] font-bold text-center">Body work</p>
              </div>
              <div className="flex flex-col items-center w-[150px] gap-2">
                <img
                  className="w-[40px] h-[40px]"
                  src={appImage.RepairsMaintenancePackages}
                  alt=""
                />
                <p className="text-[12px] font-bold text-center">Prepaid maintenance packages</p>
              </div>
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.VehicleServiceContracts} alt="" />
                <p className="text-[12px] font-bold text-center">Vehicle service contracts</p>
              </div>
              <div className="flex flex-col items-center w-[130px] gap-2">
                <img className="w-[40px] h-[40px]" src={appImage.FinanceAccessories} alt="" />
                <p className="text-[12px] font-bold text-center">Accessories</p>
              </div>
            </div>
          </div>
          <div className=" relative">
            <div className="border-[#5046E4] border-[3px] w-[90px] md:w-[275px] absolute top-0 rounded-br-full" />
            <div className="mt-[50px] md:mt-[68px] px-5 md:px-[74px] grid grid-cols-12">
              <div className="hidden md:block col-span-7">
                <img
                  className="h-full w-[210px] object-cover py-4 rounded-bl-[90px]"
                  src={NoHiddenFeesImg}
                  alt="no hidden prepayments or fees"
                />
              </div>
              <div className="col-span-12 md:col-span-5">
                <h2 className="text-xl font-extrabold max-w-[240px] mb-6">
                  There are no hidden prepayments or fees
                </h2>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row items-center gap-2">
                    <img className="w-4 h-4" src={appImage.BlueCheckOutline} alt="" />
                    <p className="text-[15px]">Financing from $350 to $7,500 *</p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img className="w-4 h-4" src={appImage.BlueCheckOutline} alt="" />
                    <p className="text-[15px]">Competitive interest rates</p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img className="w-4 h-4" src={appImage.BlueCheckOutline} alt="" />
                    <p className="text-[15px]">Affordable payments</p>
                  </div>
                  <div className="w-full border-b border-[#5046E4] border-dashed" />
                  <div className="flex flex-row items-center gap-2">
                    <img className="w-4 h-4" src={appImage.BlueCrossOutline} alt="" />
                    <p className="text-[15px]">
                      <span className="text-[#5046E4] font-semibold">No</span> money down
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img className="w-4 h-4" src={appImage.BlueCrossOutline} alt="" />
                    <p className="text-[15px]">
                      {' '}
                      <span className="text-[#5046E4] font-semibold">No</span> prepayment penalties
                    </p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img className="w-4 h-4" src={appImage.BlueCrossOutline} alt="" />
                    <p className="text-[15px]">
                      {' '}
                      <span className="text-[#5046E4] font-semibold">No</span> hidden costs or fees
                      †
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-[50px] md:py-[68px] px-5 md:px-[74px] relative">
            <div className="bg-[#FAFAFA] rounded shadow px-5 py-12 md:px-20 grid grid-cols-12">
              <div className="col-span-12 md:col-span-6">
                <h2 className="text-xl font-extrabold max-w-[240px] mb-2">Financing made easy</h2>
                <p className="text-[15px] max-w-[285px]">
                  Resolve all of your questions about how our financing program works!
                </p>
                <button
                  className="text-white bg-[#5046E4] min-w-[130px] hover:bg-indigo-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none mt-8"
                  type="button"
                  onClick={() => {
                    const tagManagerArgs = {
                      event: 'e_dignifi_faq',
                      area: 'financing'
                    };
                    eventReactGTMModule(tagManagerArgs);
                    window.open('https://www.dignifi.com/consumer-faq/');
                  }}>
                  FAQ
                </button>
              </div>
              <div className="hidden md:flex col-span-6 flex-row justify-center">
                <img
                  className="h-full w-60 object-cover pb-4 rounded-bl-[90px]"
                  src={FinancingFaqImg}
                  alt="no hidden prepayments or fees"
                />
              </div>
            </div>
          </div>
          <div className="px-[20px] md:px-[74px] mb-[50px] md:mb-[68px] text-[12px] text-[#6B7280]">
            <h3 className="font-bold mb-3">Disclosure</h3>
            <p className="mb-3">
              *All credit products are issued by WebBank. An origination fee of 4% applies. The
              DigniFi Interest Waived Program applies to all approved customers. Interest will be
              charged to your account from account opening at 9.99%‐36.00% APR. If the full loan
              amount is paid within the first 90 days, you will receive a refund in the amount of
              any accrued interest that was charged. A minimum monthly payment is required.
              Additional principal payments will be required to pay off the loan amount before the
              promotion expires. Interest will be waived if you make all of your scheduled payments
              on time and pay off your loan in full within the first 90 days. The application
              generally takes a few minutes. All applications are subject to approval pursuant to
              standard underwriting criteria. The maximum approved amount, terms, and the Annual
              Percentage Rate (APR) will be based on an evaluation of credit history. WebBank funds
              the repair amount once repairs are completed and documents are executed. (Depending on
              program used, an origination fee is 3 (AN) or 4% for everyone else)
            </p>
            <p className="mb-3">
              **All DigniFi ExpressWay credit cards are issued by WebBank. An annual fee of up to
              $79 may apply. Six-month deferred interest promotional offer is available on all
              approved purchases of $350 or more using the DigniFi ExpressWay credit card. No
              interest will be charged on the promotional purchase if you pay the promotional
              purchase amount in full within 6 months. If you do not, interest will be charged on
              the promotional purchase from the purchase date. A minimum monthly payment is
              required. The required minimum monthly payments may not pay off the promotional
              purchase by the end of the promotional period. Account must be open and current to be
              eligible for this offer. Regular account terms apply to non-promotional purchases and,
              after promotion ends, to your promotional purchases. Please see any special promotion
              advertising or other disclosures provided to you for the full terms of any special
              promotion offered. All applications for credit are based on an evaluation of credit
              history. The annual percentage rate (APR) ranges from 27.99%–36%. If you are charged
              interest, the charge will be no less than $2.00.
            </p>
            <p className="mb-3">
              † Pricing may vary based on your ExpressWay transaction amount, between "$350 $7,500",
              in combination with the monthly term of your Promotional Credit Plan. Your final APR
              will be illustrated on your final purchase receipt, emailed to you at the time of the
              transaction, and located by logging into your account.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};
export default withUser(Financing);
