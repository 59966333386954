import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { OnFocus } from 'react-final-form-listeners';
import { isEmpty } from 'lodash';
import formatString from 'format-string-by-pattern';
import {
  composeValidators,
  email,
  maxLength,
  minLength,
  renderField,
  required,
  usPhoneNumber
} from '../../helpers/form-validations';
import {
  callFBFunction,
  getAllRoofTops,
  getAllSalesByRooftop,
  uploadFile
} from '../../utils/firebase';
import withNotify from '../../redux/Hoc/withNotify';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import InputSelect from '../../elements/InputSelect';
import { STORAGE_PATH, ROLE } from '../../constants/index';

const CreateForm = ({ setLoader, setNotify }) => {
  const [rooftops, setRooftops] = useState([]);
  const [sales, setSales] = useState([]);
  const [selectedRooftop, setSelectedRooftop] = useState({});
  const [selectedSales, setSelectedSales] = useState({});
  const [selectedImage, setSelectedImage] = useState('');
  const [overwrite, setOverwrite] = useState('0');
  const [isEmail, setIsEmail] = useState(false);
  const [isForFbPost, setIsForFbPost] = useState(false);

  useEffect(() => {
    async function myApi() {
      const rooftopsLists = await getAllRoofTops();
      setRooftops(rooftopsLists);
      setSelectedRooftop(rooftopsLists[0]);
    }
    myApi();
  }, []);

  useEffect(() => {
    async function onInit() {
      if (!isEmpty(selectedRooftop)) {
        const roles = [ROLE.SALES];
        let salesLists = await getAllSalesByRooftop(selectedRooftop.id, roles);
        if (salesLists.length > 0) {
          salesLists = salesLists.map(({ id, name: { first, last } }) => ({
            id,
            name: `${first} ${last}`
          }));
          setSales(salesLists);
          setSelectedSales(salesLists[0]);
        } else {
          setSales([]);
          setSelectedSales({});
        }
      }
    }
    onInit();
  }, [selectedRooftop]);

  const onSubmit = (formData) => {
    const payload = {
      ...formData,
      rooftop: selectedRooftop.id,
      sales: selectedSales.id,
      overwrite,
      selectedImage,
      isForFbPost
    };
    setLoader(true);
    callFBFunction('signup', payload).then(({ data: response }) => {
      const { status, message } = response;
      setLoader(false);
      setNotify({ message, type: status ? 'success' : 'error' });
    });
  };

  const onSelectPhoto = async (event) => {
    setLoader(true);
    if (event.files && event.files[0]) {
      await uploadFile(STORAGE_PATH.FEED, event.files[0])
        .then(async (avatar) => {
          setSelectedImage(avatar);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          setNotify({ type: 'error', message: 'Photo upload failed! Please try again.' });
        });
    }
  };

  return (
    <div className="my-8">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="w-full grid grid-cols-2 gap-2 md:gap-4 px-2 md:px-3">
            <div className="mb-2 md:mb-4 col-span-2 md:col-span-1">
              <label htmlFor="inline-vin" className="block text-sm font-medium text-gray-700">
                VIN
              </label>
              <div className="mt-1">
                <Field
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="vin"
                  type="text"
                  id="inline-vin"
                  placeholder="3GNCJPSB3KL273514"
                  validate={composeValidators(required, maxLength(18), minLength(11))}>
                  {renderField}
                </Field>
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-1 md:col-span-1">
              <label htmlFor="inline-firstname" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <div className="mt-1">
                <Field
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="firstName"
                  type="text"
                  id="inline-firstname"
                  placeholder="Jeff"
                  validate={required}>
                  {renderField}
                </Field>
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-1 md:col-span-1">
              <label htmlFor="inline-lastname" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <div className="mt-1">
                <Field
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="lastName"
                  type="text"
                  id="inline-lastname"
                  placeholder="Smith"
                  validate={required}>
                  {renderField}
                </Field>
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-1 md:col-span-1">
              <label htmlFor="inline-email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <Field
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="email"
                  type="text"
                  id="inline-email"
                  placeholder="email@example.com"
                  validate={
                    !isEmail ? composeValidators(email) : composeValidators(required, email)
                  }>
                  {renderField}
                </Field>
              </div>
              <OnFocus name="email">
                {(value) => {
                  setIsEmail(value !== '');
                }}
              </OnFocus>
            </div>
            <div className="mb-2 md:mb-4 col-span-1 md:col-span-1">
              <label htmlFor="inline-phone" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <div className="mt-1">
                <Field
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="phone"
                  type="text"
                  id="inline-phone"
                  parse={formatString('(999) 999-9999')}
                  placeholder="(123) 456-7890"
                  validate={
                    isEmail
                      ? composeValidators(usPhoneNumber)
                      : composeValidators(required, usPhoneNumber)
                  }>
                  {renderField}
                </Field>
              </div>
              <OnFocus name="phone">
                {(value) => {
                  setIsEmail(value === '');
                }}
              </OnFocus>
            </div>
            <div className="mb-2 md:mb-4 col-span-2 md:col-span-1">
              <label htmlFor="inline-rooftop" className="block text-sm font-medium text-gray-700">
                Rooftop
              </label>
              <div className="mt-1">
                {rooftops.length ? (
                  <InputSelect
                    selected={selectedRooftop}
                    data={rooftops}
                    onSelect={(selected) => {
                      setSelectedRooftop(selected);
                    }}
                  />
                ) : (
                  <label className="text-xs text-gray-700">Fetching Details....</label>
                )}
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-2 md:col-span-1">
              <label htmlFor="inline-rooftop" className="block text-sm font-medium text-gray-700">
                Sales Agent
              </label>
              <div className="mt-1">
                {sales.length ? (
                  <InputSelect
                    selected={selectedSales}
                    data={sales}
                    onSelect={(selected) => {
                      setSelectedSales(selected);
                    }}
                  />
                ) : (
                  <label className="text-xs text-gray-700">Fetching Details....</label>
                )}
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-2 md:col-span-1">
              <label htmlFor="inline-lastname" className="block text-sm font-medium text-gray-700">
                Photo
              </label>
              <div className="mt-1">
                <input
                  className="appearance-none block w-full px-3 py-[5px] border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  type="file"
                  id="delivery-photo"
                  accept="image/*"
                  onChange={(e) => onSelectPhoto(e.target)}
                />
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-1 md:col-span-1">
              <label htmlFor="inline-lastname" className="block text-sm font-medium text-gray-700">
                Overwrite Existing ?
              </label>
              <div className="mt-1 flex flex-row gap-6">
                <div className="form-check form-check-inline">
                  <Field
                    component="input"
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    name="overwrite"
                    type="radio"
                    id="inline-overwrite-yes"
                    value="1"
                    onChange={() => setOverwrite('1')}
                    checked={overwrite === '1'}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inline-overwrite-yes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Field
                    component="input"
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    name="overwrite"
                    type="radio"
                    id="inline-overwrite-no"
                    value="0"
                    onChange={() => setOverwrite('0')}
                    checked={overwrite === '0'}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inline-overwrite-no">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-2 md:mb-4 col-span-1 text-center md:text-left md:col-span-1">
              <label htmlFor="inline-lastname" className="block text-sm font-medium text-gray-700">
                Do FB Post ?
              </label>
              <div className="mt-1">
                <label
                  htmlFor="checked-toggle"
                  className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    id="checked-toggle"
                    className="sr-only peer"
                    checked={isForFbPost}
                    onChange={() => {
                      setIsForFbPost(!isForFbPost);
                    }}
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                </label>
              </div>
            </div>
            <div className="col-span-2 flex justify-end mt-2">
              <button
                className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit">
                Create
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default withNotify(withUser(withLoader(CreateForm)));
