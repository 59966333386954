import { useIndexedDB } from 'react-indexed-db';
import { store } from '../redux/store';
import { resetRedux, notifySet } from '../redux/actions';
import { doAuthLogout, getCurrentUser } from '../utils/firebase';

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const userLogout = async () => {
  const { clear } = useIndexedDB('links');

  setTimeout(() => {
    doAuthLogout();
    sessionStorage.clear();
    clear();
    store.dispatch(resetRedux());
    store.dispatch(notifySet({ type: 'success', message: 'You have successfully signed out.' }));
    window.location = '/';
  }, 100);
};

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export const USPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const filterVehicleContent = (model, year, data) => {
  model = model.toLowerCase();
  year = year.toString();
  const response = {
    docs: [],
    videos: [],
    images: []
  };

  for (const vehicleContent of data) {
    for (const doc of vehicleContent.docs) {
      doc.models_applied = doc.models_applied.map((val) => val.toLowerCase());
      doc.years_applied = doc.years_applied.map((val) => val.toString());
      if (
        doc.years_applied.indexOf(year.toString()) > -1 &&
        doc.models_applied.indexOf(model.toLowerCase()) > -1
      ) {
        response.docs.push(doc);
      }
    }
    for (const video of vehicleContent.videos) {
      video.models_applied = video.models_applied.map((val) => val.toLowerCase());
      video.years_applied = video.years_applied.map((val) => val.toString());
      if (
        video.years_applied.indexOf(year.toString()) > -1 &&
        video.models_applied.indexOf(model.toLowerCase()) > -1
      ) {
        response.videos.push(video);
      }
    }
    for (const image of vehicleContent.images) {
      image.models_applied = image.models_applied.map((val) => val.toLowerCase());
      image.years_applied = image.years_applied.map((val) => val.toString());
      if (
        image.years_applied.indexOf(year.toString()) > -1 &&
        image.models_applied.indexOf(model.toLowerCase()) > -1
      ) {
        response.images.push(image);
      }
    }
  }
  return response;
};

export const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const wordUppercase = (value) => {
  const words = value.toLowerCase().split(' ');
  for (let word = 0; word < words.length; word += 1) {
    words[word] = words[word].charAt(0).toUpperCase() + words[word].slice(1);
  }
  return words.join(' ');
};

export const syntaxUppercase = (value) => value.charAt(0).toUpperCase() + value.slice(1);

export const isMobile = () => {
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  return regexp.test(details);
};

export const checkFirebaseSession = async () => {
  const user = getCurrentUser();
  if (!user) {
    doAuthLogout();
    sessionStorage.clear();
    store.dispatch(resetRedux());
    store.dispatch(notifySet({ type: 'error', message: 'Your session has been expired.' }));
  }
};

export const DBConfig = {
  name: 'MyDB',
  version: 1,
  objectStoresMeta: [
    {
      store: 'links',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'magicLink', keypath: 'id', options: { unique: false } }]
    }
  ]
};

export const makeVehicleFeatures = (vehicle) => {
  if (vehicle.attributes.features) {
    const { features } = vehicle.attributes;
    const vehicleDetails = {};
    if (!Array.isArray(features)) {
      const data = [];
      Object.keys(features).forEach((key) => {
        features[key].map((item) => {
          item.type = key;
          data.push(item);
        });
      });
      vehicle.attributes.features = data;
    }

    vehicle.attributes.features.forEach((item) => {
      const { category } = item;
      if (!vehicleDetails[category]) {
        vehicleDetails[category] = [];
      }
      vehicleDetails[category].push(item);
    });

    Object.keys(vehicleDetails).forEach((item) => {
      const vehicleFeatureItems = [];
      vehicleDetails[item].forEach((data) => {
        const existingItem = vehicleFeatureItems.find(
          (i) => i.feature_type.toLowerCase() === data.feature_type.toLowerCase()
        );
        if (existingItem) {
          existingItem.description.push(data.description);
        } else {
          vehicleFeatureItems.push({
            ...data,
            description: [data.description]
          });
        }
      });
      vehicleDetails[item] = vehicleFeatureItems;
    });

    vehicle.attributes.features = Object.keys(vehicleDetails)
      .sort()
      .reduce(
        (item, key) => ({
          ...item,
          [key]: vehicleDetails[key]
        }),
        {}
      );
  }
  return vehicle;
};
