import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';
import formatString from 'format-string-by-pattern';
import withNotify from '../../redux/Hoc/withNotify';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import InputSelect from '../../elements/InputSelect';
import {
  callFBFunction,
  getAllRoofTops,
  getAllSalesByRooftop,
  uploadFile
} from '../../utils/firebase';
import { ROLE, STORAGE_PATH } from '../../constants';
import {
  composeValidators,
  email,
  renderField,
  required,
  usPhoneNumber
} from '../../helpers/form-validations';

const CreateStaffForm = ({ setLoader, setNotify }) => {
  const [rooftops, setRooftops] = useState([]);
  const [sales, setSales] = useState([]);
  const [selectedRooftop, setSelectedRooftop] = useState({});
  const [selectedSales, setSelectedSales] = useState({});
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    async function onInit() {
      const rooftopsLists = await getAllRoofTops();
      setRooftops(rooftopsLists);
      setSelectedRooftop(rooftopsLists[0]);
    }
    onInit();
  }, []);

  useEffect(() => {
    async function myApi() {
      const createStaffOption = [{ id: '1', name: 'Create New Staff' }];
      if (!isEmpty(selectedRooftop)) {
        const roles = [ROLE.SALES];
        let salesLists = await getAllSalesByRooftop(selectedRooftop.id, roles);
        if (salesLists.length > 0) {
          salesLists = salesLists.map(({ id, name: { first, last } }) => ({
            id,
            name: `${first} ${last}`
          }));
          setSales([...createStaffOption, ...salesLists]);
          setSelectedSales(salesLists[0]);
        } else {
          setSales(createStaffOption);
          setSelectedSales(createStaffOption[0]);
        }
      }
    }
    myApi();
  }, [selectedRooftop]);

  const onSubmit = (formData, form) => {
    const payload = {
      ...formData,
      rooftop: selectedRooftop.id,
      secretKey: process.env.REACT_APP_SECRET_KEY,
      sales: selectedSales.id === '1' ? '' : selectedSales.id,
      selectedImage
    };

    setLoader(true);
    callFBFunction('createStaffAndSendMagicLink', payload).then(({ data: response }) => {
      const { status, message } = response;
      setLoader(false);
      form.reset();
      setNotify({ message, type: status ? 'success' : 'error' });
    });
  };

  const onSelectPhoto = async (event) => {
    setLoader(true);
    if (event.files && event.files[0]) {
      await uploadFile(STORAGE_PATH.PROFILE, event.files[0])
        .then(async (avatar) => {
          setSelectedImage(avatar);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          setNotify({ type: 'error', message: 'Photo upload failed! Please try again.' });
        });
    }
  };

  return (
    <div className="my-10">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="w-full max-w-sm">
            <div className="mb-4">
              <label htmlFor="inline-rooftop" className="block text-sm font-medium text-gray-700">
                Rooftop
              </label>
              <div className="mt-1">
                {rooftops.length ? (
                  <InputSelect
                    selected={selectedRooftop}
                    data={rooftops}
                    onSelect={(selected) => {
                      setSelectedRooftop(selected);
                    }}
                  />
                ) : (
                  <label className="text-xs text-gray-700">Fetching Details....</label>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="inline-rooftop" className="block text-sm font-medium text-gray-700">
                Sales Agent
              </label>
              <div className="mt-1">
                {sales.length ? (
                  <InputSelect
                    selected={selectedSales}
                    data={sales}
                    onSelect={(selected) => {
                      setSelectedSales(selected);
                    }}
                  />
                ) : (
                  <label className="text-xs text-gray-700">Fetching Details....</label>
                )}
              </div>
            </div>
            {selectedSales.id === '1' && (
              <>
                <div className="mb-4">
                  <label htmlFor="inline-email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <div className="mt-1">
                    <Field
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      name="email"
                      type="text"
                      id="inline-email"
                      placeholder="email@example.com"
                      validate={composeValidators(required, email)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="inline-phone" className="block text-sm font-medium text-gray-700">
                    Phone
                  </label>
                  <div className="mt-1">
                    <Field
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      name="phone"
                      type="text"
                      id="inline-phone"
                      parse={formatString('(999) 999-9999')}
                      placeholder="(123) 456-7890"
                      validate={composeValidators(required, usPhoneNumber)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="inline-firstname"
                    className="block text-sm font-medium text-gray-700">
                    First
                  </label>
                  <div className="mt-1">
                    <Field
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      name="firstName"
                      type="text"
                      id="inline-firstname"
                      placeholder="Jeff"
                      validate={required}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="inline-lastname"
                    className="block text-sm font-medium text-gray-700">
                    Last
                  </label>
                  <div className="mt-1">
                    <Field
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      name="lastName"
                      type="text"
                      id="inline-lastname"
                      placeholder="Smith"
                      validate={required}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="inline-lastname"
                    className="block text-sm font-medium text-gray-700">
                    Profile
                  </label>
                  <div className="mt-1">
                    <input
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      type="file"
                      id="profile-photo"
                      accept="image/*"
                      onChange={(e) => onSelectPhoto(e.target)}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="md:flex md:items-center mt-10">
              <div className="md:w-1/3" />
              <div className="md:w-2/3">
                <button
                  className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  type="submit">
                  {selectedSales.id === '1' ? 'Create & Send Email' : 'Send Email'}
                </button>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default withNotify(withUser(withLoader(CreateStaffForm)));
