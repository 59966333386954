import React, { useState } from 'react';
import AccordionIcon from './AccordionIcon';

const Specifications = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      {data?.attributes?.features &&
        Object.keys(data?.attributes?.features).map((item, index) => (
          <div className="mb-4 rounded cursor-pointer" key={index}>
            <div className="flex justify-between items-center" onClick={() => handleClick(index)}>
              <div className="className=text-lg leading-6 font-medium text-gray-700 py-2">
                {item}
              </div>
              <AccordionIcon open={activeIndex === index} />
            </div>
            <div className="border-t border-gray-200">
              <div
                className={`accordion-content bg-white pt-2 ${
                  activeIndex === index ? 'block' : 'hidden'
                }`}>
                <dl className="sm:divide-y sm:divide-gray-200">
                  {data?.attributes?.features[item]?.map((val, idx) => (
                    <div key={idx} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-normal text-gray-500">{val.feature_type}</dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {val.description.join(', ') || '-'}
                      </dd>
                    </div>
                  ))}
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" />
                </dl>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Specifications;
