import { connect } from 'react-redux';
import { vehicleContentSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setVehicleContent: (data) => {
    dispatch(vehicleContentSet(data));
  }
});

const mapStateToProps = (state) => ({
  vehicleContent: state.vehicleContent ? state.vehicleContent : {}
});

export default connect(mapStateToProps, mapDispatchToProps);
