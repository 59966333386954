import { useState } from 'react';
import AppleContent from './AppleContent';
import GoogleContent from './GoogleContent';

const TabsComponent = ({ onClick }) => {
  const tabs = [
    { name: 'Apple Wallet', link: '#', content: <AppleContent onClick={onClick} /> },
    { name: 'Google Wallet', link: '#', content: <GoogleContent onClick={onClick} /> }
  ];
  const [openTab, setOpenTab] = useState('Apple Wallet');

  return (
    <div>
      <div className="w-full">
        <div className="flex flex-col items-start">
          <ul className="flex flex-row">
            {tabs.map((tab) => (
              <li className="flex justify-center items-center" key={tab.name}>
                <a
                  href={tab.link}
                  onClick={() => setOpenTab(tab.name)}
                  className={
                    openTab === tab.name
                      ? 'inline-block px-4 md:px-8 py-3 text-black rounded-t-lg bg-[#F2F3F6] font-semibold border-2 border-[#F2F3F6]'
                      : 'inline-block px-4 md:px-8 py-3 text-black/40 bg-white rounded-t-lg font-semibold border-2 border-[#F2F3F6]'
                  }>
                  {tab.name}
                </a>
              </li>
            ))}
          </ul>
          <div className="w-full bg-[#F2F3F6] rounded-b-lg rounded-tr-lg px-3 py-6 md:p-6">
            {tabs.map((tab) => (
              <div key={tab.name} className={tab.name === openTab ? 'block' : 'hidden'}>
                {tab.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
