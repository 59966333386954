import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import LeaveReview from '../../modals/LeaveReview';
import withUser from '../../redux/Hoc/withUser';
import { eventReactGTMModuleSales } from '../../utils/analytics';

const SalesAgent = ({ data, reviewList, onReload, isSales, user }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isHighlightBlank = () => {
    for (let highlight = 0; highlight < data.highlights.length; highlight += 1) {
      if (data.highlights[highlight] !== '') return false;
    }
    return true;
  };

  const isReviewGiven = () => {
    for (let review = 0; review < reviewList.length; review += 1) {
      if (reviewList[review].user === user.id) {
        return false;
      }
    }
    return true;
  };
  const onLeaveReview = () => {
    setIsOpen(true);
  };
  const onContinue = () => {
    setIsOpen(false);
    onReload();
  };

  const onClickCallOrEmail = (value) => {
    const tagManagerArg = {
      event: 'e_agent_contact',
      agentID: data.staff,
      rooftop: data.rooftop,
      ownerID: isSales ? '' : data.id,
      dealerGroupID: data.rooftopData.group,
      experience: isSales ? 'sa_private' : 'sa_public',
      area: 'home',
      agent_contact_method: value
    };
    eventReactGTMModuleSales(tagManagerArg);
  };

  return (
    <>
      {Object.keys(data).length > 0 && (
        <>
          <div className="w-full flex items-center justify-between space-x-6">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-left text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  {data.name}
                </h1>

                <h2 id="information-heading" className="sr-only">
                  Product information
                </h2>
                <p className="text-sm text-gray-500 mt-2">
                  {data.title} - {data.roofTopName}
                </p>
              </div>
              <div className="flex items-center">
                <ReactStars
                  size={20}
                  count={5}
                  color="grey"
                  activeColor="#FACC14"
                  value={data.rating}
                  isHalf
                  edit={false}
                />
                <p className="text-sm text-gray-500 ml-2">{data.rating}</p>
              </div>
              <p className="sr-only">{data.rating} out of 5 stars</p>
            </div>
            <img
              className="w-16 h-16 bg-gray-300 rounded-full flex-shrink-0"
              src={data.avatar}
              alt=""
            />
          </div>
          <p className="text-left  sm:text-sm text-gray-500 mt-6">{data.bio}</p>

          <div
            className={`mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-${
              !isSales ? (isReviewGiven() && data.id === user.staff ? '3' : '2') : '2'
            }`}>
            {!isSales
              ? isReviewGiven() &&
                data.id === user.staff && (
                  <button
                    type="button"
                    onClick={onLeaveReview}
                    className="w-full bg-indigo-600 w-22 h-15 rounded-md py-2 px-4 flex items-center justify-center text-base font-medium">
                    <span className="ml-1 text-white">Leave Review</span>
                  </button>
                )
              : null}
            <a
              onClick={() => onClickCallOrEmail('call')}
              href={`tel:${data.phone.mobile}`}
              className="w-full bg-indigo-600 w-22 h-15 rounded-md py-2 px-4 flex items-center justify-center text-base font-medium">
              <span className="ml-1 text-white">Call</span>
            </a>
            <a
              onClick={() => onClickCallOrEmail('email')}
              href={`mailto:${data.email.primary}`}
              className="w-full border-black-700 w-22 h-15 rounded-md py-2 px-4 flex items-center justify-center text-base font-medium ring-offset-0 border border-gray-400">
              <span className="ml-1 text-black">Email</span>
            </a>
          </div>

          {!isHighlightBlank() && (
            <div className="border-t border-gray-200 mt-4 md:mt-10 pt-4 md:pt-10">
              <h3 className="text-left text-sm font-medium text-gray-900">Highlights</h3>
              <div className="text-left  mt-4 prose prose-sm text-gray-500">
                <ul role="list">
                  {data.highlights.map((highlight) => (
                    <div>
                      {highlight !== '' && (
                        <li className="list-disc sm:text-sm mt-2 list-inside" key={highlight}>
                          {highlight}
                        </li>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      {!isSales && isOpen && <LeaveReview onContinue={onContinue} open={isOpen} staff={data} />}
    </>
  );
};

export default withUser(SalesAgent);
