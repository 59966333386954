import React from 'react';
import withNotify from '../../redux/Hoc/withNotify';
import withUser from '../../redux/Hoc/withUser';
import withLoader from '../../redux/Hoc/withLoader';
import CreateStaffForm from '../../components/Sales/CreateStaffForm';

const CreateStaff = () => (
  <main className="lg:col-span-9 xl:col-span-6">
    <div className="p-7 bg-gray-100 h-screen">
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Create a Staff</h3>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white pt-1 pb-1 px-4 shadow sm:rounded-lg sm:px-10">
          <CreateStaffForm />
        </div>
      </div>
    </div>
  </main>
);

export default withNotify(withUser(withLoader(CreateStaff)));
