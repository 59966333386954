import React from 'react';
import Slider from 'react-slick';
import { eventAnalytics } from '../../utils/analytics';
import { ACTION, CATEGORY } from '../../constants';

const sliderSettings = {
  lazyLoad: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange: () => {
    eventAnalytics({
      category: CATEGORY.ASSET,
      action: ACTION.GALLERY_VIEW,
      nonInteraction: false
    });
  }
};

const SliderImages = ({ data }) => (
  <>
    {data.length ? (
      <Slider {...sliderSettings}>
        {data.map((image, index) => (
          <div
            key={`image-${index}`}
            className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
            <img
              src={image.source}
              alt={image.title}
              className="object-cover w-full object-cover pointer-events-none group-hover:opacity-75 rounded-lg"
            />
          </div>
        ))}
      </Slider>
    ) : null}
  </>
);

export default SliderImages;
